"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DoughnutComponent = void 0;
var react_1 = __importStar(require("react"));
var chart_js_1 = require("chart.js");
var react_chartjs_2_1 = require("react-chartjs-2");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
chart_js_1.Chart.register(chart_js_1.ArcElement, chart_js_1.Tooltip, chart_js_1.Legend);
var DoughnutComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var title = props.title, dataSet = props.dataSet;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _j = (0, react_1.useState)(), ordered = _j[0], setOrdered = _j[1];
    var _k = (0, react_1.useState)(), processing = _k[0], setProcessing = _k[1];
    var _l = (0, react_1.useState)(), partiallyFulfilled = _l[0], setPartiallyFulfilled = _l[1];
    var _m = (0, react_1.useState)(), fulfilled = _m[0], setFulfilled = _m[1];
    var _o = (0, react_1.useState)(), cancelled = _o[0], setCancelled = _o[1];
    var _p = (0, react_1.useState)(), abondoned = _p[0], setAbondoned = _p[1];
    var _q = (0, react_1.useState)(), checkedOut = _q[0], setCheckedOut = _q[1];
    var _r = (0, react_1.useState)(), active = _r[0], setActive = _r[1];
    (0, react_1.useEffect)(function () {
        if (dataSet) {
            if (title === "Orders") {
                var orderedObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ORDERED"; });
                setOrdered(orderedObject);
                var processingObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "PROCESSING"; });
                setProcessing(processingObject);
                var partiallyFulfilledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "PARTIALLY-FULFILLED"; });
                setPartiallyFulfilled(partiallyFulfilledObject);
                var fulfilledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "FULFILLED"; });
                setFulfilled(fulfilledObject);
            }
            if (title === "Baskets") {
                var activeObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ACTIVE"; });
                setActive(activeObject);
                var cancelledObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "CANCELLED"; });
                setCancelled(cancelledObject);
                var abondonedObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "ABANDONED"; });
                setAbondoned(abondonedObject);
                var checkedOutObject = dataSet === null || dataSet === void 0 ? void 0 : dataSet.find(function (item) { return item.status === "CHECKED-OUT"; });
                setCheckedOut(checkedOutObject);
            }
        }
    }, [dataSet]);
    var options = {
        responsive: false,
        maintainAspectRatio: false,
        cutout: "65%",
        spacing: 0,
        borderJoinStyle: "round",
        plugins: {
            legend: {
                position: "right",
                labels: {
                    usePointStyle: true,
                },
            },
        },
        animation: {
            animateRotate: false,
            animateScale: true,
        },
    };
    var data = {
        labels: dataSet === null || dataSet === void 0 ? void 0 : dataSet.map(function (row) { return row.status; }),
        datasets: [
            {
                data: dataSet === null || dataSet === void 0 ? void 0 : dataSet.map(function (row) { return row.count; }),
                backgroundColor: ["rgb(8,144,0)", "rgb(127, 0, 255)", "rgb(255, 194, 0)", "rgb(186,196,200, 0.5)"],
                hoverOffset: 4,
                borderRadius: 8,
            },
        ],
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { variant: "filled", backgroundColor: "bg-white", className: "flex flex-col border-transparent rounded-lg" },
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement("h1", { className: "font-bold pb-2" }, title)),
        title === "Orders" && (react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center mb-4" },
            react_1.default.createElement("div", { className: "flex flex-row gap-2 text-xs w-full" },
                ordered && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_a = ordered === null || ordered === void 0 ? void 0 : ordered.total) !== null && _a !== void 0 ? _a : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Ordered"))),
                processing && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_b = processing === null || processing === void 0 ? void 0 : processing.total) !== null && _b !== void 0 ? _b : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Processing"))),
                partiallyFulfilled && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_c = partiallyFulfilled === null || partiallyFulfilled === void 0 ? void 0 : partiallyFulfilled.total) !== null && _c !== void 0 ? _c : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Partially-Fulfilled"))),
                fulfilled && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_d = fulfilled === null || fulfilled === void 0 ? void 0 : fulfilled.total) !== null && _d !== void 0 ? _d : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Fulfilled")))))),
        title === "Baskets" && (react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center mb-4" },
            react_1.default.createElement("div", { className: "flex flex-row gap-2 text-xs w-full" },
                active && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_e = active === null || active === void 0 ? void 0 : active.total) !== null && _e !== void 0 ? _e : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Active"))),
                checkedOut && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_f = checkedOut === null || checkedOut === void 0 ? void 0 : checkedOut.total) !== null && _f !== void 0 ? _f : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Checked Out"))),
                abondoned && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_g = abondoned === null || abondoned === void 0 ? void 0 : abondoned.total) !== null && _g !== void 0 ? _g : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Abondoned"))),
                cancelled && (react_1.default.createElement("div", { className: "flex-1 border-2 rounded-lg  border-neutral-100 p-1" },
                    react_1.default.createElement("h1", { className: "text-black font-semibold" }, doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_h = cancelled === null || cancelled === void 0 ? void 0 : cancelled.total) !== null && _h !== void 0 ? _h : "0",
                        symbolSeparation: true,
                    })),
                    react_1.default.createElement("span", { className: "text-zinc-400" }, "Cancelled")))))),
        react_1.default.createElement("div", { className: "flex flex-col justify-center items-center" },
            react_1.default.createElement("div", { className: "" }, !(dataSet === null || dataSet === void 0 ? void 0 : dataSet.length) ? react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "medium" }) : react_1.default.createElement(react_chartjs_2_1.Doughnut, { data: data, options: options })))));
};
exports.DoughnutComponent = DoughnutComponent;
