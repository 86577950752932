"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontMediaSocialsConfiguration = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var free_brands_svg_icons_1 = require("@fortawesome/free-brands-svg-icons");
var store_front_1 = require("../../../../../reducers/slices/storeFront/store_front");
var upload_file_1 = require("../../../../../api/calls/media/upload_file");
var main_1 = require("../../../../../reducers/slices/main");
var FileUploads = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var dispatch = (0, react_redux_1.useDispatch)();
    var images = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.images; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var _o = (0, react_1.useState)(false), showHeaderInputControl = _o[0], setShowHeaderLogoInputControl = _o[1];
    var _p = (0, react_1.useState)(false), showFooterInputControl = _p[0], setShowFooterLogoInputControl = _p[1];
    var _q = (0, react_1.useState)(false), showFaviconInputControl = _q[0], setShowFaviconInputControl = _q[1];
    var onImageChange = function (key, file) {
        var _a, _b;
        dispatch((0, store_front_1.setIsLoading)(true));
        var image = __assign({}, images[key]);
        image.file_name = file.name;
        if (file.size > 1000000) {
            dispatch((0, store_front_1.setErrors)({ images: (_a = {}, _a[key] = { url: "File size cannot exceed 1MB" }, _a) }));
            return;
        }
        else {
            dispatch((0, store_front_1.setErrors)({ images: (_b = {}, _b[key] = { url: "" }, _b) }));
        }
        (0, upload_file_1.upload_file)({
            file: file,
            file_name: file.name,
            description: "Lets Trade Client Store Front Image",
            type: "IMAGE",
            category: "Store Front",
        })
            .then(function (res) {
            var _a;
            if (res.success) {
                image.url = res.content.location;
                image.etag = res.content.etag;
                dispatch((0, store_front_1.setImages)(__assign(__assign({}, images), (_a = {}, _a[key] = image, _a))));
                dispatch((0, store_front_1.setIsEdited)(true));
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when uploading file!", messages: [res.message] }));
            }
        })
            .catch(function (err) {
            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when uploading file!", messages: [err.message] }));
        });
        dispatch((0, store_front_1.setIsLoading)(false));
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Uploads", size: "large", textColor: "text-black" }),
        react_1.default.createElement("div", { className: "grid grid-flow-col grid-rows-3 gap-2 mx-6 mb-6" },
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Header Logo", size: "medium", textColor: "text-inputText pt-2 flex" }),
                showHeaderInputControl || !((_a = images === null || images === void 0 ? void 0 : images.header_logo) === null || _a === void 0 ? void 0 : _a.url) ? (react_1.default.createElement(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: true, errorText: (_c = (_b = errors === null || errors === void 0 ? void 0 : errors.images) === null || _b === void 0 ? void 0 : _b.header_logo) === null || _c === void 0 ? void 0 : _c.url, supportedFileTypes: [".png", ".jpeg"], onChange: function (file) {
                        onImageChange("header_logo", file);
                    } })) : (react_1.default.createElement("div", { className: "flex items-center relative border border-solid border-borders p-0 rounded-lg" },
                    react_1.default.createElement("img", { src: (_d = images === null || images === void 0 ? void 0 : images.header_logo) === null || _d === void 0 ? void 0 : _d.url, alt: "Header Logo", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }),
                    react_1.default.createElement("div", { className: "absolute right-3 bg-gray-600 rounded-full text-white" },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                setShowHeaderLogoInputControl(true);
                            } }))))),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Footer Logo", size: "medium", textColor: "text-inputText pt-2" }),
                showFooterInputControl || (!images.footer_logo.file && !((_e = images === null || images === void 0 ? void 0 : images.footer_logo) === null || _e === void 0 ? void 0 : _e.url)) ? (react_1.default.createElement(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: true, errorText: (_g = (_f = errors === null || errors === void 0 ? void 0 : errors.images) === null || _f === void 0 ? void 0 : _f.footer_logo) === null || _g === void 0 ? void 0 : _g.url, supportedFileTypes: [".png", ".jpeg"], onChange: function (file) {
                        onImageChange("footer_logo", file);
                    } })) : (react_1.default.createElement("div", { className: "flex items-center relative border border-solid border-borders p-0 rounded-lg" },
                    react_1.default.createElement("img", { src: (_h = images === null || images === void 0 ? void 0 : images.footer_logo) === null || _h === void 0 ? void 0 : _h.url, alt: "Footer Logo", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }),
                    react_1.default.createElement("div", { className: "absolute right-3 bg-gray-600 rounded-full text-white" },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                setShowFooterLogoInputControl(true);
                            } }))))),
            react_1.default.createElement("div", { className: "grid" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Favicon", size: "medium", textColor: "text-inputText pt-2" }),
                showFaviconInputControl || (!images.favicon.file && !((_j = images === null || images === void 0 ? void 0 : images.favicon) === null || _j === void 0 ? void 0 : _j.url)) ? (react_1.default.createElement(doshx_controls_web_1.FileInputControl, { startIcon: pro_light_svg_icons_1.faFileArrowUp, previewSide: "right", colors: { iconColor: "text-blue-600", borderColor: "border-borders" }, size: "medium", previewFile: true, errorText: (_l = (_k = errors === null || errors === void 0 ? void 0 : errors.images) === null || _k === void 0 ? void 0 : _k.favicon) === null || _l === void 0 ? void 0 : _l.url, supportedFileTypes: [".png", ".jpeg"], onChange: function (file) {
                        onImageChange("favicon", file);
                    } })) : (react_1.default.createElement("div", { className: "flex items-center relative border border-solid border-borders p-0 rounded-lg" },
                    react_1.default.createElement("img", { src: (_m = images === null || images === void 0 ? void 0 : images.favicon) === null || _m === void 0 ? void 0 : _m.url, alt: "Favicon", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }),
                    react_1.default.createElement("div", { className: "absolute right-3 bg-gray-600 rounded-full text-white" },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                setShowFaviconInputControl(true);
                            } }))))))));
};
var StoreFrontMediaSocialsConfiguration = function () {
    var _a, _b, _c, _d, _e;
    var dispatch = (0, react_redux_1.useDispatch)();
    var socials = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.socials; });
    var contact_details = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.contact_details; });
    var contact_us = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.contact_us; });
    var errors = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState.errors; });
    var _onSocialsChange = function (value) {
        dispatch((0, store_front_1.setSocials)(__assign(__assign({}, socials), value)));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    var _onContactDetailsChange = function (value) {
        dispatch((0, store_front_1.setContactDetails)(__assign(__assign({}, contact_details), value)));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    var _onContactUsRecipientChange = function (value) {
        dispatch((0, store_front_1.setContactUs)(__assign(__assign({}, contact_us), { recipient: __assign(__assign({}, contact_us.recipient), value) })));
        dispatch((0, store_front_1.setIsEdited)(true));
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-row gap-4" },
        react_1.default.createElement("div", { className: "flex-1 flex flex-col" },
            react_1.default.createElement(FileUploads, null),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Store Contact Details", size: "large", textColor: "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email", size: "medium", textColor: "text-inputText pt-2" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: contact_details === null || contact_details === void 0 ? void 0 : contact_details.email, required: true, errorText: (_a = errors.contact_details) === null || _a === void 0 ? void 0 : _a.email, onChange: function (email) { return _onContactDetailsChange({ email: email }); } }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Phone", size: "medium", textColor: "text-inputText pt-2" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: contact_details === null || contact_details === void 0 ? void 0 : contact_details.phone, required: true, errorText: (_b = errors.contact_details) === null || _b === void 0 ? void 0 : _b.phone, onChange: function (phone) { return _onContactDetailsChange({ phone: phone }); } }),
            react_1.default.createElement("div", { className: "pt-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Contact Us Form", size: "large", textColor: "text-black" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Specify the email to which queries are sent", size: "xsmall", textColor: "text-black" })),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email", size: "medium", textColor: "text-inputText pt-2" }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: (_c = contact_us === null || contact_us === void 0 ? void 0 : contact_us.recipient) === null || _c === void 0 ? void 0 : _c.email, required: true, errorText: (_e = (_d = errors.contact_us) === null || _d === void 0 ? void 0 : _d.recipient) === null || _e === void 0 ? void 0 : _e.email, onChange: function (email) { return _onContactUsRecipientChange({ email: email }); } })),
        react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-2" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Socials", size: "large", textColor: "text-black" }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center pt-2" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faFacebookF }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Facebook", size: "medium", textColor: "text-inputText" })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: socials === null || socials === void 0 ? void 0 : socials.facebook, onChange: function (facebook) { return _onSocialsChange({ facebook: facebook }); } }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center pt-2" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faInstagram }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Instagram", size: "medium", textColor: "text-inputText" })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: socials === null || socials === void 0 ? void 0 : socials.instagram, onChange: function (instagram) { return _onSocialsChange({ instagram: instagram }); } }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center pt-2" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faXTwitter }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Twitter", size: "medium", textColor: "text-inputText" })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: socials === null || socials === void 0 ? void 0 : socials.twitter, onChange: function (twitter) { return _onSocialsChange({ twitter: twitter }); } }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center pt-2" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faLinkedinIn }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "LinkedIn", size: "medium", textColor: "text-inputText" })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: socials === null || socials === void 0 ? void 0 : socials.linkedin, onChange: function (linkedin) { return _onSocialsChange({ linkedin: linkedin }); } }),
            react_1.default.createElement("div", { className: "flex flex-row gap-4 items-center pt-2" },
                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: free_brands_svg_icons_1.faWhatsapp }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "WhatsApp", size: "medium", textColor: "text-inputText" })),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-black-300", value: socials === null || socials === void 0 ? void 0 : socials.whatsapp, onChange: function (whatsapp) { return _onSocialsChange({ whatsapp: whatsapp }); } }))));
};
exports.StoreFrontMediaSocialsConfiguration = StoreFrontMediaSocialsConfiguration;
