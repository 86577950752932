"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductRefComponent = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var product_arrived_1 = require("../../../../../api/calls/order/update/product_arrived");
var get_order_thunk_1 = require("../../../../../reducers/slices/orders/thunks/get_order_thunk");
var constants_1 = require("../../../../../constants/constants");
var main_1 = require("../../../../../reducers/slices/main");
var ProductRefComponent = function (props) {
    var product = props.product;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), referenceNumber = _a[0], setReferenceNumber = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }), selectedOrder = _b.selectedOrder, isLoading = _b.isLoading;
    var submitData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, product_arrived_1.product_arrived)({
                        _id: selectedOrder._id,
                        product: {
                            _id: product === null || product === void 0 ? void 0 : product._id,
                        },
                        reference_number: referenceNumber,
                    })];
                case 1:
                    response = _a.sent();
                    if (response === null || response === void 0 ? void 0 : response.success) {
                        dispatch((0, get_order_thunk_1.get_order_thunk)({ _id: selectedOrder._id }));
                    }
                    else
                        dispatch((0, main_1.setMessageModal)({ title: constants_1.REQUEST_ERROR_TITLE, messages: [response.message] }));
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", null, isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: referenceNumber, onChange: function (v) {
                setReferenceNumber(v);
            }, label: "Reference Number (Optional) ", backgroundColor: "bg-gray", borderColor: "border-transparent", lines: 1, size: "small" }),
        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "xsmall", textColor: "text-white", onClick: function () {
                submitData();
            } })))));
};
exports.ProductRefComponent = ProductRefComponent;
