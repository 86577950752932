"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.productTableColumns = exports.STATUSES = void 0;
exports.STATUSES = [
    { label: "Draft", value: "DRAFT" },
    { label: "Sent", value: "SENT" },
    { label: "Viewed", value: "VIEWED" },
    { label: "Accepted", value: "ACCEPTED" },
    { label: "Declined", value: "DECLINED" },
];
exports.productTableColumns = [
    {
        displayKey: "image",
        label: "Image",
    },
    {
        displayKey: "title",
        label: "Products/Service",
    },
    {
        displayKey: "category",
        label: "Category",
    },
    {
        displayKey: "barcode",
        label: "Barcode",
    },
    {
        displayKey: "stock",
        label: "In Stock",
    },
    {
        displayKey: "quantity",
        label: "Quantity",
    },
    {
        displayKey: "price",
        label: "Total",
    },
];
