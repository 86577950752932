"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeDashboardView = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var barGraphComponent_1 = require("./barGraphComponent");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doughnutComponent_1 = require("./doughnutComponent");
var dashboardFilterPeriods_1 = require("../helpers/dashboardHelpers/dashboardFilterPeriods");
var get_client_user_branches_1 = require("../api/calls/client/get_client_user_branches");
var get_client_user_list_thunk_1 = require("../reducers/slices/client/thunks/get_client_user_list_thunk");
var branch_list_thunk_1 = require("../reducers/slices/branch/thunks/get/branch_list_thunk");
var get_client_order_dashboard_thunk_1 = require("../reducers/slices/dashboard/thunks/get_client_order_dashboard_thunk");
var get_client_basket_dashboard_thunk_1 = require("../reducers/slices/dashboard/thunks/get_client_basket_dashboard_thunk");
var get_client_user_dashboard_thunk_1 = require("../reducers/slices/dashboard/thunks/get_client_user_dashboard_thunk");
var useSearchDebounce_1 = require("../hooks/useSearchDebounce");
var HomeDashboardView = function () {
    var _a, _b, _c, _d;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _e = (0, useSearchDebounce_1.useSearchDebounce)(1100), searchDashboardBranches = _e[0], setSearchDashboardBranches = _e[1];
    var _f = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), user = _f.user, client_token = _f.client_token;
    var _g = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), clientUsers = _g.clientUsers, pagination = _g.pagination, client = _g.client;
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), dashboardBranches = _h.dashboardBranches, dashboardBranchPagination = _h.dashboardBranchPagination;
    var _j = (0, react_redux_1.useSelector)(function (state) { return state.dashboardState; }), branchDashboard = _j.branchDashboard, orderRevenue = _j.orderRevenue, orderCount = _j.orderCount, orderStatusGrouping = _j.orderStatusGrouping, basketStatusGrouping = _j.basketStatusGrouping, usersDashboard = _j.usersDashboard;
    var _k = (0, react_1.useState)((_b = (_a = dashboardFilterPeriods_1.dashboardFilterPeriods[0]) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ""), selectedPeriod = _k[0], setSelectedPeriod = _k[1];
    var query = __assign(__assign({}, dashboardBranchPagination), { is_dashboard_request: true });
    //clientUser branches
    var _l = (0, react_1.useState)([]), clientUserBranches = _l[0], setClientUserBranches = _l[1];
    var _m = (0, react_1.useState)(undefined), selectedBranchId = _m[0], setSelectedBranchId = _m[1];
    var _o = (0, react_1.useState)(""), branch = _o[0], setBranch = _o[1];
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "ADMIN") {
            getClientUsers(__assign({}, pagination));
            dispatch((0, get_client_order_dashboard_thunk_1.get_order_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
            dispatch((0, get_client_basket_dashboard_thunk_1.get_basket_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
            dispatch((0, get_client_user_dashboard_thunk_1.get_client_user_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "AREA_MANAGER") {
            getClientUsers(__assign({}, pagination));
            dispatch((0, get_client_order_dashboard_thunk_1.get_order_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
            dispatch((0, get_client_basket_dashboard_thunk_1.get_basket_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    (0, react_1.useEffect)(function () {
        if (client_token && (user === null || user === void 0 ? void 0 : user.role) === "BRANCH_MANAGER") {
            getClientUsers(__assign({}, pagination));
            dispatch((0, get_client_order_dashboard_thunk_1.get_order_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
            dispatch((0, get_client_basket_dashboard_thunk_1.get_basket_client_dashboard_thunk)({ period: selectedPeriod, branch_id: selectedBranchId }));
        }
    }, [client_token, user, selectedPeriod, selectedBranchId]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (user && ((_a = Object.keys(user)) === null || _a === void 0 ? void 0 : _a.length) !== 0) {
            getLoggedInUserBranches();
        }
    }, [clientUsers]);
    (0, react_1.useEffect)(function () {
        onSearchBranches(searchDashboardBranches);
    }, [searchDashboardBranches]);
    var getClientUsers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/, dispatch((0, get_client_user_list_thunk_1.get_client_user_list_thunk)(query))];
    }); }); };
    var getBranches = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchBranches = function (search_string) {
        getBranches(__assign(__assign(__assign({}, dashboardBranchPagination), (search_string && { search_string: search_string })), { is_dashboard_request: true }));
    };
    var getLoggedInUserBranches = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!(user && (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN")) return [3 /*break*/, 1];
                    (0, get_client_user_branches_1.get_logged_in_client_user_branches)({
                        branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of,
                    }).then(function (response) {
                        var _a, _b, _c;
                        if (response.success) {
                            setClientUserBranches((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.branch_list);
                            setSelectedBranchId((_c = (_b = response === null || response === void 0 ? void 0 : response.content) === null || _b === void 0 ? void 0 : _b.branch_list[0]) === null || _c === void 0 ? void 0 : _c._id);
                        }
                    });
                    return [3 /*break*/, 3];
                case 1:
                    if (!((user === null || user === void 0 ? void 0 : user.role) === "ADMIN")) return [3 /*break*/, 3];
                    return [4 /*yield*/, dispatch((0, branch_list_thunk_1.user_branch_list_thunk)(__assign(__assign({}, dashboardBranchPagination), query)))];
                case 2:
                    response = _d.sent();
                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success) {
                        setClientUserBranches((_c = (_b = response === null || response === void 0 ? void 0 : response.payload) === null || _b === void 0 ? void 0 : _b.content) === null || _c === void 0 ? void 0 : _c.branch_list);
                    }
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "pb-4" },
        react_1.default.createElement("div", { className: "flex space-x-4 pt-6 items-center" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Welcome,", size: "large", textColor: "text-black", bold: true }),
            react_1.default.createElement("span", { className: "italic text-xl" }, user === null || user === void 0 ? void 0 :
                user.name,
                " ", user === null || user === void 0 ? void 0 :
                user.surname)),
        react_1.default.createElement("div", { className: "w-auto pt-4 mb-12 flex flex-row gap-4" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Select Period", size: "small", options: dashboardFilterPeriods_1.dashboardFilterPeriods, value: selectedPeriod, borderColor: "border-slate-400", textColor: "text-slate-400", labelColor: "text-slate-400", iconColor: "text-slate-400", onChange: function (value) {
                        var selectedOption = dashboardFilterPeriods_1.dashboardFilterPeriods === null || dashboardFilterPeriods_1.dashboardFilterPeriods === void 0 ? void 0 : dashboardFilterPeriods_1.dashboardFilterPeriods.find(function (option) { return option.value === value; });
                        setSelectedPeriod(selectedOption.value);
                    } })),
            (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && (react_1.default.createElement("div", { className: "w-96" },
                react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (branch) { return branch === null || branch === void 0 ? void 0 : branch.label; }, label: "Search a branch...", options: (_c = dashboardBranches === null || dashboardBranches === void 0 ? void 0 : dashboardBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })) !== null && _c !== void 0 ? _c : [], size: "small", value: branch, colors: {
                        borderColor: "border-slate-400",
                        textColor: "text-slate-400",
                        labelColor: "text-slate-400",
                        iconColor: "text-slate-400",
                    }, errorText: "", onChange: function (branchObject) {
                        setBranch(branchObject);
                        setSelectedBranchId(branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                        dispatch((0, get_client_order_dashboard_thunk_1.get_order_client_dashboard_thunk)({ period: selectedPeriod, branch_id: branch === null || branch === void 0 ? void 0 : branch.value }));
                        dispatch((0, get_client_basket_dashboard_thunk_1.get_basket_client_dashboard_thunk)({ period: selectedPeriod, branch_id: branch === null || branch === void 0 ? void 0 : branch.value }));
                        if ((user === null || user === void 0 ? void 0 : user.role) === "ADMIN") {
                            dispatch((0, get_client_user_dashboard_thunk_1.get_client_user_dashboard_thunk)({ period: selectedPeriod, branch_id: branch === null || branch === void 0 ? void 0 : branch.value }));
                        }
                    }, onSearchChanged: function (search_string) {
                        setSearchDashboardBranches(search_string);
                    } }))),
            (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" && (react_1.default.createElement("div", { className: "w-96" },
                react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (branch) { return branch === null || branch === void 0 ? void 0 : branch.label; }, label: "Search a branch...", options: (_d = clientUserBranches === null || clientUserBranches === void 0 ? void 0 : clientUserBranches.map(function (branch) { return ({ label: branch === null || branch === void 0 ? void 0 : branch.display_name, value: branch === null || branch === void 0 ? void 0 : branch._id }); })) !== null && _d !== void 0 ? _d : [], size: "small", value: branch, colors: {
                        borderColor: "border-slate-400",
                        textColor: "text-slate-400",
                        labelColor: "text-slate-400",
                        iconColor: "text-slate-400",
                    }, errorText: "", onChange: function (branchObject) { return __awaiter(void 0, void 0, void 0, function () {
                        var payload;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    setBranch(branchObject);
                                    setSelectedBranchId(branchObject === null || branchObject === void 0 ? void 0 : branchObject.value);
                                    payload = {
                                        period: selectedPeriod,
                                        branch_id: branchObject === null || branchObject === void 0 ? void 0 : branchObject.value,
                                    };
                                    return [4 /*yield*/, dispatch((0, get_client_order_dashboard_thunk_1.get_order_client_dashboard_thunk)(payload))];
                                case 1:
                                    _a.sent();
                                    return [4 /*yield*/, dispatch((0, get_client_basket_dashboard_thunk_1.get_basket_client_dashboard_thunk)(payload))];
                                case 2:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    }); }, onSearchChanged: function (search_string) {
                        setSearchDashboardBranches(search_string);
                    } })))),
        (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && (react_1.default.createElement("div", { className: "grid grid-cols-2 lg:grid-cols-4 gap-4" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: usersDashboard === null || usersDashboard === void 0 ? void 0 : usersDashboard.ratio, title: "Users", orientation: "horizontal", subTitle: usersDashboard === null || usersDashboard === void 0 ? void 0 : usersDashboard.total_users, colors: {
                        barFilledColor: "text-green-700",
                        barUnfilledColor: "text-gray-200",
                        subTitleColor: "text-black-700",
                        backgroundColor: "bg-white",
                        badge: {
                            backgroundColor: "bg-green-300",
                            iconColor: "text-lime-900",
                            percentageColor: "text-lime-900",
                            valueColor: "text-lime-900",
                            signColor: "text-lime-900",
                        },
                    }, badge: {
                        sign: pro_solid_svg_icons_1.faPlus,
                        value: usersDashboard === null || usersDashboard === void 0 ? void 0 : usersDashboard.new_users,
                    } })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: orderCount === null || orderCount === void 0 ? void 0 : orderCount.ratio, title: "Order Count", orientation: "horizontal", subTitle: orderCount === null || orderCount === void 0 ? void 0 : orderCount.total_orders, colors: {
                        barFilledColor: "text-green-700",
                        barUnfilledColor: "text-gray-200",
                        subTitleColor: "text-black-700",
                        backgroundColor: "bg-white",
                        dropdown: {
                            textColor: "text-purple-500",
                            iconBackgroundColor: "bg-purple-200",
                            iconColor: "text-purple-500",
                        },
                        badge: {
                            backgroundColor: "bg-green-300",
                            iconColor: "text-lime-900",
                            percentageColor: "text-lime-900",
                            valueColor: "text-lime-900",
                            signColor: "text-lime-900",
                        },
                    }, badge: {
                        sign: pro_solid_svg_icons_1.faPlus,
                        value: orderCount === null || orderCount === void 0 ? void 0 : orderCount.new_orders_count,
                    } })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: orderRevenue === null || orderRevenue === void 0 ? void 0 : orderRevenue.ratio, title: "Revenue", orientation: "horizontal", subTitle: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: orderRevenue === null || orderRevenue === void 0 ? void 0 : orderRevenue.total_revenue,
                        symbolSeparation: true,
                    }), colors: {
                        barFilledColor: "text-green-700",
                        barUnfilledColor: "text-gray-200",
                        subTitleColor: "text-black-700",
                        backgroundColor: "bg-white",
                        badge: {
                            backgroundColor: "bg-green-300",
                            iconColor: "text-lime-900",
                            percentageColor: "text-lime-900",
                            valueColor: "text-lime-900",
                            signColor: "text-lime-900",
                        },
                    }, badge: {
                        sign: pro_solid_svg_icons_1.faPlus,
                        value: doshx_controls_web_1.Utilities.formatMoney({
                            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                            value: orderRevenue === null || orderRevenue === void 0 ? void 0 : orderRevenue.new_orders_revenue,
                            symbolSeparation: true,
                        }),
                    } })),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: branchDashboard === null || branchDashboard === void 0 ? void 0 : branchDashboard.ratio, title: "Top Branch", orientation: "horizontal", subTitle: branchDashboard === null || branchDashboard === void 0 ? void 0 : branchDashboard.top_branch, colors: {
                        barFilledColor: "text-green-700",
                        barUnfilledColor: "text-purple-200",
                        subTitleColor: "text-black-700",
                        backgroundColor: "bg-white",
                        dropdown: {
                            textColor: "text-purple-500",
                            iconBackgroundColor: "bg-purple-200",
                            iconColor: "text-purple-500",
                        },
                        badge: {
                            backgroundColor: "bg-green-300",
                            iconColor: "text-lime-900",
                            percentageColor: "text-lime-900",
                            valueColor: "text-lime-900",
                            signColor: "text-lime-900",
                        },
                    }, badge: {
                        sign: pro_solid_svg_icons_1.faPlus,
                        value: doshx_controls_web_1.Utilities.formatMoney({
                            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                            value: branchDashboard === null || branchDashboard === void 0 ? void 0 : branchDashboard.branch_order_revenue,
                            symbolSeparation: true,
                        }),
                    } })))),
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4" },
            react_1.default.createElement(doughnutComponent_1.DoughnutComponent, { title: "Orders", dataSet: orderStatusGrouping }),
            react_1.default.createElement(doughnutComponent_1.DoughnutComponent, { title: "Baskets", dataSet: basketStatusGrouping })),
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 gap-4 pt-4" },
            react_1.default.createElement("div", null,
                react_1.default.createElement(barGraphComponent_1.BarGraphComponent, { title: "Orders", backgroundColor: "rgba(77,19,209)", belowBadgeValue: "Active Orders", newItems: orderCount === null || orderCount === void 0 ? void 0 : orderCount.new_orders_count })),
            react_1.default.createElement("div", null,
                react_1.default.createElement(barGraphComponent_1.BarGraphComponent, { title: "Parcels", backgroundColor: "rgba(0,0,230)", belowBadgeValue: "Active Parcels" })))));
};
exports.HomeDashboardView = HomeDashboardView;
