"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddAddressComponent = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var branch_1 = require("../../../../../../reducers/slices/branch/branch");
var constants_1 = require("./constants");
var main_1 = require("../../../../../../reducers/slices/main");
var AddAddressComponent = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var isEditing = _a.isEditing;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _x = (0, react_1.useState)(true), isInteracted = _x[0], setIsInteracted = _x[1];
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var errors = (0, validateForm_1.validateForm)(selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address, {
        initialValues: constants_1.initialAddressData,
        enabled: isInteracted,
    }).errors;
    var _onControlChange = function (value) {
        dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { address: __assign(__assign({}, selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address), value) })));
        // Trigger validation only if user has started interacting with the form
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };
    return (react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4" },
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Full Address" //
            , size: "medium", value: (_c = (_b = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _b === void 0 ? void 0 : _b.full_address) !== null && _c !== void 0 ? _c : "", onChange: function (full_address) { return _onControlChange({ full_address: full_address }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.full_address }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Line 1" //
            , size: "medium", value: (_e = (_d = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _d === void 0 ? void 0 : _d.line_one) !== null && _e !== void 0 ? _e : "", onChange: function (line_one) { return _onControlChange({ line_one: line_one }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.line_one }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Line 2" //
            , size: "medium", value: (_g = (_f = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _f === void 0 ? void 0 : _f.line_two) !== null && _g !== void 0 ? _g : "", onChange: function (line_two) { return _onControlChange({ line_two: line_two }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.line_two }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Latitude", size: "medium", value: (_j = (_h = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _h === void 0 ? void 0 : _h.latitude) !== null && _j !== void 0 ? _j : "", onChange: function (latitude) { return _onControlChange({ latitude: latitude }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.latitude }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Longitude", size: "medium", value: (_l = (_k = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _k === void 0 ? void 0 : _k.longitude) !== null && _l !== void 0 ? _l : "", onChange: function (longitude) { return _onControlChange({ longitude: longitude }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.longitude }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Town", size: "medium", value: (_o = (_m = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _m === void 0 ? void 0 : _m.town) !== null && _o !== void 0 ? _o : "", onChange: function (town) { return _onControlChange({ town: town }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.town }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "City", size: "medium", value: (_q = (_p = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _p === void 0 ? void 0 : _p.city) !== null && _q !== void 0 ? _q : "", onChange: function (city) { return _onControlChange({ city: city }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.city }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Province", size: "medium", value: (_s = (_r = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _r === void 0 ? void 0 : _r.province) !== null && _s !== void 0 ? _s : "", onChange: function (province) { return _onControlChange({ province: province }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.province }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Country", size: "medium", value: (_u = (_t = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _t === void 0 ? void 0 : _t.country) !== null && _u !== void 0 ? _u : "", onChange: function (country) { return _onControlChange({ country: country }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.country }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Postal Code", size: "medium", value: (_w = (_v = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _v === void 0 ? void 0 : _v.postal_code) !== null && _w !== void 0 ? _w : "", onChange: function (postal_code) { return _onControlChange({ postal_code: postal_code }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.postal_code }),
        react_1.default.createElement("div", { className: "flex flex-col items-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Update", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        dispatch((0, main_1.setContentModal)(null));
                        return [2 /*return*/];
                    });
                }); } }))));
};
exports.AddAddressComponent = AddAddressComponent;
