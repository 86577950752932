"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchTradingTimesPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var pages_1 = require("../../../../../../constants/pages");
var weekdays_1 = require("../../../../../../constants/weekdays");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var branch_1 = require("../../../../../../reducers/slices/branch/branch");
var branch_extra_data_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/branch_extra_data_thunk");
var branch_working_hours_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/branch_working_hours_thunk");
var toggle_branch_status_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/toggle_branch_status_thunk");
var constants_1 = require("../constants");
var constants_2 = require("./constants");
var BranchTradingTimesPage = function () {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _d = (0, react_1.useState)(1), currentPage = _d[0], setCurrentPage = _d[1];
    var _e = (0, react_1.useState)(10), rowsPerPage = _e[0], setRowsPerPage = _e[1];
    var _f = (0, react_1.useState)(false), disableDateTime = _f[0], setDisableDateTime = _f[1];
    var _g = (0, react_1.useState)(false), updateTableTradingTimes = _g[0], setUpdateTableTradingTimes = _g[1];
    var _h = (0, react_1.useState)(false), updateBranchExtraData = _h[0], setUpdateBranchExtraData = _h[1];
    var _j = (0, react_1.useState)(constants_2.initialExtraDataValues), branchExtraData = _j[0], setBranchExtraData = _j[1];
    var _k = (0, react_1.useState)(constants_2.initialBranchTradingTimesValues), tradingTimes = _k[0], setTradingTimes = _k[1];
    var _l = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), selectedBranch = _l.selectedBranch, isLoading = _l.isLoading;
    var _m = (0, validateForm_1.validateForm)(tradingTimes), tradingTimesError = _m.errors, tradingTimesIsValid = _m.isValid;
    var _o = (0, validateForm_1.validateForm)(branchExtraData), extraDataErrors = _o.errors, extraDataIsValid = _o.isValid;
    var workingHours = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.working_hours.map(function (hours) { return (__assign(__assign({}, hours), (hours.closed && { closed: "Closed", to: "--:--", from: "--:--" }))); });
    var _setTradingTimes = function (value) {
        setTradingTimes(__assign(__assign({}, tradingTimes), value));
    };
    var onChangeStatus = function (status) {
        switch (status) {
            case "Open 24hrs":
                setTradingTimes(__assign(__assign({}, constants_2.initialBranchTradingTimesValues), { status: status }));
                setDisableDateTime(true);
                break;
            case "Closed":
                setTradingTimes(__assign(__assign({}, constants_2.initialBranchTradingTimesValues), { status: status, closed: true }));
                setDisableDateTime(true);
                break;
            case "Open":
                setTradingTimes(__assign(__assign({}, tradingTimes), { status: status, closed: false }));
                setDisableDateTime(false);
                break;
        }
    };
    (0, react_1.useEffect)(function () {
        if (updateTableTradingTimes) {
            dispatch((0, branch_working_hours_thunk_1.branch_working_hours_thunk)());
            setUpdateTableTradingTimes(false);
        }
    }, [updateTableTradingTimes]);
    (0, react_1.useEffect)(function () {
        if (updateBranchExtraData) {
            dispatch((0, branch_extra_data_thunk_1.branch_extra_data_thunk)());
            setUpdateBranchExtraData(false);
        }
    }, [updateBranchExtraData]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "gap-4 flex flex-col", backgroundColor: "bg-transparent", borderColor: "border-white", addPadding: false },
        react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: false }),
        react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4" },
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 items-center justify-between" },
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Branch Status", cols: "grid-cols-2", options: [
                        { label: "Online", value: "on" },
                        { label: "Offline", value: "off" },
                    ], selectedValue: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.offline) ? "off" : "on", onChange: function (v) {
                        return dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { offline: v === "off" })));
                    }, required: true, colors: pages_1.radioButtonColorConfig }),
                react_1.default.createElement("div", { className: "flex justify-end" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { startIcon: pro_light_svg_icons_1.faSave, label: "Save", size: "small", loading: isLoading, onClick: function () {
                            if ((selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.offline) !== undefined) {
                                dispatch((0, toggle_branch_status_thunk_1.toggle_branch_status_thunk)({
                                    branch_id: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id,
                                    branch_status: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.offline,
                                }));
                            }
                        } }))),
            react_1.default.createElement(doshx_controls_web_1.TableControl, { title: "Trading Hours", 
                // currentPage={currentPage}
                // rowsPerPage={rowsPerPage}
                // totalRecords={2}
                columns: [
                    {
                        displayKey: "day",
                        label: "Working Hours",
                    },
                    {
                        displayKey: "from",
                        label: "Opening Time",
                    },
                    {
                        displayKey: "to",
                        label: "Closing Time",
                    },
                    {
                        displayKey: "closed",
                        label: "Closed",
                    },
                ], data: workingHours !== null && workingHours !== void 0 ? workingHours : [], rowActions: [
                    {
                        label: "Delete",
                        onClick: function (data) {
                            var _a;
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { working_hours: __spreadArray([], (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.working_hours) === null || _a === void 0 ? void 0 : _a.filter(function (hours) { return (hours === null || hours === void 0 ? void 0 : hours.day) !== (data === null || data === void 0 ? void 0 : data.day); }), true) })));
                            setUpdateTableTradingTimes(true);
                        },
                    },
                ], onSearch: function (searchText) { }, hasShadow: true, pagination: {
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: 2,
                    onPageChange: function (page) {
                        setCurrentPage(page);
                    },
                    onRowsPerPageChange: function (rows) {
                        setRowsPerPage(rows);
                    },
                }, colors: pages_1.tableColorConfig }),
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-4 gap-4 pt-4" },
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Select Day", required: true, options: weekdays_1.daysOfTheWeek.map(function (bc) {
                        return { label: bc.day, value: bc.day };
                    }), iconColor: "text-primary", size: "small", value: (_a = tradingTimes === null || tradingTimes === void 0 ? void 0 : tradingTimes.day) !== null && _a !== void 0 ? _a : "", onChange: function (day) {
                        _setTradingTimes({ day: day });
                    }, errorText: tradingTimesError === null || tradingTimesError === void 0 ? void 0 : tradingTimesError.day }),
                react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Select Status", required: true, options: constants_1.storeStatus.map(function (bc) {
                        return { label: bc.status, value: bc.status };
                    }), textColor: "text-primary", iconColor: "text-primary", size: "small", value: (_b = tradingTimes === null || tradingTimes === void 0 ? void 0 : tradingTimes.status) !== null && _b !== void 0 ? _b : "", onChange: function (status) { return onChangeStatus(status); }, errorText: tradingTimesError === null || tradingTimesError === void 0 ? void 0 : tradingTimesError.status }),
                react_1.default.createElement(doshx_controls_web_1.TimePickerControl, { label: "Opening Time", size: "small", disabled: disableDateTime, required: true, inputFormat: "HH:mm", value: tradingTimes.from, onChange: function (from) {
                        _setTradingTimes({ from: from });
                    }, errorText: tradingTimesError === null || tradingTimesError === void 0 ? void 0 : tradingTimesError.from }),
                react_1.default.createElement(doshx_controls_web_1.TimePickerControl, { label: "Closing Time", size: "small", disabled: disableDateTime, required: true, inputFormat: "HH:mm", value: tradingTimes.to, onChange: function (to) {
                        _setTradingTimes({ to: to });
                    }, errorText: tradingTimesError === null || tradingTimesError === void 0 ? void 0 : tradingTimesError.to })),
            react_1.default.createElement("div", { className: "flex items-center justify-end pt-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { startIcon: pro_light_svg_icons_1.faSave, enabled: tradingTimesIsValid, label: "Save", size: "small", loading: isLoading, onClick: function () {
                        var _a, _b;
                        var tradingTimeExists = ((_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.working_hours) !== null && _a !== void 0 ? _a : []).find(function (value) { return value.day === tradingTimes.day; });
                        if (tradingTimeExists) {
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { working_hours: __spreadArray([], (_b = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.working_hours) === null || _b === void 0 ? void 0 : _b.map(function (hour) {
                                    if ((hour === null || hour === void 0 ? void 0 : hour.day) === (tradingTimeExists === null || tradingTimeExists === void 0 ? void 0 : tradingTimeExists.day)) {
                                        return tradingTimes;
                                    }
                                    else {
                                        return hour;
                                    }
                                }), true) })));
                        }
                        else {
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { working_hours: __spreadArray(__spreadArray([], selectedBranch.working_hours, true), [tradingTimes], false) })));
                        }
                        setUpdateTableTradingTimes(true);
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-1" },
            react_1.default.createElement(doshx_controls_web_1.TableControl, { title: "Branch Extra Data", 
                // currentPage={currentPage}
                // rowsPerPage={rowsPerPage}
                // totalRecords={2}
                columns: [
                    {
                        displayKey: "name",
                        label: "Name",
                    },
                    {
                        displayKey: "value",
                        label: "Value",
                    },
                ], data: (_c = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.extra_data) !== null && _c !== void 0 ? _c : [], rowActions: [
                    {
                        label: "Delete",
                        onClick: function (data) {
                            var _a;
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { extra_data: __spreadArray([], (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.extra_data) === null || _a === void 0 ? void 0 : _a.filter(function (extraData) { return (extraData === null || extraData === void 0 ? void 0 : extraData.name) !== (data === null || data === void 0 ? void 0 : data.name); }), true) })));
                            setUpdateBranchExtraData(true);
                        },
                    },
                ], onSearch: function (searchText) { }, hasShadow: true, pagination: {
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: 2,
                    onPageChange: function (page) {
                        setCurrentPage(page);
                    },
                    onRowsPerPageChange: function (rows) {
                        setRowsPerPage(rows);
                    },
                }, colors: pages_1.tableColorConfig }),
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row w-full pt-4 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Name", required: true, size: "medium", value: branchExtraData.name, onChange: function (v) {
                        var _a;
                        setBranchExtraData(__assign(__assign({}, branchExtraData), (_a = {}, _a["name"] = v, _a)));
                    }, errorText: extraDataErrors === null || extraDataErrors === void 0 ? void 0 : extraDataErrors.name }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Value", required: true, size: "medium", value: branchExtraData.value, onChange: function (v) {
                        var _a;
                        return setBranchExtraData(__assign(__assign({}, branchExtraData), (_a = {}, _a["value"] = v, _a)));
                    }, errorText: extraDataErrors === null || extraDataErrors === void 0 ? void 0 : extraDataErrors.value }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add", size: "small", textColor: "text-white", enabled: extraDataIsValid, endIcon: pro_light_svg_icons_1.faPlus, loading: isLoading, onClick: function () {
                        var _a, _b;
                        var tableExtraDataExists = ((_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.extra_data) !== null && _a !== void 0 ? _a : []).find(function (value) { return value.name === branchExtraData.name; });
                        if (tableExtraDataExists) {
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { extra_data: __spreadArray([], (_b = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.extra_data) === null || _b === void 0 ? void 0 : _b.map(function (extraData) {
                                    if ((extraData === null || extraData === void 0 ? void 0 : extraData.name) === (branchExtraData === null || branchExtraData === void 0 ? void 0 : branchExtraData.name)) {
                                        return branchExtraData;
                                    }
                                    else {
                                        return extraData;
                                    }
                                }), true) })));
                        }
                        else {
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { extra_data: __spreadArray(__spreadArray([], selectedBranch.extra_data, true), [branchExtraData], false) })));
                        }
                        setUpdateBranchExtraData(true);
                    } })))));
};
exports.BranchTradingTimesPage = BranchTradingTimesPage;
