"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var OnRouteComponent = function (props) {
    var _a;
    var setSelectedTrip = props.setSelectedTrip, selectedTrip = props.selectedTrip;
    var dispatch = (0, react_redux_1.useDispatch)();
    var trips = (0, react_redux_1.useSelector)(function (state) { return state.tripState; }).trips;
    var _b = (0, react_1.useState)(1), currentPage = _b[0], setCurrentPage = _b[1];
    (0, react_1.useEffect)(function () { }, []);
    var tripsInTransit = trips === null || trips === void 0 ? void 0 : trips.filter(function (x) { return x.status === "IN-TRANSIT"; });
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("hr", { className: "mt-4" }),
        react_1.default.createElement("div", { className: "flex gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total", bold: true, textColor: "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_a = tripsInTransit === null || tripsInTransit === void 0 ? void 0 : tripsInTransit.length) !== null && _a !== void 0 ? _a : 0) })), tripsInTransit === null || tripsInTransit === void 0 ? void 0 :
        tripsInTransit.map(function (x) { return (react_1.default.createElement("div", { key: x._id, className: "".concat((selectedTrip === null || selectedTrip === void 0 ? void 0 : selectedTrip._id) === x._id ? "shadow border rounded" : "", " p-2") },
            react_1.default.createElement("div", { className: "flex justify-between" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Trip Created:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatDateTime(x.updatedAt) })),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Driver:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(x.driver_name, " ").concat(x.driver_surname) }))),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Trip Status:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: x.status })),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Est\u2019 Deliveries finish time:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "" })))),
            react_1.default.createElement("hr", null),
            react_1.default.createElement("div", { className: "flex justify-center p-4" }, (selectedTrip === null || selectedTrip === void 0 ? void 0 : selectedTrip._id) === x._id ?
                react_1.default.createElement("div", { key: x._id },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                            setSelectedTrip(null);
                        }, icon: pro_solid_svg_icons_1.faChevronUp })) :
                react_1.default.createElement("div", { key: x._id },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "View trip", 
                        //  endIcon={faPlus}
                        size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                            setSelectedTrip(x);
                        } }))))); }),
        react_1.default.createElement("hr", null),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.PaginationControl, { currentPage: currentPage, totalRecords: 0, recordsPerPage: 10, onClick: function (page) {
                    // console.log(page);
                    setCurrentPage(page);
                } }))));
};
exports.default = OnRouteComponent;
