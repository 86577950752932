"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TermStorageComponent = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pages_1 = require("../../../../../constants/pages");
var get_term_storage_thunk_1 = require("../../../../../reducers/slices/searchTerms/thunks/get_term_storage_thunk");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var TermStorageComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), searchTerm = _a[0], setSearchTerm = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.searchTermsState; }), termStorage = _b.termStorage, isLoading = _b.isLoading;
    var _c = (0, react_1.useState)({
        from_date: "",
        to_date: "",
        search_string: "",
        distinct: false,
    }), filters = _c[0], setFilters = _c[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, get_term_storage_thunk_1.get_term_storage_thunk)({}));
    }, []);
    (0, react_1.useEffect)(function () {
        dispatch((0, get_term_storage_thunk_1.get_term_storage_thunk)(__assign(__assign({}, filters), { search_string: searchTerm })));
    }, [searchTerm]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "bg-white flex flex-col gap-4 p-4 rounded" },
            react_1.default.createElement("div", { className: "flex justify-between" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Date Filter", bold: true, textColor: "text-black" }),
                    react_1.default.createElement("div", { className: "flex gap-2 w-72 relative bg-white p-4 rounded" },
                        react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: new Date(filters.from_date), onChange: function (v) {
                                setFilters(__assign(__assign({}, filters), { from_date: v.toISOString().split("T")[0] }));
                            }, size: "small" }),
                        react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: new Date(filters.to_date), onChange: function (v) {
                                setFilters(__assign(__assign({}, filters), { to_date: v.toISOString().split("T")[0] }));
                            }, size: "small" }),
                        react_1.default.createElement("div", { className: "absolute bottom-0 right-0 pr-1 pb-1 pt-2" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: function () {
                                    dispatch((0, get_term_storage_thunk_1.get_term_storage_thunk)(__assign({}, filters)));
                                } })))),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", 
                    // value={searchTerm}
                    onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            setSearchTerm(e);
                            return [2 /*return*/];
                        });
                    }); } })),
            react_1.default.createElement("div", { className: "flex justify-end" },
                react_1.default.createElement("div", { className: "flex gap-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.SwitchControl, { disabled: false, label: "Include replicas", required: true, 
                            // checked={filters?.distinct}
                            onChange: function (checked) {
                                dispatch((0, get_term_storage_thunk_1.get_term_storage_thunk)(__assign(__assign({}, filters), { distinct: checked })));
                            }, colors: {
                                thumbColor: true ? "bg-blue-500" : "bg-gray-500",
                                titleColor: "text-blue-500",
                                labelColor: "text-black",
                                errorTextColor: "text-red-500",
                            } }))))),
        react_1.default.createElement("div", { className: "flex flex-col bg-white gap-4 rounded" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Saved term", bold: true, textColor: "text-black", className: "m-4" }),
            isLoading ? (react_1.default.createElement("div", { className: "grid justify-center p-4" },
                react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl //
                , { size: "large" }))) : (react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "term",
                        label: "Term",
                    },
                    {
                        displayKey: "createdAt",
                        label: "Date",
                        formatter: function (basket) {
                            if (basket) {
                                return doshx_controls_web_1.Utilities.formatDateTime(basket === null || basket === void 0 ? void 0 : basket.createdAt, "YYYY-MM-DD");
                            }
                            else {
                                return "";
                            }
                        },
                    },
                ], data: termStorage !== null && termStorage !== void 0 ? termStorage : [], hasShadow: false, colors: pages_1.tableColorConfig })))));
};
exports.TermStorageComponent = TermStorageComponent;
