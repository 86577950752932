"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockManagementPage = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var add_product_stock_1 = require("../../../../../../api/calls/product/product_stock/add_product_stock");
var get_product_stock_1 = require("../../../../../../api/calls/product/product_stock/get_product_stock");
var update_stock_list_1 = require("../../../../../../api/calls/product/product_stock/update_stock_list");
var constants_1 = require("../../../../../../constants/constants");
var pages_1 = require("../../../../../../constants/pages");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var main_1 = require("../../../../../../reducers/slices/main");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var StockManagementPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(1100), searchString = _a[0], setSearchString = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), productList = _b.products, loading = _b.loading, pagination = _b.pagination;
    var _c = (0, react_1.useState)(pages_1.initialSortValues), sorter = _c[0], setSorter = _c[1];
    var _d = (0, react_1.useState)([]), productStock = _d[0], setProductStock = _d[1];
    var _e = (0, react_1.useState)(), updatedProduct = _e[0], setUpdatedProduct = _e[1];
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var findStock = function (variationId) {
        var _a;
        return (_a = productStock === null || productStock === void 0 ? void 0 : productStock.find(function (_a) {
            var variation_id = _a.variation_id;
            return variation_id === variationId;
        })) !== null && _a !== void 0 ? _a : {};
    };
    var findVariationById = function (variations, id, index) {
        var _a;
        if (index === void 0) { index = 0; }
        if (index >= variations.length) {
            return null;
        }
        var variation = variations[index];
        if ((variation === null || variation === void 0 ? void 0 : variation._id) === id) {
            return variation;
        }
        if (Array.isArray(variation === null || variation === void 0 ? void 0 : variation.variations)) {
            var subVariation = findVariationById(variation === null || variation === void 0 ? void 0 : variation.variations, id);
            if (subVariation) {
                return subVariation;
            }
        }
        return (_a = findVariationById(variations, id, index + 1)) !== null && _a !== void 0 ? _a : {};
    };
    var getProductStock = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_product_stock_1.get_product_stock)({
                        product_id: updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.product_id,
                    })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        setProductStock(response.content);
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var existingStock = findStock(updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.variant_value_id);
    var products = productList.map(function (_a) {
        var _b, _c, _d, _e, _f, _g;
        var product_id = _a._id, pid = _a.pid, sku = _a.sku, image_url = _a.image_url, title = _a.title, price = _a.price, variations = _a.variations;
        var branchStock = (_b = existingStock === null || existingStock === void 0 ? void 0 : existingStock.stock_list) === null || _b === void 0 ? void 0 : _b.find(function (stock) { return stock.branch_id === (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id); });
        var productData = {
            title: title,
            price: price,
            pid: pid,
            sku: sku,
            image_url: image_url,
            product_id: product_id,
            variations: variations,
            branch_id: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id,
        };
        var updatedData = {
            variant_type_id: updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.variant_type_id,
            variant_value_id: (_c = updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.variant_value_id) !== null && _c !== void 0 ? _c : existingStock === null || existingStock === void 0 ? void 0 : existingStock.variant_id,
            stock_level: (_e = (_d = updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.stock_level) !== null && _d !== void 0 ? _d : branchStock === null || branchStock === void 0 ? void 0 : branchStock.stock_level) !== null && _e !== void 0 ? _e : undefined,
            minimum_stock_level: (_g = (_f = updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.minimum_stock_level) !== null && _f !== void 0 ? _f : branchStock === null || branchStock === void 0 ? void 0 : branchStock.minimum_stock_level) !== null && _g !== void 0 ? _g : undefined,
        };
        return (updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.product_id) === product_id ? __assign(__assign({}, productData), updatedData) : __assign({}, productData);
    });
    var _getProducts = function (query) {
        if (query === void 0) { query = {}; }
        return dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, pagination), query)));
    };
    var _setProductStockData = function (_a) {
        var product = _a.product, value = __rest(_a, ["product"]);
        var updatedStockData = __assign(__assign({}, product), value);
        setUpdatedProduct(updatedStockData);
    };
    var _onStockLevelChanged = function () { return __awaiter(void 0, void 0, void 0, function () {
        var pid, sku, product_id, variation_id, branch_id, stock_level, minimum_stock_level, existingStocks, response;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    pid = updatedProduct.pid, sku = updatedProduct.sku, product_id = updatedProduct.product_id, variation_id = updatedProduct.variant_value_id, branch_id = updatedProduct.branch_id, stock_level = updatedProduct.stock_level, minimum_stock_level = updatedProduct.minimum_stock_level;
                    existingStocks = ((_b = (_a = existingStock === null || existingStock === void 0 ? void 0 : existingStock.stock_list) === null || _a === void 0 ? void 0 : _a.filter(function (stock) { return stock.branch_id !== branch_id; })) !== null && _b !== void 0 ? _b : []).map(function (_a) {
                        var _id = _a._id, stock = __rest(_a, ["_id"]);
                        return (__assign({}, stock));
                    });
                    if (!(existingStock === null || existingStock === void 0 ? void 0 : existingStock._id))
                        return [2 /*return*/, _createProductStock()];
                    return [4 /*yield*/, (0, update_stock_list_1.update_stock_list)({
                            _id: existingStock._id,
                            pid: pid,
                            sku: sku,
                            product_id: product_id,
                            variation_id: variation_id,
                            stock_list: __spreadArray(__spreadArray([], existingStocks, true), [
                                {
                                    branch_id: branch_id,
                                    stock_level: String(stock_level),
                                    minimum_stock_level: String(minimum_stock_level),
                                    type: (_c = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _c === void 0 ? void 0 : _c.method,
                                },
                            ], false),
                        })];
                case 1:
                    response = _d.sent();
                    if (response.success) {
                        _getProducts();
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var _createProductStock = function () { return __awaiter(void 0, void 0, void 0, function () {
        var pid, sku, product_id, variation_id, branch_id, stock_level, minimum_stock_level, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    pid = updatedProduct.pid, sku = updatedProduct.sku, product_id = updatedProduct.product_id, variation_id = updatedProduct.variant_value_id, branch_id = updatedProduct.branch_id, stock_level = updatedProduct.stock_level, minimum_stock_level = updatedProduct.minimum_stock_level;
                    return [4 /*yield*/, (0, add_product_stock_1.add_product_stock)({
                            product_id: product_id,
                            pid: pid,
                            sku: sku,
                            variation_id: variation_id,
                            stock_list: [
                                {
                                    branch_id: branch_id,
                                    stock_level: String(stock_level),
                                    minimum_stock_level: String(minimum_stock_level),
                                    type: (_a = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _a === void 0 ? void 0 : _a.method,
                                },
                            ],
                        })];
                case 1:
                    response = _b.sent();
                    if (response.success) {
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var _getVariationTypes = function (variations) {
        return variations === null || variations === void 0 ? void 0 : variations.map(function (variation) { return ({ label: variation.title, value: variation._id }); });
    };
    var _getVariationValues = function (variations) {
        return variations
            .filter(function (variation) { return (updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.variant_type_id) === (variation === null || variation === void 0 ? void 0 : variation._id); })
            .map(function (variation) {
            return ((variation === null || variation === void 0 ? void 0 : variation.variations) || []).map(function (variation) { return ({
                label: variation.title,
                value: variation === null || variation === void 0 ? void 0 : variation._id,
            }); });
        })
            .flat();
    };
    var _onPageChange = function (page) {
        return _getProducts({
            page: page,
        });
    };
    var _onRowsPerPageChange = function (rows) {
        return _getProducts({
            rows: rows,
        });
    };
    var onSortTable = function (sorter) {
        setSorter(sorter);
        _getProducts({ sort_by: sorter.key, order_by: sorter.direction });
    };
    (0, react_1.useEffect)(function () {
        if (updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.product_id)
            getProductStock();
    }, [updatedProduct]);
    (0, react_1.useEffect)(function () {
        if (client_token && !loading)
            _getProducts({ search_string: searchString });
    }, [searchString, client_token]);
    (0, react_1.useEffect)(function () {
        if ((updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.stock_level) || (updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.minimum_stock_level)) {
            _onStockLevelChanged();
        }
    }, [updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.stock_level, updatedProduct === null || updatedProduct === void 0 ? void 0 : updatedProduct.minimum_stock_level]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "gap-4 flex flex-col", backgroundColor: "bg-transparent", borderColor: "border-white", addPadding: false },
        react_1.default.createElement("div", { className: "flex pt-4 justify-end" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: setSearchString })),
        react_1.default.createElement("div", { className: "grid grid-cols-1" },
            react_1.default.createElement(doshx_controls_web_1.TableControl, { title: "", sort: {
                    value: sorter,
                    onChange: onSortTable,
                }, columns: [
                    {
                        displayKey: "image_url",
                        label: "Image",
                        formatter: function (o) { return react_1.default.createElement("img", { src: o.image_url, width: 50, className: "h-10 rounded-md" }); },
                    },
                    {
                        displayKey: "title",
                        label: "Product Name",
                    },
                    {
                        displayKey: "price",
                        label: "Price",
                    },
                    {
                        displayKey: "variant_type_id",
                        label: "Variant Type",
                        formatter: function (product) {
                            var _a;
                            return (react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Select", size: "small", options: _getVariationTypes(product.variations), value: (_a = product.variant_type_id) !== null && _a !== void 0 ? _a : "", borderColor: "border-slate-400", textColor: "text-slate-400", labelColor: "text-slate-400", iconColor: "text-slate-400", onChange: function (variant_type_id) { return _setProductStockData({ variant_type_id: variant_type_id, product: product }); } }));
                        },
                    },
                    {
                        displayKey: "variant_value_id",
                        label: "Variant Value",
                        formatter: function (product) {
                            var _a;
                            return (react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Select", size: "small", options: _getVariationValues(product.variations), value: (_a = product.variant_value_id) !== null && _a !== void 0 ? _a : "", borderColor: "border-slate-400", textColor: "text-slate-400", labelColor: "text-slate-400", iconColor: "text-slate-400", onChange: function (variant_value_id) { return _setProductStockData({ variant_value_id: variant_value_id, product: product }); } }));
                        },
                    },
                    {
                        displayKey: "stock_level",
                        label: "Stock Level",
                        formatter: function (product) {
                            var _a;
                            return (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { size: "small", type: "number", disabled: !(product === null || product === void 0 ? void 0 : product.variant_value_id), borderColor: "border-transaparent", value: (_a = product === null || product === void 0 ? void 0 : product.stock_level) !== null && _a !== void 0 ? _a : 0, onChange: function (stock_level) { return !!stock_level && _setProductStockData({ stock_level: stock_level, product: product }); } }));
                        },
                    },
                    {
                        displayKey: "minimum_stock_level",
                        label: "Min Stock Level",
                        formatter: function (product) {
                            var _a;
                            return (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { size: "small", type: "number", disabled: !(product === null || product === void 0 ? void 0 : product.variant_value_id), borderColor: "border-transaparent", value: (_a = product === null || product === void 0 ? void 0 : product.minimum_stock_level) !== null && _a !== void 0 ? _a : 0, onChange: function (minimum_stock_level) { return !!minimum_stock_level && _setProductStockData({ minimum_stock_level: minimum_stock_level, product: product }); } }));
                        },
                    },
                ], data: products, hasShadow: true, pagination: {
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: _onPageChange,
                    onRowsPerPageChange: _onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig }))));
};
exports.StockManagementPage = StockManagementPage;
