"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var sanitiseObject_1 = require("../../../../../../helpers/sanitiseObject");
var main_1 = require("../../../../../../reducers/slices/main");
var HttpsAuth = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h;
    var value = _a.value, onChange = _a.onChange;
    var dispatch = (0, react_redux_1.useDispatch)();
    var authMethodOptions = [
        { label: "None", value: "NONE" },
        { label: "Webhook Secret", value: "SECRET" },
        { label: "Bearer Token", value: "BEARER" },
        { label: "Azure AD OAUTH2", value: "AZURE" },
    ];
    var _j = (0, react_1.useState)((_b = structuredClone(value)) !== null && _b !== void 0 ? _b : { method: "NONE", config: {} }), authSettings = _j[0], setAuthSettings = _j[1];
    var _k = (0, react_1.useState)(), errorText = _k[0], setErrorText = _k[1];
    var setConfigValues = function (newValues) {
        setAuthSettings(__assign(__assign({}, authSettings), { config: __assign(__assign({}, authSettings === null || authSettings === void 0 ? void 0 : authSettings.config), newValues) }));
    };
    var _onSave = function () {
        var sanitisedAuth = (0, sanitiseObject_1.sanitiseObjectRecursively)(authSettings);
        var valid = validate();
        if (!valid)
            return;
        console.log("sanitisedAuth", sanitisedAuth);
        onChange(sanitisedAuth);
        dispatch((0, main_1.setContentModal)(null));
    };
    var validate = function () {
        var _a, _b, _c, _d, _e, _f;
        var validation = {};
        switch (authSettings.method) {
            case "SECRET":
                Object.assign(validation, {
                    secret: ((_a = authSettings.config) === null || _a === void 0 ? void 0 : _a.secret) ? null : "Secret is required",
                });
                break;
            case "BEARER":
                Object.assign(validation, {
                    access_token: ((_b = authSettings.config) === null || _b === void 0 ? void 0 : _b.access_token) ? null : "Access Token is required",
                });
                break;
            case "AZURE":
                Object.assign(validation, {
                    tenant: ((_c = authSettings.config) === null || _c === void 0 ? void 0 : _c.tenant) ? null : "Tenant is required",
                    client_id: ((_d = authSettings.config) === null || _d === void 0 ? void 0 : _d.client_id) ? null : "Client ID is required",
                    scope: ((_e = authSettings.config) === null || _e === void 0 ? void 0 : _e.scope) ? null : "Scope is required",
                    client_secret: ((_f = authSettings.config) === null || _f === void 0 ? void 0 : _f.client_secret) ? null : "Client Secret is required",
                });
                break;
            default:
                return true;
        }
        var sanitisedObject = (0, sanitiseObject_1.sanitiseObject)(validation);
        setErrorText(sanitisedObject);
        if (Object.keys(sanitisedObject).length > 0)
            return false;
        return true;
    };
    (0, react_1.useEffect)(function () {
        var config = authSettings.method === (value === null || value === void 0 ? void 0 : value.method) ? value === null || value === void 0 ? void 0 : value.config : {};
        setAuthSettings(__assign(__assign({}, authSettings), { config: config }));
    }, [authSettings.method]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Method", options: authMethodOptions, labelColor: "text-black", textColor: "text-black", borderColor: "border-gray-500", iconColor: "text-black", size: "small", value: authSettings.method, onChange: function (method) {
                setAuthSettings(__assign(__assign({}, authSettings), { method: method }));
            } }),
        authSettings.method === "NONE" && (value === null || value === void 0 ? void 0 : value.method) !== "NONE" && (react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement("div", { className: "w-40" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return _onSave(); } })))),
        authSettings.method === "SECRET" && (react_1.default.createElement("div", { className: "flex flex-col gap-4 max-w-md" },
            react_1.default.createElement("div", { className: "flex flex-col px-4 gap-4 border-borders border-radius-2" },
                react_1.default.createElement("div", { className: "flex flex-col lg:flex-row items-center justify-between" },
                    react_1.default.createElement("div", { className: "flex justify-start" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Auth details", bold: true, textColor: "text-black" }))),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Webhook Secret", type: "password", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_c = authSettings.config) === null || _c === void 0 ? void 0 : _c.secret, onChange: function (secret) { return setConfigValues({ secret: secret }); }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.secret }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. 0987tgyJO098y7t6r789o0988u9y7t6rfgy89i", size: "xsmall" }))),
            react_1.default.createElement("div", { className: "flex justify-center" },
                react_1.default.createElement("div", { className: "w-40" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return _onSave(); } }))),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "The webhook secret is used to generate a hash of the body. " +
                    "This hash is sent in the 'X-LetsTrade-Hash' header can be used to verify the request came from LetsTrade.", size: "xsmall", center: true }))),
        authSettings.method === "BEARER" && (react_1.default.createElement("div", { className: "flex flex-col gap-4 max-w-md" },
            react_1.default.createElement("div", { className: "flex flex-col px-4 gap-4 border-borders border-radius-2" },
                react_1.default.createElement("div", { className: "flex flex-col lg:flex-row items-center justify-between" },
                    react_1.default.createElement("div", { className: "flex justify-start" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Auth details", bold: true, textColor: "text-black" }))),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Bearer Token", type: "password", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_d = authSettings.config) === null || _d === void 0 ? void 0 : _d.access_token, onChange: function (access_token) {
                            if (access_token.startsWith("Bearer "))
                                access_token = access_token.replace("Bearer ", "");
                            return setConfigValues({ access_token: access_token });
                        }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.access_token }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ...", size: "xsmall" }))),
            react_1.default.createElement("div", { className: "flex justify-center" },
                react_1.default.createElement("div", { className: "w-40" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return _onSave(); } }))),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "The bearer token is used to authenticate the request. " +
                    "This token is sent in the 'Authorization' header and can be used to verify the request came from LetsTrade.", size: "xsmall", center: true }))),
        authSettings.method === "AZURE" && (react_1.default.createElement("div", { className: "flex flex-col gap-4 max-w-md" },
            react_1.default.createElement("div", { className: "flex flex-col px-4 gap-4 border-borders border-radius-2" },
                react_1.default.createElement("div", { className: "flex flex-col lg:flex-row items-center justify-between" },
                    react_1.default.createElement("div", { className: "flex justify-start" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Auth details", bold: true, textColor: "text-black" }))),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Tenant", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_e = authSettings.config) === null || _e === void 0 ? void 0 : _e.tenant, onChange: function (tenant) { return setConfigValues({ tenant: tenant }); }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.tenant }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. 'example.com' or GUID '535fb089-9ff3-47b6-9bfb-4f1264799865'", size: "xsmall" })),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Client ID", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_f = authSettings.config) === null || _f === void 0 ? void 0 : _f.client_id, onChange: function (client_id) { return setConfigValues({ client_id: client_id }); }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.client_id }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. 535fb089-9ff3-47b6-9bfb-4f1264799865", size: "xsmall" })),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Client Secret", type: "password", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_g = authSettings.config) === null || _g === void 0 ? void 0 : _g.client_secret, onChange: function (client_secret) { return setConfigValues({ client_secret: client_secret }); }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.client_secret })),
                react_1.default.createElement("div", { className: "flex flex-col" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "scope", borderColor: "border-borders", textColor: "text-black", labelColor: "text-black", required: true, value: (_h = authSettings.config) === null || _h === void 0 ? void 0 : _h.scope, onChange: function (scope) { return setConfigValues({ scope: scope }); }, errorText: errorText === null || errorText === void 0 ? void 0 : errorText.scope }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. https://graph.microsoft.com/.default", size: "xsmall" }))),
            react_1.default.createElement("div", { className: "flex justify-center" },
                react_1.default.createElement("div", { className: "w-40" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return _onSave(); } }))),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Clicking connect will attempt to request an access token using the infromation provided." +
                    "These details will be saved to obtain new tokens upon expiry of the current one.", size: "xsmall", center: true })))));
};
exports.default = HttpsAuth;
