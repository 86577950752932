"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../../reducers/slices/console");
var subscriptions_1 = require("../../../../../reducers/slices/subscriptions/subscriptions");
var get_subscription_details_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/get_subscription_details_thunk");
var get_subscription_user_redemptions_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/get_subscription_user_redemptions_thunk");
var get_subscription_products_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/get_subscription_products_thunk");
var pages_1 = require("../../../../../constants/pages");
var constants_1 = require("./constants");
var formatDate_1 = require("../../../../../helpers/formatDate");
var SubscriptionDetailsPage = function () {
    var _a, _b, _c, _d, _e;
    var _f = (0, react_router_dom_1.useParams)(), subscription_id = _f.subscription_id, user_id = _f.user_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _g = (0, react_redux_1.useSelector)(function (state) { return state.subscriptionsState; }), selectedSubscription = _g.selectedSubscription, subscriptionUserRedemption = _g.subscriptionUserRedemption, subscriptionProducts = _g.subscriptionProducts, subscriptionUserRedemptionPagination = _g.subscriptionUserRedemptionPagination, subscriptionProductsPagination = _g.subscriptionProductsPagination;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Subscription Details"));
        console.log("selectedSubscription ===>", selectedSubscription);
    }, []);
    (0, react_1.useEffect)(function () {
        if (!(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription._id)) {
            dispatch((0, get_subscription_details_thunk_1.get_subscription_details_thunk)({
                subscription_id: subscription_id,
            }));
        }
        if (!user_id) {
            dispatch((0, get_subscription_user_redemptions_thunk_1.get_subscription_user_redemptions_thunk)({
                subscription_id: subscription_id,
            }));
        }
        dispatch((0, get_subscription_products_thunk_1.get_subscription_products_thunk)({
            subscription_id: subscription_id,
        }));
    }, []);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                    dispatch((0, subscriptions_1.setSelectedSubscription)(constants_1.initialSubscriptionValues));
                    navigate(-1);
                } })),
        react_1.default.createElement("div", { className: "bg-tableBackgroundColor rounded-md grid grid-cols-1 gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "m-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.name, textColor: "text-black", bold: true, size: "medium" })),
                react_1.default.createElement("div", { className: "grid grid-cols-4 gap-4" },
                    react_1.default.createElement("div", { className: "m-4" },
                        react_1.default.createElement("img", { src: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.image })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Description", className: "pb-2 text-black text-sm", bold: true }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "", lines: 5, borderColor: "border-borders", value: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.description, onChange: function (v) { } })),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Purchase Date", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, formatDate_1.formatDate)(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.createdAt), size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Payment Type", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.payment_type, size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.status, size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Start Date", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, formatDate_1.formatDate)(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.start_date), size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "End Date", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, formatDate_1.formatDate)(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.end_date), size: "xsmall" }))),
                    react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Last Redemption Date", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (0, formatDate_1.formatDate)(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.last_redemption_date), size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total Redemption", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_c = (_b = (_a = selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.redemption) === null || _a === void 0 ? void 0 : _a.redeemed_subscriptions) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0), size: "xsmall" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Payment Retries", bold: true, textColor: "text-black", size: "xsmall" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.retry_count, size: "xsmall" })))))),
        react_1.default.createElement("div", { className: "bg-tableBackgroundColor rounded-md grid grid-cols-1" },
            react_1.default.createElement("div", { className: "flex justify-between items-center bg-white pl-2.5 p-2 rounded-t-lg" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Redemption", textColor: "text-black", bold: true, size: "medium" })),
            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.redemptionsTableColumns, data: user_id ? (_e = (_d = selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.redemption) === null || _d === void 0 ? void 0 : _d.redeemed_subscriptions) !== null && _e !== void 0 ? _e : [] : subscriptionUserRedemption !== null && subscriptionUserRedemption !== void 0 ? subscriptionUserRedemption : [], 
                //   onPageChange={onPageChange}
                //  onRowsPerPageChange={onRowsPerPageChange}
                hasShadow: true, pagination: {
                    currentPage: subscriptionUserRedemptionPagination.page,
                    rowsPerPage: subscriptionUserRedemptionPagination.size,
                    totalRecords: subscriptionUserRedemptionPagination.countTotal,
                }, colors: pages_1.tableColorConfig })),
        react_1.default.createElement("div", { className: "bg-tableBackgroundColor rounded-md grid grid-cols-1" },
            react_1.default.createElement("div", { className: "flex justify-between items-center bg-white pl-2.5 p-2 rounded-t-lg" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Products", textColor: "text-black", bold: true, size: "medium" })),
            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.productTableColumns, data: subscriptionProducts !== null && subscriptionProducts !== void 0 ? subscriptionProducts : [], 
                //   onPageChange={onPageChange}
                //  onRowsPerPageChange={onRowsPerPageChange}
                hasShadow: true, pagination: {
                    currentPage: subscriptionProductsPagination.page,
                    rowsPerPage: subscriptionProductsPagination.size,
                    totalRecords: subscriptionProductsPagination.countTotal,
                }, colors: pages_1.tableColorConfig }))));
};
exports.SubscriptionDetailsPage = SubscriptionDetailsPage;
