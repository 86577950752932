"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockReplacements = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var constants_1 = require("./constants");
var stock_replacement_1 = require("../../../../../reducers/slices/stockReplacement/stock_replacement");
var main_1 = require("../../../../../reducers/slices/main");
var cancel_scheduled_stock_replacement_thunk_1 = require("../../../../../reducers/slices/stockReplacement/thunks/cancel_scheduled_stock_replacement_thunk");
var stock_replacement_quantities_1 = require("./stock_replacement_quantities");
var trigger_stock_replacement_thunk_1 = require("../../../../../reducers/slices/stockReplacement/thunks/trigger_stock_replacement_thunk");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var StockReplacements = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.stockReplacementState; }), selectedScheduledWebhook = _a.selectedScheduledWebhook, isLoading = _a.isLoading, scheduledWebhookTriggered = _a.isTriggered;
    var parcels = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }).parcels;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }), selectedOrder = _b.selectedOrder, selectedOrderFirstTimeView = _b.selectedOrderFirstTimeView;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _c = (0, react_1.useState)(), productsToBeReplaced = _c[0], setProductsToBeReplace = _c[1];
    var _d = (0, react_1.useState)(false), replacementIsEditable = _d[0], setReplacementEditable = _d[1];
    var _e = (0, react_1.useState)(), formattedDate = _e[0], setFormattedDate = _e[1];
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d;
        if (selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.scheduled_for) {
            var date = (_a = new Date(selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.scheduled_for)) !== null && _a !== void 0 ? _a : null;
            setFormattedDate("".concat(date.toLocaleString("default", { month: "short" }), " ").concat(date.getDate(), ", ").concat(date.getFullYear(), " | ").concat(date.toLocaleTimeString()));
        }
        if (selectedOrder._id && (selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.status) === "NONE" && !isLoading) {
            dispatch((0, trigger_stock_replacement_thunk_1.trigger_stock_replacement_thunk)({ topic: "orders/viewed", process: "Stock Replacement", data: selectedOrder }));
        }
        if (selectedOrder._id && (selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.status) !== "NONE" && !isLoading) {
            var isEditable = true;
            if (client.stock_replacement.method === "BRANCH-MANAGED" && !((_c = (_b = client.stock_replacement) === null || _b === void 0 ? void 0 : _b.branch_ids) === null || _c === void 0 ? void 0 : _c.includes((_d = selectedOrder.branch) === null || _d === void 0 ? void 0 : _d._id))) {
                isEditable = false;
            }
            if (["COMPLETED", "CANCELLED"].includes(selectedScheduledWebhook.status)) {
                isEditable = false;
            }
            if (new Date(selectedScheduledWebhook.scheduled_for) <= new Date()) {
                isEditable = false;
            }
            setReplacementEditable(isEditable);
        }
    }, [selectedOrder, selectedScheduledWebhook]);
    (0, react_1.useEffect)(function () {
        if (selectedOrderFirstTimeView && scheduledWebhookTriggered && replacementIsEditable) {
            dispatch((0, stock_replacement_1.setIsTriggered)(false));
            dispatch((0, main_1.setContentModal)({
                title: "Stock Replacement",
                content: (react_1.default.createElement("div", { className: "flex flex-col items-center max-w-md gap-4" },
                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faClock, className: "text-6xl text-gray-500" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Stock Replacement for this order is scheduled for", size: "2xlarge", textColor: "text-black", center: true }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: new Date(selectedScheduledWebhook.scheduled_for).toLocaleString(), size: "2xlarge", textColor: "text-primary", bold: true, center: true }))),
            }));
        }
    }, [scheduledWebhookTriggered, replacementIsEditable]);
    (0, react_1.useEffect)(function () {
        var _a;
        setProductsToBeReplace((_a = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.stock_replacement) === null || _a === void 0 ? void 0 : _a.map(function (stock_replacement) {
            var _a, _b, _c;
            var product = selectedOrder.product_list.find(function (product) { return product._id === stock_replacement.product_id; });
            var parcel = parcels.find(function (parcel) { return parcel.product_list.find(function (p) { return p._id === product._id; }); });
            return __assign(__assign({}, product), { parcel_code: parcel === null || parcel === void 0 ? void 0 : parcel.code, quantity_requested: (_a = stock_replacement === null || stock_replacement === void 0 ? void 0 : stock_replacement.quantity_requested) !== null && _a !== void 0 ? _a : "0", quantity_replaced: (_b = stock_replacement === null || stock_replacement === void 0 ? void 0 : stock_replacement.quantity_replaced) !== null && _b !== void 0 ? _b : "0", replacement_status: (_c = stock_replacement === null || stock_replacement === void 0 ? void 0 : stock_replacement.status) !== null && _c !== void 0 ? _c : "PENDING" });
        }));
    }, [selectedScheduledWebhook]);
    var statusLabel = function (status) {
        var _status;
        var scheduled_date = new Date(selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.scheduled_for);
        switch (status) {
            case "PENDING":
                _status = scheduled_date > new Date() ? "Scheduled for" : "Completed";
                break;
            case "COMPLETED":
                _status = "Completed";
                break;
            case "CANCELLED":
                _status = "Cancelled";
                break;
            case "HELD":
                _status = scheduled_date > new Date() ? "Held until" : "Cancelled";
                break;
        }
        return _status;
    };
    var scheduledForColour = function () {
        if (["COMPLETED", "CANCELLED"].includes(selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.status))
            return "text-black";
        var scheduled_date = new Date(selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.scheduled_for);
        var scheduledReplacementIsNear = scheduled_date ? scheduled_date.getTime() - new Date().getTime() <= 300000 : false;
        if (scheduled_date) {
            if (scheduled_date < new Date()) {
                return "text-error";
            }
            else if (scheduledReplacementIsNear) {
                return "text-orange-500";
            }
            else {
                return "text-primary";
            }
        }
        else {
            return "text-black";
        }
    };
    var onClickChangeQuantities = function () { return dispatch((0, main_1.setContentModal)({ title: "Stock Replacement", content: react_1.default.createElement(stock_replacement_quantities_1.StockReplacementQuantities, null) })); };
    var onCancelStockReplacement = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: (react_1.default.createElement("div", { className: "flex gap-4 flex-col" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to cancel stock replacement for order ".concat(selectedOrder.code, "?"), size: "medium", textColor: "text-inputText" }),
                react_1.default.createElement("div", { className: "flex gap-4 flex-row justify-center" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Yes, Continue", size: "small", onClick: function () {
                            dispatch((0, cancel_scheduled_stock_replacement_thunk_1.cancel_scheduled_stock_replacement_thunk)(selectedScheduledWebhook._id));
                        } })))),
        }));
    };
    return (selectedScheduledWebhook === null || selectedScheduledWebhook === void 0 ? void 0 : selectedScheduledWebhook.status) !== "NONE" ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row items-center gap-4 justify-between" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Stock Replacement", size: "small", textColor: "text-black", bold: true }),
            formattedDate ? (react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                react_1.default.createElement("div", { className: "text-right" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: statusLabel(selectedScheduledWebhook.status), size: "small", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: formattedDate, size: "xsmall", textColor: scheduledForColour() })),
                replacementIsEditable ? (react_1.default.createElement("div", { className: "flex flex-row gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Change Quantities", size: "xsmall", textColor: "text-white", 
                        // loading={isLoadingButton}
                        onClick: onClickChangeQuantities }),
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "xsmall", textColor: "text-black", backgroundColor: "bg-transparent", borderColor: "border-borders", 
                        // loading={isLoadingButton}
                        onClick: onCancelStockReplacement }))) : null)) : null),
        react_1.default.createElement("div", { className: "grid grid-cols-8 w-full gap-4 mt-3" }, constants_1.stockReplacementTableColumns.map(function (_a, index) {
            var label = _a.label, width = _a.width;
            return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: index },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, size: "small", textColor: "text-black", bold: true })));
        })), productsToBeReplaced === null || productsToBeReplaced === void 0 ? void 0 :
        productsToBeReplaced.map(function (product, index) {
            return (react_1.default.createElement("div", { className: "grid grid-cols-8 w-full gap-4 mt-3 items-center", key: index }, constants_1.stockReplacementTableColumns.map(function (_a, i) {
                var _b;
                var displayKey = _a.displayKey, width = _a.width;
                var label = (_b = product[displayKey]) !== null && _b !== void 0 ? _b : "-";
                return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: i },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, size: "xsmall", textColor: "text-black" })));
            })));
        }))) : null;
};
exports.StockReplacements = StockReplacements;
