"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BranchDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var get_branch_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/get_branch_thunk");
var console_1 = require("../../../../../reducers/slices/console");
var branch_trading_times_1 = require("./components/branch_trading_times");
var delivery_settings_1 = require("./components/delivery_settings");
var general_details_1 = require("./components/general_details");
var stock_management_1 = require("./components/stock_management");
var constants_1 = require("../../../../../constants/constants");
var BranchDetailsPage = function () {
    var _a;
    var params = (0, react_router_dom_1.useParams)();
    var branchId = params.branchId;
    var tabRef = (0, react_1.useRef)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _b = (0, react_1.useState)(0), tabIndex = _b[0], setTabIndex = _b[1];
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Branch Details"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (branchId) {
            dispatch((0, get_branch_thunk_1.get_branch_thunk)(branchId));
        }
    }, [branchId]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-white" },
        react_1.default.createElement("div", { ref: tabRef, className: "grid grid-cols-1" },
            react_1.default.createElement("div", { className: "flex pb-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "xsmall", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE));
                    } })),
            react_1.default.createElement(doshx_controls_web_1.TabControl, { selectedTabIndex: tabIndex, colors: {
                    backgroundColor: "bg-transparent",
                    tabs: {
                        indicatorColor: "bg-primary",
                        textColor: "text-black",
                        backgroundColor: "bg-borders",
                        selectedBackgroundColor: "bg-tableBackgroundColor",
                        unselectedBackgroundColor: "bg-borders",
                    },
                }, tabs: [
                    {
                        title: "General Details",
                        content: react_1.default.createElement(general_details_1.GeneralDetailsPage, { tabRef: tabRef, setTabIndex: setTabIndex }),
                        hidden: false,
                    },
                    {
                        title: "Trading Hours & Extra Data",
                        content: react_1.default.createElement(branch_trading_times_1.BranchTradingTimesPage, null),
                        hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                    },
                    {
                        title: "Delivery Settings",
                        content: react_1.default.createElement(delivery_settings_1.DeliverySettingsPage, null),
                        hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                    },
                    ((_a = client === null || client === void 0 ? void 0 : client.stock_manager) === null || _a === void 0 ? void 0 : _a.method) === constants_1.BRANCH && {
                        title: "Stock Management",
                        content: react_1.default.createElement(stock_management_1.StockManagementPage, null),
                        hidden: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch._id) ? false : true,
                    },
                ].filter(Boolean) }))));
};
exports.BranchDetailsPage = BranchDetailsPage;
