"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentMethods = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var upgradeModalComponent_1 = require("../../../../../../components/upgradeModalComponent");
var constants_1 = require("../../../../../../constants/constants");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var main_1 = require("../../../../../../reducers/slices/main");
var client_user_selector_1 = require("../../../../../../selectors/client_user_selector");
var constant_1 = require("../constant");
var pages_1 = require("../../../../../../constants/pages");
var PaymentMethods = function (_a) {
    var _b, _c;
    var selectedPaymentMethods = _a.selectedPaymentMethods, _d = _a.addPaymentCallBack, addPaymentCallBack = _d === void 0 ? function () { } : _d, _e = _a.deletePaymentCallBack, deletePaymentCallBack = _e === void 0 ? function () { } : _e, setPaymentCallBack = _a.setPaymentCallBack, requestCallBack = _a.requestCallBack, isLoading = _a.isLoading;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _f = (0, react_1.useState)("paygate"), paymentProvider = _f[0], setPaymentProvider = _f[1];
    var _g = (0, react_1.useState)("secure"), payGateOption = _g[0], setPayGateOption = _g[1];
    var _h = (0, react_redux_1.useSelector)(client_user_selector_1.clientSubsciptionStatusSelector), isFreemiumPlan = _h.isFreemiumPlan, isUnsubscribed = _h.isUnsubscribed;
    var initialFormValues = constant_1.initialPaymentSettingsValues.payment_method[paymentProvider];
    var selectedProvider = selectedPaymentMethods ? selectedPaymentMethods[paymentProvider] : initialFormValues;
    var _isPayGate = function (provider) { return paymentProvider === "paygate"; };
    var _isPeach = function (provider) { return paymentProvider === "peach"; };
    var _isPayfast = function (provider) { return paymentProvider === "payfast"; };
    var isUpdating = selectedProvider ? (_b = selectedProvider === null || selectedProvider === void 0 ? void 0 : selectedProvider._id) !== null && _b !== void 0 ? _b : (_c = selectedProvider[payGateOption]) === null || _c === void 0 ? void 0 : _c._id : false;
    var formValues = _isPayGate(selectedProvider) ? __assign(__assign({}, constant_1.initialPayGateValues), selectedProvider) : selectedProvider;
    var providerFormValues = formValues !== null && formValues !== void 0 ? formValues : initialFormValues;
    var hasExistingCardPaymentMethod = selectedPaymentMethods &&
        Object.keys(selectedPaymentMethods).some(function (provider) {
            var paymentMethod = selectedPaymentMethods[provider];
            var hasSavedCardPayment = paymentMethod && (paymentMethod._id || (paymentMethod[payGateOption] && paymentMethod[payGateOption]._id));
            return hasSavedCardPayment && constant_1.cardProviders.includes(paymentProvider) && constant_1.cardProviders.includes(provider);
        });
    var _setPaymentMethod = function (value) {
        var _a, _b;
        var formValues = _isPayGate(providerFormValues) ? (_a = {}, _a[payGateOption] = __assign(__assign({}, providerFormValues[payGateOption]), value), _a) : value;
        return setPaymentCallBack(__assign(__assign({}, selectedPaymentMethods), (_b = {}, _b[paymentProvider] = __assign(__assign({}, providerFormValues), formValues), _b)));
    };
    var _showUpgradeModal = function (_) { return dispatch((0, main_1.setContentModal)({ title: constants_1.UPGRADE_MODAL_TITLE, content: react_1.default.createElement(upgradeModalComponent_1.UpgradeModalComponent, null) })); };
    var _onRemovePaymentMethod = function () {
        var _a, _b;
        var _id = (_isPayGate(providerFormValues) ? selectedProvider[payGateOption] : selectedProvider)._id;
        var payload = _isPayGate(providerFormValues) ? (_a = {}, _a[paymentProvider] = { _id: _id, type: payGateOption }, _a) : (_b = {}, _b[paymentProvider] = { _id: _id }, _b);
        deletePaymentCallBack(payload);
        requestCallBack();
        dispatch((0, main_1.setContentModal)(null));
    };
    var _onCreatePaymentMethod = function () {
        var _a, _b;
        if (isUnsubscribed) {
            return _showUpgradeModal(null);
        }
        if (hasExistingCardPaymentMethod)
            return dispatch((0, main_1.setMessageModal)({ title: "Please correct the following errors:", messages: ["Please remove any existing card before saving a new one."] }));
        if (_isPayGate(providerFormValues)) {
            setPaymentCallBack(__assign(__assign({}, selectedPaymentMethods), (_a = {}, _a[paymentProvider] = (_b = {},
                _b[payGateOption] = __assign({}, selectedPaymentMethods[paymentProvider][payGateOption]),
                _b), _a)));
        }
        addPaymentCallBack(paymentProvider);
        requestCallBack();
    };
    var _setIsModalOpen = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: (react_1.default.createElement("div", { className: "flex pt-4 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this?", textColor: "text-inputText" }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: _onRemovePaymentMethod }))),
        }));
    };
    var getFormValues = function (provider) { return (_isPayGate(provider) && provider && provider[payGateOption] ? provider[payGateOption] : provider); };
    var _j = (0, validateForm_1.validateForm)(getFormValues(providerFormValues)), errors = _j.errors, isValid = _j.isValid;
    var _renderPaymentForms = function (provider, isFormEnabled) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            _renderPayGateForm(provider, isFormEnabled),
            _renderPeachForm(provider, isFormEnabled),
            _renderPayfastForm(provider, isFormEnabled)));
    };
    var _renderPayGateForm = function (provider, isFormEnabled) {
        var _a, _b, _c, _d, _e, _f;
        return _isPayGate(provider) && (react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "mb-3" },
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "", cols: "grid-cols-6", options: constant_1.payGateOptions, selectedValue: payGateOption, onChange: function (value) { return setPayGateOption(value); }, colors: pages_1.radioButtonColorConfig })),
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
                react_1.default.createElement("div", { className: "gap-4 grid grid-cols-1" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Paygate ID", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.paygate_id, onChange: function (paygate_id) { return _setPaymentMethod({ paygate_id: paygate_id }); }, value: (_a = provider[payGateOption]) === null || _a === void 0 ? void 0 : _a.paygate_id }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Company", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.company, onChange: function (company) { return _setPaymentMethod({ company: company }); }, value: (_b = provider[payGateOption]) === null || _b === void 0 ? void 0 : _b.company }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "User name", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.user_name, onChange: function (user_name) { return _setPaymentMethod({ user_name: user_name }); }, value: (_c = provider[payGateOption]) === null || _c === void 0 ? void 0 : _c.user_name }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Password", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.password, onChange: function (password) { return _setPaymentMethod({ password: password }); }, value: (_d = provider[payGateOption]) === null || _d === void 0 ? void 0 : _d.password }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Encryption Key", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.encryption_key, onChange: function (encryption_key) { return _setPaymentMethod({ encryption_key: encryption_key }); }, value: (_e = provider[payGateOption]) === null || _e === void 0 ? void 0 : _e.encryption_key }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Type", disabled: true, labelColor: "text-labelText", borderColor: "border-borders", required: true, errorText: errors === null || errors === void 0 ? void 0 : errors.type, onChange: function (type) { return _setPaymentMethod({ type: type }); }, value: (_f = provider[payGateOption]) === null || _f === void 0 ? void 0 : _f.type })))));
    };
    var _renderPeachForm = function (provider, isFormEnabled) {
        return _isPeach(provider) && (react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Entity Id", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["entity_id"], onChange: function (entity_id) { return _setPaymentMethod({ entity_id: entity_id }); }, value: provider.entity_id }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "API Key", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["api_key"], onChange: function (api_key) { return _setPaymentMethod({ api_key: api_key }); }, value: provider.api_key }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Bearer Token", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["bearer_token"], onChange: function (bearer_token) { return _setPaymentMethod({ bearer_token: bearer_token }); }, value: provider.bearer_token }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Client Code", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["client_code"], onChange: function (client_code) { return _setPaymentMethod({ client_code: client_code }); }, value: provider.client_code }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Service", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["service"], onChange: function (service) { return _setPaymentMethod({ service: service }); }, value: provider.service }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Service Type", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors["service_type"], onChange: function (service_type) { return _setPaymentMethod({ service_type: service_type }); }, value: provider.service_type }))));
    };
    var _renderPayfastForm = function (provider, isFormEnabled) {
        return _isPayfast(provider) && (react_1.default.createElement("div", { className: "gap-4 grid grid-cols-2" },
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-1" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Merchant Id", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors.merchant_id, onChange: function (merchant_id) { return _setPaymentMethod({ merchant_id: merchant_id }); }, value: provider.merchant_id }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Merchant Key", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors.merchant_key, onChange: function (merchant_key) { return _setPaymentMethod({ merchant_key: merchant_key }); }, value: provider.merchant_key }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Merchant Passphrase", labelColor: "text-labelText", borderColor: "border-borders", disabled: !isFormEnabled, required: true, errorText: errors.merchant_passphrase, onChange: function (merchant_passphrase) { return _setPaymentMethod({ merchant_passphrase: merchant_passphrase }); }, value: provider.merchant_passphrase }))));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "" },
            react_1.default.createElement(doshx_controls_web_1.BoxInControl, { colors: {
                    innerBox: {
                        borderColor: "border-borders",
                        selectedBorderColor: "border-black",
                    },
                }, value: paymentProvider, onBoxSelect: function (selectedItem) {
                    setPaymentProvider(selectedItem.value);
                }, boxes: constant_1.paymentOptions })),
        paymentProvider && (react_1.default.createElement(react_1.default.Fragment, null, !isUpdating ? (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "" },
            react_1.default.createElement("div", { className: "mb-3" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "mb-5", label: "Complete Payment Provider Form", bold: true, textColor: "text-black" })),
            _renderPaymentForms(providerFormValues, true),
            react_1.default.createElement("div", { className: "w-[200px] mt-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { loading: isLoading, enabled: isValid, label: "Add Payment Provider", size: "small", onClick: _onCreatePaymentMethod })))) : (react_1.default.createElement(doshx_controls_web_1.BoxControl, { variant: "outlined", backgroundColor: "bg-white", borderColor: "border-borders" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "mb-5", label: "Manage linked Provider", bold: true, textColor: "text-black" }),
            react_1.default.createElement("div", { className: "gap-4 grid grid-cols-1" }, _renderPaymentForms(providerFormValues, false)),
            react_1.default.createElement("div", { className: "flex pt-4 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { loading: isLoading, label: "Remove", size: "small", onClick: function () { return _setIsModalOpen(); } }))))))));
};
exports.PaymentMethods = PaymentMethods;
