"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankDetailsComponent = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var branch_1 = require("../../../../../../reducers/slices/branch/branch");
var constants_1 = require("./constants");
var BankDetailsComponent = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _j = (0, react_1.useState)(true), isInteracted = _j[0], setIsInteracted = _j[1];
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var errors = (0, validateForm_1.validateForm)(selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details, {
        initialValues: constants_1.initialBankingDetailsData,
        enabled: isInteracted,
    }).errors;
    var _onControlChange = function (name, value) {
        var _a;
        dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { banking_details: __assign(__assign(__assign({}, constants_1.initialBankingDetailsData), selectedBranch.banking_details), (_a = {}, _a[name] = value, _a)) })));
        // Trigger validation only if user has started interacting with the form
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };
    return (react_1.default.createElement("div", { className: "grid grid-cols-1 gap-4" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Bank account details" //
            , bold: true, textColor: "text-inputText" }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Bank Name" //
            , size: "medium", value: (_b = (_a = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : "", onChange: function (v) { return _onControlChange("name", v); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.name }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Account No" //
            , required: true, size: "medium", value: (_d = (_c = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details) === null || _c === void 0 ? void 0 : _c.account_number) !== null && _d !== void 0 ? _d : "", onChange: function (v) { return _onControlChange("account_number", v); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.account_number }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Account Type" //
            , required: true, size: "medium", value: (_f = (_e = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details) === null || _e === void 0 ? void 0 : _e.account_type) !== null && _f !== void 0 ? _f : "", onChange: function (v) { return _onControlChange("account_type", v); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.account_type }),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Branch Code" //
            , required: true, size: "medium", value: (_h = (_g = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details) === null || _g === void 0 ? void 0 : _g.branch_code) !== null && _h !== void 0 ? _h : "", onChange: function (v) { return _onControlChange("branch_code", v); }, errorText: errors === null || errors === void 0 ? void 0 : errors.branch_code })));
};
exports.BankDetailsComponent = BankDetailsComponent;
