"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeneralDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var link_branch_integration_1 = require("../../../../../../api/calls/branch/update/link_branch_integration");
var constants_1 = require("../../../../../../constants/constants");
var routes_1 = require("../../../../../../constants/routes");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var branch_1 = require("../../../../../../reducers/slices/branch/branch");
var create_branch_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/create_branch_thunk");
var get_branch_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/get/get_branch_thunk");
var add_branch_payment_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/add_branch_payment_thunk");
var branch_update_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/branch_update_thunk");
var delete_branch_payment_thunk_1 = require("../../../../../../reducers/slices/branch/thunks/updates/delete_branch_payment_thunk");
var main_1 = require("../../../../../../reducers/slices/main");
var constants_2 = require("../../../configurations/components/loop/constants");
var payment_methods_1 = require("../../../configurations/paymentSettings/components/payment_methods");
var constants_3 = require("./../constants");
var add_address_component_1 = require("./add_address_component");
var bank_details_component_1 = require("./bank_details_component");
var branch_image_component_1 = require("./branch_image_component");
var unlink_branch_integration_1 = require("../../../../../../api/calls/branch/update/unlink_branch_integration");
var GeneralDetailsPage = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
    var _y = (0, react_1.useState)(false), isInteracted = _y[0], setIsInteracted = _y[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var params = (0, react_router_dom_1.useParams)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var branchId = params.branchId;
    var tabRef = props.tabRef, setTabIndex = props.setTabIndex;
    // Redux state
    var _z = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), selectedBranch = _z.selectedBranch, isLoading = _z.isLoading;
    var _0 = (0, react_1.useState)(false), showPaymentMethod = _0[0], setShowPaymentMethod = _0[1];
    var selectedPaymentSettings = (0, react_redux_1.useSelector)(function (state) { return state.paymentSettingsState; }).selectedPaymentSettings;
    var _1 = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }), client = _1.client, clientIntegrations = _1.clientIntegrations;
    var _2 = (0, validateForm_1.validateForm)(selectedBranch, {
        validationConfig: constants_3.validationConfig,
        enabled: isInteracted,
        initialValues: constants_3.initialBranchData,
    }), errors = _2.errors, branchDetailsIsValid = _2.isValid;
    var bankDetailsIsValid = (0, validateForm_1.validateForm)(selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.banking_details).isValid;
    var isValid = branchDetailsIsValid;
    var isBranchPaymentEnabled = ((_a = selectedPaymentSettings === null || selectedPaymentSettings === void 0 ? void 0 : selectedPaymentSettings.payment_config) === null || _a === void 0 ? void 0 : _a.account_owner) === constants_1.BRANCH;
    var configurations = (_c = (_b = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.integration_settings) === null || _b === void 0 ? void 0 : _b.configurations) !== null && _c !== void 0 ? _c : [];
    var loopIntegration = configurations.find(function (int) { return int.type === constants_2.LOOP; });
    var _3 = (0, react_1.useState)(""), loopBranchId = _3[0], setLoopBranchId = _3[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (loopIntegration)
            setLoopBranchId((_a = loopIntegration === null || loopIntegration === void 0 ? void 0 : loopIntegration.data) === null || _a === void 0 ? void 0 : _a.loop_branch_id);
    }, [loopIntegration]);
    var _integrationExists = function (type) { return clientIntegrations.find(function (int) { return int.type === type; }); };
    var _onControlChange = function (value) {
        dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), value)));
        // Trigger validation only if user has started interacting with the form
        if (!isInteracted) {
            setIsInteracted(true);
        }
    };
    // scrolls page to the top when the user creates a branch
    // this allows them to see the now visible tabs
    var scrollTabsIntoView = function () {
        var tblRef = tabRef.current;
        if (tblRef) {
            // 👇 Will scroll smoothly to the top of the next section
            tblRef.scrollIntoView({ behavior: "smooth" });
            setTabIndex(1); //next tab
        }
    };
    var fetchBranch = function () {
        if (branchId) {
            dispatch((0, get_branch_thunk_1.get_branch_thunk)(branchId));
        }
        else {
            dispatch((0, branch_1.setSelectedBranch)(constants_3.initialBranchData));
        }
    };
    var onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var payload;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!branchId) return [3 /*break*/, 1];
                    dispatch((0, branch_update_thunk_1.branch_update_thunk)());
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, dispatch((0, create_branch_thunk_1.create_branch_thunk)())];
                case 2:
                    payload = (_b.sent()).payload;
                    if (payload.success) {
                        scrollTabsIntoView();
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE).concat(routes_1.BRANCH_DETAILS_ROUTE, "/").concat((_a = payload.content) === null || _a === void 0 ? void 0 : _a._id));
                    }
                    _b.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onAddAddress = function (product) {
        return dispatch((0, main_1.setContentModal)({
            title: "Update Manually",
            content: react_1.default.createElement(add_address_component_1.AddAddressComponent, null),
        }));
    };
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            dispatch((0, main_1.setContentModal)({ title: "Success", content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
            dispatch((0, get_branch_thunk_1.get_branch_thunk)(branchId));
        }
        else
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
    };
    var _onLinkIntegration = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, payload, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = type;
                    switch (_a) {
                        case constants_2.LOOP: return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1:
                    payload = { type: type, branch_id: selectedBranch._id, data: { loop_branch_id: loopBranchId } };
                    showLoader();
                    return [4 /*yield*/, (0, link_branch_integration_1.link_branch_integration)(payload)];
                case 2:
                    response = _b.sent();
                    handleResponse(response);
                    return [3 /*break*/, 4];
                case 3: return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _unLinkIntegration = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, payload, response;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = type;
                    switch (_a) {
                        case constants_2.LOOP: return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1:
                    payload = { type: type, branch_id: selectedBranch._id };
                    showLoader();
                    return [4 /*yield*/, (0, unlink_branch_integration_1.unlink_branch_integration)(payload)];
                case 2:
                    response = _b.sent();
                    handleResponse(response);
                    return [3 /*break*/, 4];
                case 3: return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onUnLinkIntegration = function (type) {
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: (react_1.default.createElement("div", { className: "flex pt-4 gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to unlink ".concat(type, "?"), textColor: "text-inputText" }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            _unLinkIntegration(type);
                            return [2 /*return*/];
                        });
                    }); } }))),
        }));
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: "gap-4", backgroundColor: "bg-transparent", borderColor: "border-white", addPadding: false },
        react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: false }),
        react_1.default.createElement("div", { className: "flex justify-between items-center py-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Branch information" //
                , size: "large", bold: true, textColor: "text-inputText" })),
        react_1.default.createElement("div", { className: "w-full grid grid-cols-1 lg:grid-cols-2 gap-4 py-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Is this branch owned or not owned", cols: "grid-cols-2", options: [
                        { label: "Owned", value: "yes" },
                        { label: "Not Owned", value: "no" },
                    ], selectedValue: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.franchise) ? "no" : "yes", onChange: function (value) { return _onControlChange({ franchise: value === "no" }); }, errorText: errors === null || errors === void 0 ? void 0 : errors.franchise }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Branch Name" //
                    , required: true, size: "medium", value: (_d = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.display_name) !== null && _d !== void 0 ? _d : "", onChange: function (display_name) { return _onControlChange({ display_name: display_name }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.display_name }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Branch Number", required: true, size: "medium", value: (_e = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.branch_number) !== null && _e !== void 0 ? _e : "", onChange: function (branch_number) { return _onControlChange({ branch_number: branch_number }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.branch_number }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Registered Name", required: true, size: "medium", value: (_f = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.registered_name) !== null && _f !== void 0 ? _f : "", onChange: function (registered_name) { return _onControlChange({ registered_name: registered_name }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.registered_name }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Registration Number", required: true, size: "medium", value: (_g = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.registration_number) !== null && _g !== void 0 ? _g : "", onChange: function (registration_number) { return _onControlChange({ registration_number: registration_number }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.registration_number }),
                react_1.default.createElement("div", { className: "flex flex-col mb-3 gap-2" },
                    react_1.default.createElement(doshx_controls_web_1.AddressSearchControl //
                    , { letsTradeAPIUrl: process.env.LETS_TRADE_CLIENT_URL, borderColor: "border-borders", value: {
                            fullAddress: (_j = (_h = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _h === void 0 ? void 0 : _h.full_address) !== null && _j !== void 0 ? _j : "",
                            coordinates: {
                                lat: (_l = (_k = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _k === void 0 ? void 0 : _k.latitude) !== null && _l !== void 0 ? _l : "",
                                lng: (_o = (_m = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address) === null || _m === void 0 ? void 0 : _m.longitude) !== null && _o !== void 0 ? _o : "",
                            },
                        }, required: true, label: "Address", size: "medium", onSelectionChange: function (value) {
                            dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { address: __assign(__assign({}, selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address), { full_address: value.fullAddress, latitude: value.coordinates.lat.toString(), longitude: value.coordinates.lng.toString(), town: value.suburb, city: value.city, province: value.province, country: value.country, postal_code: value.postal_code }) })));
                        }, errorText: errors === null || errors === void 0 ? void 0 : errors.address }),
                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { className: "justify-end", size: "small", textColor: "text-blue-600", label: "Add Manually", onClick: onAddAddress })),
                react_1.default.createElement(doshx_controls_web_1.PhoneInputControl, { key: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.country_code_extension, borderColor: "border-borders", label: "Phone number", required: true, defaultCountryCode: selectedBranch.country_code_extension, placeholderColor: "placeholder-disabled", value: (_p = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.contact_number) !== null && _p !== void 0 ? _p : "", onChange: function (contact_number) { return _onControlChange({ contact_number: contact_number }); }, onExtensionChange: function (country_code, country_code_extension) {
                        _onControlChange({ country_code: country_code, country_code_extension: country_code_extension });
                    }, errorText: errors === null || errors === void 0 ? void 0 : errors.contact_number }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "Email address" //
                    , required: true, size: "medium", value: (_q = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.email) !== null && _q !== void 0 ? _q : "", onChange: function (email) { return _onControlChange({ email: email }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.email })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-start" },
                (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.display_name) && (react_1.default.createElement(branch_image_component_1.BranchImageComponent //
                , { imageError: errors === null || errors === void 0 ? void 0 : errors.image_url })),
                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "Is this branch VAT Registered?", cols: "grid-cols-2", options: [
                        { label: "YES", value: "yes" },
                        { label: "NO", value: "no" },
                    ], selectedValue: (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.vat_registered) ? "yes" : "no", onChange: function (value) {
                        var vat_registered = value === "yes";
                        _onControlChange({ vat_registered: vat_registered });
                    }, errorText: errors === null || errors === void 0 ? void 0 : errors.vat_registered, required: true }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", label: "VAT number" //
                    , required: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.vat_registered, size: "medium", type: "number", value: (_r = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.vat_number) !== null && _r !== void 0 ? _r : "", onChange: function (vat_number) { return _onControlChange({ vat_number: vat_number }); }, iconColor: "text-error", errorText: errors === null || errors === void 0 ? void 0 : errors.vat_number }),
                (selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.franchise) && react_1.default.createElement(bank_details_component_1.BankDetailsComponent, null)),
            ((_s = client.stock_replacement) === null || _s === void 0 ? void 0 : _s.enabled) && client.stock_replacement.branch_ids.includes(selectedBranch._id) && (react_1.default.createElement("div", { className: "mt-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Stock Replacment", bold: true, textColor: "text-black" }),
                react_1.default.createElement(doshx_controls_web_1.SwitchControl, { label: "REPLACE STOCK BY DEFAULT", checked: (_t = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.stock_replacement) === null || _t === void 0 ? void 0 : _t.replace_by_default, onChange: function (replace_by_default) { return _onControlChange({ stock_replacement: { replace_by_default: replace_by_default } }); }, colors: __assign({ labelColor: "text-black" }, (((_u = selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.stock_replacement) === null || _u === void 0 ? void 0 : _u.replace_by_default)
                        ? {
                            thumbColor: "bg-primary",
                            trackColor: "bg-blue-100",
                        }
                        : {
                            thumbColor: "bg-gray",
                            trackColor: "bg-borders",
                        })) })))),
        selectedBranch._id && (react_1.default.createElement("div", { className: "gap-2 my-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Integrations", bold: true, textColor: "text-black" }),
            _integrationExists(constants_2.LOOP) && (react_1.default.createElement("div", { className: "mt-4" },
                react_1.default.createElement("div", { className: "grid grid-cols-2 gap-2 mt-4" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Enter Loop Branch Id", type: "text", size: "medium", borderColor: "border-borders", value: loopBranchId, onChange: function (v) {
                                setLoopBranchId(v);
                            } })),
                    react_1.default.createElement("div", { className: "w-[150px]" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: ((_v = loopIntegration === null || loopIntegration === void 0 ? void 0 : loopIntegration.data) === null || _v === void 0 ? void 0 : _v.enabled) ? "Update Loop" : "Connect Loop", enabled: Boolean(loopBranchId), size: "medium", backgroundColor: ((_w = loopIntegration === null || loopIntegration === void 0 ? void 0 : loopIntegration.data) === null || _w === void 0 ? void 0 : _w.enabled) ? "bg-green-600" : "bg-primary", textColor: "text-white", onClick: function () { return _onLinkIntegration(constants_2.LOOP); } })),
                    ((_x = loopIntegration === null || loopIntegration === void 0 ? void 0 : loopIntegration.data) === null || _x === void 0 ? void 0 : _x.enabled) && (react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "Unlink", size: "small", textColor: "text-blue-700", onClick: function () { return _onUnLinkIntegration(constants_2.LOOP); } }))))))),
        isBranchPaymentEnabled && selectedBranch._id && (react_1.default.createElement("div", { className: "grid grid-cols-1" },
            react_1.default.createElement("div", { className: "bg-borders flex items-center justify-between p-4 rounded-md" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Set Payment Method", size: "large" //
                    , textColor: "text-tableTextColor", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: showPaymentMethod ? pro_light_svg_icons_1.faChevronUp : pro_light_svg_icons_1.faChevronDown, borderColor: "border-transparent", iconColor: "text-primary", onClick: function () {
                        setShowPaymentMethod(!showPaymentMethod);
                    } })),
            react_1.default.createElement("div", { className: showPaymentMethod ? "w-full" : "hidden" },
                react_1.default.createElement(payment_methods_1.PaymentMethods, { selectedPaymentMethods: selectedBranch.payment_method, addPaymentCallBack: function (paymentProvider) { return dispatch((0, add_branch_payment_thunk_1.add_branch_payment_thunk)(paymentProvider)); }, deletePaymentCallBack: function (paymentProvider) { return dispatch((0, delete_branch_payment_thunk_1.delete_branch_payment_thunk)(paymentProvider)); }, setPaymentCallBack: function (payment_method) { return dispatch((0, branch_1.setSelectedBranch)(__assign(__assign({}, selectedBranch), { payment_method: payment_method }))); }, requestCallBack: function () { return (0, get_branch_thunk_1.get_branch_thunk)(branchId); }, isLoading: isLoading })))),
        react_1.default.createElement("div", { className: "flex justify-between items-center py-4 gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black" //
                , startIcon: pro_light_svg_icons_1.faCancel, label: "Cancel", size: "medium", backgroundColor: "bg-borders", textColor: "text-black", onClick: fetchBranch }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white" //
                , startIcon: pro_light_svg_icons_1.faSave, label: "Save", size: "medium", enabled: isValid, backgroundColor: "bg-primary", textColor: "text-white", loading: isLoading, onClick: onSubmit }))));
};
exports.GeneralDetailsPage = GeneralDetailsPage;
