"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontBuild = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var get_build_status_1 = require("../../../../../api/calls/storeFront/gcp/get_build_status");
var constants_1 = require("../../../../../constants/constants");
var main_1 = require("../../../../../reducers/slices/main");
var StoreFrontBuild = function (_a) {
    var _b;
    var environment = _a.environment, name = _a.name;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, react_1.useState)(null), isLoading = _c[0], setIsLoading = _c[1];
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState; }), store_domain = _d.store_domain, selectedEnvironment = _d.selectedEnvironment, store_front = __rest(_d, ["store_domain", "selectedEnvironment"]);
    var _e = (0, react_1.useState)(null), buildConfig = _e[0], setBuildConfig = _e[1];
    var _f = (0, react_1.useState)(true), shouldFetch = _f[0], setShouldFetch = _f[1];
    var _g = buildConfig !== null && buildConfig !== void 0 ? buildConfig : {}, status = _g.status, steps = _g.steps;
    var domainPayload = {
        app_name: client.name,
        stage: name === "production" ? "prod" : "dev",
    };
    var url = (_b = buildConfig === null || buildConfig === void 0 ? void 0 : buildConfig.url) !== null && _b !== void 0 ? _b : "Url not ready";
    var onGetBuildStatus = (0, react_1.useCallback)(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, buildConfig;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, (0, get_build_status_1.get_build_status)(domainPayload)];
                case 1:
                    response = _a.sent();
                    buildConfig = handleResponse(response);
                    setBuildConfig(buildConfig);
                    return [2 /*return*/];
            }
        });
    }); }, [selectedEnvironment]);
    var onGetBuildStatusRef = (0, react_1.useRef)(onGetBuildStatus);
    var handleResponse = function (response) {
        setIsLoading(false);
        if (response.success) {
            return response.content;
        }
        else {
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
        }
    };
    var getStepName = function (index) {
        var buildStepArgName = steps[index].args[0];
        switch (buildStepArgName) {
            case "push":
                return "Pushing - ".concat(steps[index].name);
            case "build":
                return "Building - ".concat(steps[index].name);
            case "run":
                return "Deploying - ".concat(steps[index].name);
        }
    };
    (0, react_1.useEffect)(function () {
        var interval = null;
        if (shouldFetch && !isLoading) {
            interval = setInterval(function () {
                onGetBuildStatusRef.current();
            }, 10000);
        }
        return function () {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [shouldFetch]);
    (0, react_1.useEffect)(function () {
        onGetBuildStatusRef.current = onGetBuildStatus;
        onGetBuildStatusRef.current();
    }, [selectedEnvironment]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "w-md" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Deployment Status", size: "large", textColor: "text-black" }),
        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
        react_1.default.createElement("div", null, buildConfig && status ? (react_1.default.createElement("div", { className: "flex flex-row justify-start overflow-hidden" },
            status === "WORKING" && react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xsmall" }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "px-1", label: "".concat(status, ": "), size: "medium", bold: true, textColor: status === "SUCCESS" ? "text-green" : "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: !!url ? url : "URL not ready", onClick: function () {
                    window.open(url, "_blank");
                } }))) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No deployments", size: "small", textColor: "text-black" })))));
};
exports.StoreFrontBuild = StoreFrontBuild;
