"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var workflow_1 = require("../../../../../../reducers/slices/workflow/workflow");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var constants_1 = require("../constants");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_2 = require("doshx_controls_web");
var CustomNode = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var id = props.id, type = props.type, category = props.category, data = props.data, draggable = props.draggable, children = props.children, configurationComponent = props.configurationComponent, colors = props.colors, infoPopOver = props.infoPopOver;
    var className = props.className, prop_icon = props.icon;
    var _a = (0, react_1.useState)(prop_icon), icon = _a[0], setIcon = _a[1];
    var _b = (0, react_1.useState)(false), configurable = _b[0], setConfigurable = _b[1];
    var isGraphNode = Boolean(id);
    (0, react_1.useEffect)(function () {
        var _a, _b, _c, _d, _e, _f;
        if (isGraphNode) {
            var nodeConfig = (_a = constants_1.nodeConfigurations[category]) === null || _a === void 0 ? void 0 : _a.find(function (nodeConfig) { return nodeConfig.type === type && nodeConfig.data.name === data.name; });
            if (nodeConfig) {
                setConfigurable((_b = nodeConfig.data) === null || _b === void 0 ? void 0 : _b.configurable);
                if (!icon && ((_c = nodeConfig.data) === null || _c === void 0 ? void 0 : _c.icon)) {
                    setIcon((_d = nodeConfig.data) === null || _d === void 0 ? void 0 : _d.icon);
                }
                if (!data.schema && ((_e = nodeConfig.data) === null || _e === void 0 ? void 0 : _e.schema)) {
                    dispatch((0, workflow_1.setNode)({ id: id, data: __assign(__assign({}, data), { schema: (_f = nodeConfig.data) === null || _f === void 0 ? void 0 : _f.schema }), edit: false }));
                }
            }
        }
    }, [id, type, category, data]);
    var _c = (0, react_1.useState)(false), showConfiguration = _c[0], setShowConfiguration = _c[1];
    var _d = (0, react_1.useState)(false), hasErrors = _d[0], setHasErrors = _d[1];
    (0, react_1.useEffect)(function () {
        if (!isGraphNode)
            return;
        if (data.errors && Object.keys(data.errors).length > 0) {
            setHasErrors(true);
            setShowConfiguration(true);
        }
        else if (hasErrors) {
            setHasErrors(false);
        }
    }, [data.errors]);
    var onDragStart = null;
    if (draggable) {
        className += " cursor-move";
        onDragStart = function (event) {
            event.dataTransfer.setData("application/reactflow", type);
            event.dataTransfer.effectAllowed = "move";
            dispatch((0, workflow_1.setNewNode)({ type: type, data: data }));
        };
    }
    var borderColor = "border-borders";
    if (props.selected) {
        borderColor = "border-tertiary";
    }
    else if (hasErrors) {
        borderColor = "border-error";
    }
    var InfoPopOver = function () { return (react_1.default.createElement(doshx_controls_web_1.PopoverControl, { content: react_1.default.createElement("div", { className: "flex flex-col max-w-sm rounded-sm" },
            react_1.default.createElement("div", { className: "bold bg-blue-100 text-lg p-2 shadow" }, infoPopOver === null || infoPopOver === void 0 ? void 0 : infoPopOver.summary),
            react_1.default.createElement("div", { className: "p-2" }, infoPopOver === null || infoPopOver === void 0 ? void 0 : infoPopOver.content)), behavior: "hover", position: "bottomCenter", addPadding: false },
        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faInfoCircle, className: "text-labelText hover:text-tertiary", size: "xs" }))); };
    var RenderInfo = function () {
        var _a;
        return (react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: icon !== null && icon !== void 0 ? icon : pro_light_svg_icons_1.faCircle, size: "xl", className: "".concat((_a = colors === null || colors === void 0 ? void 0 : colors.iconColor) !== null && _a !== void 0 ? _a : "text-tertiary", " w-5") }),
            react_1.default.createElement("div", { className: "flex-1 flex-col" },
                react_1.default.createElement("div", { className: "flex flex-row gap-2" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: data === null || data === void 0 ? void 0 : data.name, textColor: "text-labelText", maxLines: "one" }),
                    infoPopOver && showConfiguration && react_1.default.createElement(InfoPopOver, null)),
                react_1.default.createElement("div", { className: "flex flex-row items-center justify-between gap-2" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_2.Utilities.titleCase(category), maxLines: "one", size: "xsmall", textColor: "text-labelText" }))),
            isGraphNode && configurable && configurationComponent && renderConfigurationButton()));
    };
    var renderConfigurationButton = function () { return (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: showConfiguration ? pro_light_svg_icons_1.faChevronUp : pro_light_svg_icons_1.faChevronDown, borderColor: "border-transparent", backgroundColor: showConfiguration ? "bg-blue-100 hover:bg-blue-200" : "bg-transparent hover:bg-blue-100", iconColor: (colors === null || colors === void 0 ? void 0 : colors.iconColor) || "text-tertiary", size: "2xsmall", shape: "circle", onClick: function () { return setShowConfiguration(!showConfiguration); } })); };
    return (react_1.default.createElement("div", { className: "flex flex-col min-w-[200px] max-w-md px-4 py-2 shadow hover:shadow-md rounded-md bg-white border-2 ".concat(borderColor, " ").concat(className), draggable: draggable, onDragStart: onDragStart },
        react_1.default.createElement(RenderInfo, null),
        showConfiguration && (react_1.default.createElement("div", { className: "flex flex-col" },
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { lineColor: "bg-borders", addPadding: true }),
            configurationComponent(data))),
        isGraphNode && children));
};
exports.default = (0, react_1.memo)(CustomNode);
