"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParcelsTablesComponent = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var constants_1 = require("../../orders/components/parcels/constants");
var ParcelsTablesComponent = function (props) {
    var parcels = props.parcels;
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        console.log("parcels ===>", parcels);
    }, [parcels]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
            react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Parcels", size: "small", textColor: "text-black", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You\u2019ve added the following items into a parcel", size: "xsmall", textColor: "text-black" })), parcels === null || parcels === void 0 ? void 0 :
            parcels.map(function (parcel, index) {
                var _a;
                return (react_1.default.createElement("div", { key: index }, parcel.product_list.length > 0 && (react_1.default.createElement("div", null,
                    react_1.default.createElement(react_1.default.Fragment, null,
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Parcel ".concat(parcel === null || parcel === void 0 ? void 0 : parcel.code), size: "small", textColor: "text-blue-700", bold: true }),
                        react_1.default.createElement("div", { className: "grid grid-cols-8 mt-3 gap-4 w-full" },
                            constants_1.parcelProductTableColumns.map(function (_a, index) {
                                var label = _a.label, width = _a.width;
                                return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: index },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, size: "small", textColor: "text-black", bold: true })));
                            }),
                            react_1.default.createElement("div", { className: "col-span-2 flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Actions", size: "small", textColor: "text-black", bold: true }))), (_a = parcel === null || parcel === void 0 ? void 0 : parcel.product_list) === null || _a === void 0 ? void 0 :
                        _a.map(function (product, index) {
                            return (react_1.default.createElement("div", { className: "grid grid-cols-8 mt-3 gap-4 w-full items-center" }, constants_1.parcelProductTableColumns.map(function (_a, i) {
                                var displayKey = _a.displayKey, width = _a.width;
                                return (react_1.default.createElement("div", { className: "".concat(width, " break-words items-center"), key: i },
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" })));
                            })));
                        }))))));
            }))));
};
exports.ParcelsTablesComponent = ParcelsTablesComponent;
