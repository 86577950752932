"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var constants_1 = require("../deals/constants");
var pages_1 = require("../../../../../constants/pages");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var branch_1 = require("../../../../../reducers/slices/branch/branch");
var AddBranchesComponent = function (_a) {
    var onSave = _a.onSave, onSelectAllChange = _a.onSelectAllChange;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(900), searchDealBranches = _b[0], setSearchDealBranches = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }), dealBranches = _c.dealBranches, dealBranchPagination = _c.dealBranchPagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _d = (0, react_1.useState)([]), selectedRows = _d[0], setSelectedRows = _d[1];
    var _e = (0, react_1.useState)(false), selectedAll = _e[0], setSelectedAll = _e[1];
    var handleSave = function () {
        onSave(selectedRows);
        dispatch((0, main_1.setContentModal)(null));
    };
    (0, react_1.useEffect)(function () {
        getBranches(dealBranchPagination);
    }, []);
    (0, react_1.useEffect)(function () {
        onSelectAllChange(selectedAll);
    }, [selectedAll, onSelectAllChange]);
    (0, react_1.useEffect)(function () {
        onSearchBranches(searchDealBranches);
    }, [searchDealBranches]);
    var getBranches = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, branch_list_thunk_1.deal_branch_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchBranches = function (search_string) {
        getBranches(__assign(__assign({}, dealBranchPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        if ((user === null || user === void 0 ? void 0 : user.role) !== "ADMIN") {
            if (searchDealBranches === "") {
                return getBranches(__assign(__assign({}, dealBranchPagination), { branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of, page: page }));
            }
            else {
                return getBranches(__assign(__assign({}, dealBranchPagination), { page: page, branch_id_list: user === null || user === void 0 ? void 0 : user.manager_of, search_string: searchDealBranches }));
            }
        }
        else {
            if (searchDealBranches === "") {
                return getBranches(__assign(__assign({}, dealBranchPagination), { page: page }));
            }
            else {
                return getBranches(__assign(__assign({}, dealBranchPagination), { page: page, search_string: searchDealBranches }));
            }
        }
    };
    var onRowsPerPageChange = function (size) {
        return getBranches(__assign(__assign({}, dealBranchPagination), { size: size }));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
        react_1.default.createElement("div", { className: "flex justify-between items-center gap-2" },
            (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && (react_1.default.createElement("div", { className: "pl-4" },
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Select All", size: "xsmall", value: selectedAll, onChange: function (v) {
                        if (v) {
                            setSelectedAll(true);
                        }
                        else {
                            setSelectedAll(false);
                        }
                    } }))),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search Deal Branches", endIcon: pro_solid_svg_icons_1.faSearch, borderColor: "border-borders", size: "small", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, dispatch((0, branch_1.setDealBranchPagination)(dealBranchPagination))];
                            case 1:
                                _a.sent();
                                setSearchDealBranches(e);
                                return [2 /*return*/];
                        }
                    });
                }); } })),
        react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.dealBranchColumns, rowSelections: selectedRows, onSelectionChange: function (selectedRows) {
                setSelectedAll(false);
                setSelectedRows(selectedRows);
            }, data: dealBranches, hasShadow: true, pagination: {
                currentPage: dealBranchPagination.page,
                rowsPerPage: dealBranchPagination.size,
                totalRecords: dealBranchPagination.countTotal,
                onPageChange: onPageChange,
                onRowsPerPageChange: onRowsPerPageChange,
            }, colors: pages_1.tableColorConfig }),
        react_1.default.createElement("div", { className: "flex justify-center items-center gap-2" },
            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faCircleInfo, tooltip: {
                    label: "Please note your branch selection will be overwritten per saved selection.",
                    placement: "top",
                    colors: {
                        backgroundColor: "bg-dark",
                        textColor: "text-white",
                    },
                }, onClick: function () { }, size: "medium" }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", onClick: handleSave, size: "small", shape: "square" }))));
};
exports.default = AddBranchesComponent;
