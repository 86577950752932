"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var local_storage_names_1 = require("../../../../constants/local_storage_names");
var routes_1 = require("../../../../constants/routes");
var console_1 = require("../../../../reducers/slices/console");
var get_user_thunk_1 = require("../../../../reducers/slices/customers/thunks/get_user_thunk");
var orders_1 = require("../../../../reducers/slices/orders/orders");
var get_order_thunk_1 = require("../../../../reducers/slices/orders/thunks/get_order_thunk");
var parcel_list_thunk_1 = require("../../../../reducers/slices/parcels/thunks/parcel_list_thunk");
var get_scheduled_stock_replacement_thunk_1 = require("../../../../reducers/slices/stockReplacement/thunks/get_scheduled_stock_replacement_thunk ");
var client_user_selector_1 = require("../../../../selectors/client_user_selector");
var order_timeline_1 = require("./components/order_timeline");
var parcels_1 = require("./components/parcels/parcels");
var products_list_1 = require("./components/products_list");
var returned_products_1 = require("./components/returned_products");
var stock_replacements_1 = require("./components/stock_replacements");
var OrderPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21;
    var _id = (0, react_router_dom_1.useParams)()._id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _22 = (0, react_redux_1.useSelector)(function (state) { return state.orderState; }), isLoadingOrders = _22.isLoading, selectedOrder = _22.selectedOrder;
    var pagination = (0, react_redux_1.useSelector)(function (state) { return state.parcelState; }).pagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _23 = (0, react_redux_1.useSelector)(function (state) { return state.customerState; }), isLoadingCustomer = _23.isLoadingCustomer, customer = _23.customer;
    var isManager = (0, react_redux_1.useSelector)(client_user_selector_1.isManagerSelector).isManager;
    var isLoading = isLoadingOrders || isLoadingCustomer;
    var transaction_details = selectedOrder.transaction_details;
    var _24 = transaction_details || {}, _25 = _24.wallet_amount, wallet_amount = _25 === void 0 ? "0" : _25, _26 = _24.loan_amount, loan_amount = _26 === void 0 ? "0" : _26, transaction = _24.transaction;
    var _27 = (transaction || {}).amount, amount = _27 === void 0 ? "0" : _27;
    var paidByCustomer = parseFloat(wallet_amount) + parseFloat(amount);
    var _28 = (0, react_1.useState)(false), stockReplacementEnabled = _28[0], setStockReplacementEnabled = _28[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Order Data: ".concat(selectedOrder.code)));
        dispatch((0, orders_1.setNewOrderCount)(undefined)); //clear order count
    }, [selectedOrder]);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            if (!(selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder._id)) {
                _getOrder({ _id: _id });
            }
        }
    }, [selectedOrder, user]);
    (0, react_1.useEffect)(function () {
        if (selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder._id) {
            _getParcels(__assign({ order_id: _id }, pagination));
            _getCustomer({ _id: selectedOrder.user_id });
            _storeViewedOrderIDs(selectedOrder._id);
            if (client.stock_replacement.enabled) {
                setStockReplacementEnabled(true);
                _getScheduledStockReplacement({ "data._id": selectedOrder._id });
            }
        }
    }, [selectedOrder]);
    var _getCustomer = function (query) { return dispatch((0, get_user_thunk_1.get_user_thunk)(query)); };
    var _getOrder = function (query) { return dispatch((0, get_order_thunk_1.get_order_thunk)(query)); };
    var _getParcels = function (query) { return dispatch((0, parcel_list_thunk_1.parcel_list_thunk)(query)); };
    var _getScheduledStockReplacement = function (query) { return dispatch((0, get_scheduled_stock_replacement_thunk_1.get_scheduled_stock_replacement_thunk)(query)); };
    var _storeViewedOrderIDs = function (order_id) {
        var viewedOrderIDsStorage = localStorage.getItem(local_storage_names_1.VIEWED_ORDER_IDS_STORAGE);
        if (viewedOrderIDsStorage) {
            var viewedOrderIDs = viewedOrderIDsStorage.split(",");
            if (viewedOrderIDs.includes(order_id)) {
                (0, orders_1.setSelectedOrderFirstTimeView)(false);
            }
            else {
                // TODO: Add audit logs
                if (viewedOrderIDs.length >= 249) {
                    viewedOrderIDs = viewedOrderIDs.slice(-249);
                }
                viewedOrderIDs.push(order_id);
                localStorage.setItem(local_storage_names_1.VIEWED_ORDER_IDS_STORAGE, viewedOrderIDs.join(","));
                dispatch((0, orders_1.setSelectedOrderFirstTimeView)(true));
            }
        }
        else {
            localStorage.setItem(local_storage_names_1.VIEWED_ORDER_IDS_STORAGE, order_id);
            dispatch((0, orders_1.setSelectedOrderFirstTimeView)(true));
        }
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { borderColor: "border-transparent", addPadding: false, className: "w-full" }, isLoadingOrders ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement("div", { className: " flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 w-full" },
            react_1.default.createElement("div", { className: "flex flex-row" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Back to orders", size: "xsmall", startIcon: pro_solid_svg_icons_1.faChevronLeft, backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                        navigate(-1);
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-4 gap-4 items-start justify-start" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center lg:col-span-3" },
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 w-full" },
                        react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order", size: "small", textColor: "text-black", bold: true }),
                            react_1.default.createElement(doshx_controls_web_1.BadgeControl, { size: "xsmall", value: selectedOrder.status, colors: {
                                    backgroundColor: "bg-secondary",
                                    valueColor: "text-white",
                                } })),
                        react_1.default.createElement("div", { className: "flex flex-col lg:flex-row justify-end gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Code: ".concat(selectedOrder.code), size: "xsmall", backgroundColor: "bg-gray-100", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Order Total: ".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = (_a = selectedOrder.total) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                    symbolSeparation: true,
                                })), size: "xsmall", backgroundColor: "bg-gray-100", textColor: "text-black" })))),
                react_1.default.createElement(products_list_1.ProductListComponent, null),
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Sub Total", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: ((_c = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.sub_total) !== null && _c !== void 0 ? _c : 0).toString(),
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Tax (Vat 15% Included)", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: ((_d = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.vat) !== null && _d !== void 0 ? _d : 0).toString(),
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery", size: "small", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_f = (_e = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.delivery_fee) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-black" }))),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Total", size: "small", textColor: "text-blue-700", bold: true })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_h = (_g = selectedOrder.total) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-blue-700", bold: true }))),
                    react_1.default.createElement(doshx_controls_web_1.DividerControl, { addPadding: false, orientation: "horizontal" }),
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Paid by customer", size: "small", textColor: "text-blue-700", bold: true })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_j = paidByCustomer.toString()) !== null && _j !== void 0 ? _j : "0",
                                    symbolSeparation: true,
                                })), size: "small", textColor: "text-blue-700", bold: true })))),
                react_1.default.createElement(parcels_1.ParcelsComponent, null),
                react_1.default.createElement(returned_products_1.ReturnedProducts, null),
                stockReplacementEnabled ? react_1.default.createElement(stock_replacements_1.StockReplacements, null) : null,
                react_1.default.createElement(order_timeline_1.OrderTimelineComponent, null)),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.BadgeControl, { size: "xsmall", value: selectedOrder.method, colors: {
                            backgroundColor: "bg-secondary",
                            valueColor: "text-white",
                        } })),
                isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" })) : (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faUserCrown })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customer", size: "small", textColor: "text-black", bold: true }))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View Customer", onClick: function () {
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.CUSTOMERS_ROUTE).concat(routes_1.CUSTOMER_PROFILE_ROUTE, "/").concat(selectedOrder.client_id));
                                }, size: "small" }))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faUser })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(customer === null || customer === void 0 ? void 0 : customer.name, " ").concat(customer === null || customer === void 0 ? void 0 : customer.surname), size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faPhone })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(customer === null || customer === void 0 ? void 0 : customer.cell_number), size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faEnvelope })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(customer === null || customer === void 0 ? void 0 : customer.email), size: "xsmall", textColor: "text-black" }))))),
                react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCommentDollar })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Payment Details", size: "small", textColor: "text-black", bold: true })))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faWallet })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Wallet: ".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_m = (_l = (_k = selectedOrder.transaction_details) === null || _k === void 0 ? void 0 : _k.wallet_amount) === null || _l === void 0 ? void 0 : _l.toString()) !== null && _m !== void 0 ? _m : "0",
                                    symbolSeparation: true,
                                })), size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faGiftCard })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Vouchers: None", size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faRectangleHistory })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Subscriptions: None", size: "xsmall", textColor: "text-black" })),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faMoneyBill })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Amount: ".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_q = (_p = (_o = selectedOrder.transaction_details) === null || _o === void 0 ? void 0 : _o.transaction) === null || _p === void 0 ? void 0 : _p.amount.toString()) !== null && _q !== void 0 ? _q : "0",
                                    symbolSeparation: true,
                                })), size: "xsmall", textColor: "text-black" })),
                        !isManager && (react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCreditCard })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Card: ".concat((_t = (_s = (_r = selectedOrder.transaction_details) === null || _r === void 0 ? void 0 : _r.card) === null || _s === void 0 ? void 0 : _s.card_number) !== null && _t !== void 0 ? _t : "", " ").concat((_w = (_v = (_u = selectedOrder.transaction_details) === null || _u === void 0 ? void 0 : _u.card) === null || _v === void 0 ? void 0 : _v.card_type) !== null && _w !== void 0 ? _w : ""), size: "xsmall", textColor: "text-black" }))),
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCreditCard })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Loan: ".concat(doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_x = loan_amount === null || loan_amount === void 0 ? void 0 : loan_amount.toString()) !== null && _x !== void 0 ? _x : "0",
                                    symbolSeparation: true,
                                })), size: "xsmall", textColor: "text-black" })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faCalendar })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order Creation Date", size: "small", textColor: "text-black", bold: true })))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCalendarAlt })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(selectedOrder.order_date), size: "xsmall", textColor: "text-black" })))),
                react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faStoreAlt })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Store Information", size: "small", textColor: "text-black", bold: true }))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { textColor: "text-blue-600", label: "View Store", onClick: function () {
                                    var _a;
                                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BRANCHES_ROUTE).concat(routes_1.BRANCH_DETAILS_ROUTE, "/").concat((_a = selectedOrder.branch) === null || _a === void 0 ? void 0 : _a._id));
                                }, size: "small" }))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faStore })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_z = (_y = selectedOrder.branch) === null || _y === void 0 ? void 0 : _y.display_name) !== null && _z !== void 0 ? _z : (_0 = selectedOrder.store) === null || _0 === void 0 ? void 0 : _0.store_name), size: "xsmall", textColor: "text-black" })))),
                selectedOrder.channel && selectedOrder.channel !== "LETS-TRADE" && (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBagShopping })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Sales Channel", size: "small", textColor: "text-black", bold: true })))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTag })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat((_1 = selectedOrder.channel) === null || _1 === void 0 ? void 0 : _1.toLowerCase()), size: "xsmall", textColor: "text-black", className: "capitalize" })),
                        selectedOrder.order_id && (react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHashtag })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Order Number: ".concat(selectedOrder.order_id), size: "xsmall", textColor: "text-black" })))))),
                react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                    react_1.default.createElement("div", { className: "flex items-center" },
                        react_1.default.createElement("div", { className: "flex-1" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faTruck })),
                                selectedOrder.method == "COLLECTION" ? (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Collection Information", size: "small", textColor: "text-black", bold: true })) : (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery Information", size: "small", textColor: "text-black", bold: true }))))),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faClock })),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Scheduled Time: ".concat((_2 = selectedOrder.delivery_address) === null || _2 === void 0 ? void 0 : _2.scheduled_time), size: "xsmall", textColor: "text-black" })),
                        selectedOrder.method === "COLLECTION" ? (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faLocationDot })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "To be Collected at:  ".concat((_3 = selectedOrder.branch) === null || _3 === void 0 ? void 0 : _3.display_name), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHouse })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Residential:  ".concat(((_4 = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.delivery_address) === null || _4 === void 0 ? void 0 : _4.is_residential) ? "Yes" : "No"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBusinessTime })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Business Name:  ".concat((_6 = (_5 = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.delivery_address) === null || _5 === void 0 ? void 0 : _5.business_name) !== null && _6 !== void 0 ? _6 : "N/A"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBuilding })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Complex/Building Name:  ".concat((_8 = (_7 = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.delivery_address) === null || _7 === void 0 ? void 0 : _7.complex_building_details) !== null && _8 !== void 0 ? _8 : "N/A"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faNotes })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Instructions:  ".concat((_10 = (_9 = selectedOrder.delivery_address) === null || _9 === void 0 ? void 0 : _9.instructions) !== null && _10 !== void 0 ? _10 : "N/A"), size: "xsmall", textColor: "text-black" })))) : (react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faLocationDot })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "To be Collected at:  ".concat((_11 = selectedOrder.branch) === null || _11 === void 0 ? void 0 : _11.display_name), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faLocationDot })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "To be Delivered to:  ".concat((_12 = selectedOrder.delivery_address) === null || _12 === void 0 ? void 0 : _12.full_address), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faHouse })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Residential:  ".concat(((_13 = selectedOrder.delivery_address) === null || _13 === void 0 ? void 0 : _13.isResidential) ? "Yes" : "No"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBusinessTime })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Business Name:  ".concat((_15 = (_14 = selectedOrder.delivery_address) === null || _14 === void 0 ? void 0 : _14.business_name) !== null && _15 !== void 0 ? _15 : "N/A"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faBuilding })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Complex/Building Name:  ".concat((_17 = (_16 = selectedOrder.delivery_address) === null || _16 === void 0 ? void 0 : _16.complex_building_details) !== null && _17 !== void 0 ? _17 : "N/A"), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement("span", { className: "text-black text-sm w-8 h-7 flex justify-left items-center" },
                                    react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faNotes })),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Instructions:  ".concat((_19 = (_18 = selectedOrder.delivery_address) === null || _18 === void 0 ? void 0 : _18.instructions) !== null && _19 !== void 0 ? _19 : "N/A"), size: "xsmall", textColor: "text-black" })))),
                        ((_20 = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.status_history) === null || _20 === void 0 ? void 0 : _20.length) && react_1.default.createElement("div", { className: "flex flex-col mt-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Status Change History", size: "small", bold: true, textColor: "text-black" }), (_21 = selectedOrder === null || selectedOrder === void 0 ? void 0 : selectedOrder.status_history) === null || _21 === void 0 ? void 0 :
                            _21.map(function (record, index) { return (react_1.default.createElement("div", { key: index, className: "flex flex-col mt-2" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(record.status), size: "xsmall", bold: true, textColor: "text-black" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(doshx_controls_web_1.Utilities.formatDateTime(record.date)), size: "xsmall", textColor: "text-black" }))); }))))))))));
};
exports.OrderPage = OrderPage;
