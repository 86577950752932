"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchTermPage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var overview_1 = require("./components/overview");
var searches_1 = require("./components/searches");
var search_without_results_1 = require("./components/search_without_results");
var searches_without_clicks_1 = require("./components/searches_without_clicks");
var term_storage_1 = require("./components/term_storage");
var SearchTermPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _a = (0, react_1.useState)(0), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Search Term"));
    }, []);
    return (react_1.default.createElement("div", { className: "rounded-md p-4" },
        react_1.default.createElement(doshx_controls_web_1.TabControl, { selectedTabIndex: selectedTabIndex, tabs: [
                { title: "Overview", content: react_1.default.createElement(overview_1.OverviewComponent, { selectedTabIndex: selectedTabIndex }) },
                {
                    title: "Searches",
                    content: react_1.default.createElement(searches_1.SearchesComponent, null),
                },
                { title: "Searches without results", content: react_1.default.createElement(search_without_results_1.SearchesWithoutResultsComponent, { selectedTabIndex: selectedTabIndex }) },
                { title: "Searches without click", content: react_1.default.createElement(searches_without_clicks_1.SearchesWithoutClicksComponent, { selectedTabIndex: selectedTabIndex }) },
                { title: "Term Storage", content: react_1.default.createElement(term_storage_1.TermStorageComponent, null) },
            ], colors: {
                // borderColor: "border-white",
                tabs: {
                    textColor: "text-gray-800",
                    indicatorColor: "bg-primary",
                    backgroundColor: "bg-white",
                    unselectedBackgroundColor: "bg-white",
                    selectedBackgroundColor: "bg-white",
                },
            } })));
};
exports.SearchTermPage = SearchTermPage;
