const colors = {
  //TODO: add more colors as needed
  primary: "#070720", //"#0300E6", // that darkish blue color
  secondary: "#0250fa",
  tertiary: "#3976fc",
  disabled: "#c2d5fe", //"#B0B0B0",
  success: "#039c2c", // that green color
  error: "#AA0000",
  borders: "#EDF0F4",
  inputText: "#3F4254", // text color of most input text
  labelText: "#626679", // color of most labels
  backgroundColor: "#EDF0F4", // applies to all if not most screens
  underlineColor: "#8A8A8A",
  tableTextColor: "#000000",
  tableBackgroundColor: "#FFFFFF",
  selectedColor: "#EDF0F4",
  green: "#17A221", // that green color
  yellow: "#FFE66D",
  black: "#000000",
  shopify: "#96bf48",
  facebook: "#3B5998",
  google: "#DB4437",
};

module.exports = colors;
