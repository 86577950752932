"use strict";
// NB: Start all routes must follow:
// - forward slash at the beginning
// - lowercase words
// - using dashes(hyphens) for multiple words
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDERS_ROUTE = exports.FAILED_BASKET_ROUTE = exports.SUCCESS_BASKET_ROUTE = exports.BASKET_PAYFAST_GATEWAY_ROUTE = exports.CREATE_BASKET = exports.VIEW_BASKET_ROUTE = exports.BASKETS_ROUTE = exports.WAYBILL_DETAILS_ROUTE = exports.WAYBILL_ROUTE = exports.DELIVERY_ROUTE = exports.DRIVER_ROUTE = exports.PAYMENT_SETTINGS_ROUTE = exports.INTEGRATIONS_ROUTE = exports.VOUCHERS_ROUTE = exports.PACKAGES_ROUTE = exports.SELECT_SUBSCRIPTION_ROUTE = exports.SUBSCRIPTIONS_DETAILS_ROUTE = exports.SUBSCRIPTION_ROUTE = exports.SUBSCRIPTIONS_ROUTE = exports.DEALS_ROUTE = exports.PRODUCT_TYPES_ROUTE = exports.PRODUCT_CATEGORIES_ROUTE = exports.EXCLUDED_PRODUCT_LIST_ROUTE = exports.PRODUCT_LIST_ROUTE = exports.LOYALTIES_ROUTE = exports.LOYALTY_ROUTE = exports.DOCUMENT_DETAILS_ROUTE = exports.ANALYTICS_ROUTE = exports.DOCUMENTS_ROUTE = exports.REDEMPTIONS_ROUTE = exports.QUOTE_DETAILS_ROUTE = exports.QUOTES_ROUTE = exports.PARCELS_ROUTE = exports.BRANCH_GROUPS_ROUTE = exports.BRANCH_DETAILS_ROUTE = exports.BRANCHES_ROUTE = exports.CUSTOMER_PROFILE_ROUTE = exports.CUSTOMERS_ROUTE = exports.DASHBOARD_ROUTE = exports.CONSOLE_ROUTE = exports.TRACK_PARCEL_ROUTE = exports.TRACK_ORDER_ROUTE = exports.PRIVACY_POLICY_ROUTE = exports.TERMS_AND_CONDITIONS_ROUTE = exports.RESET_PASSWORD_ROUTE = exports.FORGOT_PASSWORD_ROUTE = exports.CREATE_CLIENT_ROUTE = exports.SIGN_UP_ROUTE = exports.LOG_IN_ROUTE = exports.WELCOME_ROUTE = void 0;
exports.USER_GIFTS_DETAILS_ROUTE = exports.USER_GIFTS_ROUTE = exports.GIFT_CATEGORY_DETAILS_ROUTE = exports.GIFT_CATEGORY_ROUTE = exports.GIFT_ROUTE = exports.PROCUREMENT_PRODUCTS_ROUTE = exports.PROCUREMENT_HISTORY_ROUTE = exports.SUPPLIERS_DETAILS_ROUTE = exports.PROCUREMENT_ROUTE = exports.SUPPLIERS_ROUTE = exports.MENU_CONCEPT_DETAILS_ROUTE = exports.MENU_CONCEPT_LIST_ROUTE = exports.CONDIMENTS_ROUTE = exports.BILLING_ROUTE = exports.SEARCH_TERMS_ROUTE = exports.THEMES_ROUTE = exports.STORE_FRONT_ROUTE = exports.WORKFLOW_DETAILS_ROUTE = exports.WORKFLOWS_ROUTE = exports.VOUCHER_DETAILS_ROUTE = exports.DEAL_DETAILS_ROUTE = exports.CHANNELS_ROUTE = exports.EXCLUDED_PRODUCT_DETAILS_ROUTE = exports.PRODUCT_DETAILS_ROUTE = exports.SETTINGS_ROUTE = exports.PROFILE_ROUTE = exports.USER_ROUTE = exports.USERS_ROUTE = exports.ORDER_ROUTE = void 0;
// ENTRY ROUTES >
exports.WELCOME_ROUTE = "/welcome";
exports.LOG_IN_ROUTE = "/log-in";
exports.SIGN_UP_ROUTE = "/sign-up";
exports.CREATE_CLIENT_ROUTE = "/create-client";
exports.FORGOT_PASSWORD_ROUTE = "/forgot-password";
exports.RESET_PASSWORD_ROUTE = "/reset-password";
exports.TERMS_AND_CONDITIONS_ROUTE = "/terms-and-conditions";
exports.PRIVACY_POLICY_ROUTE = "/privacy-policy";
exports.TRACK_ORDER_ROUTE = "/track-order";
exports.TRACK_PARCEL_ROUTE = "/track-parcel";
// CONSOLE ROUTES >
exports.CONSOLE_ROUTE = "/console";
exports.DASHBOARD_ROUTE = "/dashboard";
exports.CUSTOMERS_ROUTE = "/customers";
exports.CUSTOMER_PROFILE_ROUTE = "/customer-profile";
exports.BRANCHES_ROUTE = "/branches";
exports.BRANCH_DETAILS_ROUTE = "/branch-details";
exports.BRANCH_GROUPS_ROUTE = "/branch-groups";
exports.PARCELS_ROUTE = "/parcels";
exports.QUOTES_ROUTE = "/quotes";
exports.QUOTE_DETAILS_ROUTE = "/quote-details";
exports.REDEMPTIONS_ROUTE = "/redemptions";
exports.DOCUMENTS_ROUTE = "/documents";
exports.ANALYTICS_ROUTE = "/analytics";
exports.DOCUMENT_DETAILS_ROUTE = "/document-details";
exports.LOYALTY_ROUTE = "/loyalty";
exports.LOYALTIES_ROUTE = "/loyalties";
exports.PRODUCT_LIST_ROUTE = "/product-list";
exports.EXCLUDED_PRODUCT_LIST_ROUTE = "/excluded-product-list";
exports.PRODUCT_CATEGORIES_ROUTE = "/product-categories";
exports.PRODUCT_TYPES_ROUTE = "/product-types";
exports.DEALS_ROUTE = "/deals";
exports.SUBSCRIPTIONS_ROUTE = "/subscriptions";
exports.SUBSCRIPTION_ROUTE = "/subscription";
exports.SUBSCRIPTIONS_DETAILS_ROUTE = "/subscription-details";
exports.SELECT_SUBSCRIPTION_ROUTE = "/select-subscription";
exports.PACKAGES_ROUTE = "/packages";
exports.VOUCHERS_ROUTE = "/vouchers";
exports.INTEGRATIONS_ROUTE = "/integrations";
exports.PAYMENT_SETTINGS_ROUTE = "/payment-settings";
exports.DRIVER_ROUTE = "/driver";
exports.DELIVERY_ROUTE = "/delivery";
exports.WAYBILL_ROUTE = "/waybill";
exports.WAYBILL_DETAILS_ROUTE = "/waybill-details";
exports.BASKETS_ROUTE = "/baskets";
exports.VIEW_BASKET_ROUTE = "/view-baskets";
exports.CREATE_BASKET = "/create-baskets";
exports.BASKET_PAYFAST_GATEWAY_ROUTE = "/payfast-gateway";
exports.SUCCESS_BASKET_ROUTE = "/success-basket";
exports.FAILED_BASKET_ROUTE = "/failed-basket";
exports.ORDERS_ROUTE = "/orders";
exports.ORDER_ROUTE = "/order";
exports.USERS_ROUTE = "/users";
exports.USER_ROUTE = "/user";
exports.PROFILE_ROUTE = "/profile";
exports.SETTINGS_ROUTE = "/settings";
exports.PRODUCT_DETAILS_ROUTE = "/product-details";
exports.EXCLUDED_PRODUCT_DETAILS_ROUTE = "/excluded-product-details";
exports.CHANNELS_ROUTE = "/channels";
exports.DEAL_DETAILS_ROUTE = "/deal-details";
exports.VOUCHER_DETAILS_ROUTE = "/voucher-details";
exports.WORKFLOWS_ROUTE = "/workflows";
exports.WORKFLOW_DETAILS_ROUTE = "/workflow-details";
exports.STORE_FRONT_ROUTE = "/store-front";
exports.THEMES_ROUTE = "/themes";
exports.SEARCH_TERMS_ROUTE = "/search-terms";
exports.BILLING_ROUTE = "/billings";
exports.CONDIMENTS_ROUTE = "/condiments";
exports.MENU_CONCEPT_LIST_ROUTE = "/menu-concepts";
exports.MENU_CONCEPT_DETAILS_ROUTE = "/menu-concept-details";
exports.SUPPLIERS_ROUTE = "/suppliers";
exports.PROCUREMENT_ROUTE = "/procurement";
exports.SUPPLIERS_DETAILS_ROUTE = "/supplier-details";
exports.PROCUREMENT_HISTORY_ROUTE = "/procurement-history";
exports.PROCUREMENT_PRODUCTS_ROUTE = "/procurement-products";
exports.GIFT_ROUTE = "/gifts";
exports.GIFT_CATEGORY_ROUTE = "/gift-categories";
exports.GIFT_CATEGORY_DETAILS_ROUTE = "/gift-categories-details";
exports.USER_GIFTS_ROUTE = "/user-gifts";
exports.USER_GIFTS_DETAILS_ROUTE = "/user-gifts-details";
