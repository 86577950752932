"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockConfigure = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var main_1 = require("../../../../../reducers/slices/main");
var procure_products_1 = require("../../../../../api/calls/product/procurement/procure_products");
var routes_1 = require("../../../../../constants/routes");
var get_user_1 = require("../../../../../api/calls/user/get/get_user");
var user_sign_up_1 = require("../../../../../api/calls/user/user_sign_up");
var get_procurement_products_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_procurement_products_thunk");
var StockConfigure = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)(null), selectedSupplier = _b[0], setSelectedSupplier = _b[1];
    var _c = (0, react_1.useState)(null), branches = _c[0], setBranches = _c[1];
    var _d = (0, react_1.useState)(null), customer = _d[0], setCustomer = _d[1];
    var _e = (0, react_1.useState)({
        page: 1,
        size: 10,
        countTotal: 0,
    }), stockPagination = _e[0], setStockPagination = _e[1];
    var _f = (0, react_1.useState)(0), selectedSupplierIndex = _f[0], setSelectedSupplierIndex = _f[1];
    var _g = (0, react_1.useState)(0), selectedBranchIndex = _g[0], setSelectedBranchIndex = _g[1];
    var _h = (0, react_1.useState)([]), clientData = _h[0], setClientData = _h[1];
    var _j = (0, react_1.useState)([]), basket = _j[0], setBasket = _j[1];
    var _k = (0, react_1.useState)(false), isLoading = _k[0], setIsLoading = _k[1];
    var selectedBranch = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).selectedBranch;
    var clientSuppliers = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).clientSuppliers;
    var _l = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), procurementProducts = _l.procurementProducts, currentProcurement = _l.currentProcurement, procurementProductsPagination = _l.procurementProductsPagination, loading = _l.loading;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var navigate = (0, react_router_dom_1.useNavigate)();
    (0, react_1.useEffect)(function () {
        if (user._id) {
            getCustomer();
        }
    }, []);
    (0, react_1.useEffect)(function () {
        if (currentProcurement === null || currentProcurement === void 0 ? void 0 : currentProcurement._id)
            getProcurementProducts({});
    }, [clientSuppliers]);
    var mapSupplier = function () {
        return procurementProducts === null || procurementProducts === void 0 ? void 0 : procurementProducts.map(function (x) {
            var _a;
            var _supplier = clientSuppliers.find(function (s) { var _a; return ((_a = s.supplier) === null || _a === void 0 ? void 0 : _a._id) === x.supplier_id; });
            if (_supplier) {
                return __assign(__assign({}, x), { suppler_name: (_a = _supplier.supplier) === null || _a === void 0 ? void 0 : _a.name });
            }
            return __assign(__assign({}, x), { suppler_name: "" });
        });
    };
    var productProcurement = function (status) {
        setIsLoading(true);
        (0, procure_products_1.procure_products)({
            user: {
                user_id: customer._id,
                address: selectedBranch === null || selectedBranch === void 0 ? void 0 : selectedBranch.address,
            },
            procurement_id: currentProcurement._id,
            status: status,
        }).then(function (res) {
            setIsLoading(false);
            if (res.success) {
                dispatch((0, main_1.setMessageModal)({
                    title: "Success!",
                    messages: ["Successfully procured your products"],
                    buttons: [
                        {
                            label: "Ok",
                            onClick: function () {
                                dispatch((0, main_1.setMessageModal)(null));
                                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PROCUREMENT_ROUTE).concat(routes_1.PROCUREMENT_PRODUCTS_ROUTE, "/").concat(currentProcurement._id));
                            },
                        },
                    ],
                }));
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [res.message] }));
            }
        });
    };
    var getProcurementProducts = function (filter) {
        var _procurementProductsPagination = __assign({}, procurementProductsPagination);
        delete _procurementProductsPagination.countTotal;
        dispatch((0, get_procurement_products_thunk_1.get_procurement_products_thunk)({
            payload: __assign(__assign(__assign({}, _procurementProductsPagination), filter), { procurement_id: currentProcurement === null || currentProcurement === void 0 ? void 0 : currentProcurement._id }),
            callback: function () { },
        }));
    };
    var getCustomer = function () {
        (0, get_user_1.get_user)({ email: user.email }).then(function (res) {
            if (res.success) {
                setCustomer(res.content);
            }
            else {
                (0, user_sign_up_1.user_sign_up)({
                    name: user === null || user === void 0 ? void 0 : user.name,
                    surname: user === null || user === void 0 ? void 0 : user.surname,
                    email: user === null || user === void 0 ? void 0 : user.email,
                    cell_number: user === null || user === void 0 ? void 0 : user.cell_number,
                    password: "123456",
                    gender: "N/A",
                    get_communication: user.get_communication,
                    status: "ACTIVE",
                    agrees_terms_and_conditions: user.agrees_terms_and_conditions,
                }).then(function (response) {
                    if (response.success) {
                        setCustomer(res.content);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when adding a customer!", messages: [response.message] }));
                    }
                });
            }
        });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 mt-4" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement(react_1.default.Fragment, null, loading ? (react_1.default.createElement("div", { className: "grid justify-center p-4" },
                react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" }))) : (react_1.default.createElement("div", { className: "" },
                react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                        {
                            displayKey: "suppler_name",
                            label: "Suppler",
                        },
                        {
                            displayKey: "title",
                            label: "Title",
                        },
                        {
                            displayKey: "sku",
                            label: "SKU",
                        },
                        {
                            displayKey: "branch.display_name",
                            label: "Branch",
                        },
                        {
                            displayKey: "price",
                            label: "Price",
                        },
                        {
                            displayKey: "stock_status",
                            label: "Status",
                        },
                        {
                            displayKey: "ordered_quantity",
                            label: "Quantity",
                        },
                        {
                            displayKey: "quantity",
                            label: "Required Stock",
                        },
                    ], data: (_a = mapSupplier()) !== null && _a !== void 0 ? _a : [], hasShadow: false, pagination: {
                        currentPage: stockPagination === null || stockPagination === void 0 ? void 0 : stockPagination.page,
                        rowsPerPage: stockPagination === null || stockPagination === void 0 ? void 0 : stockPagination.size,
                        totalRecords: stockPagination === null || stockPagination === void 0 ? void 0 : stockPagination.countTotal,
                        onPageChange: function (page) {
                            getProcurementProducts({ page: page });
                        },
                        onRowsPerPageChange: function (size) {
                            getProcurementProducts({ size: size });
                        },
                    }, colors: pages_1.tableColorConfig }))))),
        react_1.default.createElement("div", { className: "flex justify-center items-center gap-2" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Partially And In Stock", size: "small", shape: "square", backgroundColor: "bg-success", onClick: function () {
                    productProcurement("PARTIALLY IN STOCK");
                } }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "In Stock", size: "small", shape: "square", loading: isLoading, backgroundColor: "bg-success", onClick: function () {
                    productProcurement("IN STOCK");
                } }))));
};
exports.StockConfigure = StockConfigure;
