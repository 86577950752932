"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var create_trip_thunk_1 = require("../../../../../reducers/slices/trips/thunks/create_trip_thunk");
var CreateTripComponent = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    var setSelectedParcels = props.setSelectedParcels, setSelectedAvailableDriver = props.setSelectedAvailableDriver, selectedParcels = props.selectedParcels, selectedAvailableDriver = props.selectedAvailableDriver;
    (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "shadow-md flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-between" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Selected Parcels", bold: true, textColor: "text-black" }),
                selectedParcels.map(function (x) { return (react_1.default.createElement("div", { className: "flex justify-between p-4 rounded-md border" },
                    react_1.default.createElement("div", { className: "" }, x.code),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("span", { className: "text-primary ml-8" },
                            react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { onClick: function () {
                                    var _selectedParcels = selectedParcels.filter(function (parcel) { return parcel._id !== x._id; });
                                    setSelectedParcels(_selectedParcels);
                                }, icon: pro_light_svg_icons_1.faTrash }))))); })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Selected Driver", bold: true, textColor: "text-black" }),
                selectedAvailableDriver.name && (react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Driver:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.name, " ").concat(selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.surname) })),
                    react_1.default.createElement("div", { className: "flex gap-4" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Mobile:", textColor: "text-black" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: selectedAvailableDriver === null || selectedAvailableDriver === void 0 ? void 0 : selectedAvailableDriver.cell_number })))))),
        selectedParcels.length > 0 && selectedAvailableDriver.name && (react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create trip", 
                //  endIcon={faPlus}
                size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                    dispatch((0, main_1.setMessageModal)({
                        title: "Confirm!",
                        messages: ["Please confirm to conclude assigning the trip to the driver"],
                        buttons: [
                            {
                                label: "Cancel",
                                backgroundColor: "bg-white",
                                textColor: "text-primary",
                                borderColor: "border-primary",
                                onClick: function () {
                                    dispatch((0, main_1.setMessageModal)(null));
                                },
                            },
                            {
                                label: "Confirm",
                                loading: false,
                                onClick: function () {
                                    dispatch((0, create_trip_thunk_1.create_trip_thunk)({
                                        driver_id: selectedAvailableDriver._id,
                                        parcel_id_list: selectedParcels.map(function (x) { return x._id; }),
                                    }));
                                    setSelectedParcels([]);
                                    setSelectedAvailableDriver({});
                                },
                            },
                        ],
                    }));
                } })))));
};
exports.default = CreateTripComponent;
