"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaybillTimeline = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var update_waybill_comment_thunk_1 = require("../../../../../reducers/slices/waybill/thunks/update_waybill_comment_thunk");
var WaybillTimeline = function (props) {
    var waybillID = props.waybillID, comments = props.comments;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(""), comment = _a[0], setComment = _a[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var addCommentLoading = (0, react_redux_1.useSelector)(function (state) { return state.waybillState; }).addCommentLoading;
    var formatOrderNotes = function (notes) {
        return notes === null || notes === void 0 ? void 0 : notes.map(function (note) {
            return __assign(__assign({}, note), { date: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "Do MMMM YYYY"), time: doshx_controls_web_1.Utilities.formatDateTime(note === null || note === void 0 ? void 0 : note.date, "HH:mm") });
        });
    };
    var formattedNotes = comments ? formatOrderNotes(comments) : [];
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement("div", { className: "flex items-center" },
                    react_1.default.createElement("div", { className: "flex items-center justify-center p-4 w-16 h-16 rounded-full border " },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faUser, onClick: function () { }, backgroundColor: "bg-gray-200", iconColor: "text-black", shape: "circle", size: "large" })),
                    react_1.default.createElement("div", { className: "w-full pl-4" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Notes/Comments", value: comment, onChange: function (text) { return setComment(text); }, required: true, backgroundColor: "bg-gray-200", borderColor: "border-transparent", lines: 1, size: "medium" }))),
                react_1.default.createElement("div", { className: "flex justify-end items-center" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Post", textColor: "text-white", loading: addCommentLoading, backgroundColor: "bg-primary", borderColor: "border-transparent", size: "small", onClick: function () {
                            if (comment) {
                                dispatch((0, update_waybill_comment_thunk_1.update_waybill_comment_thunk)({
                                    comment: comment,
                                    waybill_id: waybillID,
                                    user: {
                                        _id: user._id,
                                        display_name: "".concat(user.name, " ").concat(user.surname)
                                    }
                                }));
                                setComment("");
                            }
                        } })),
                react_1.default.createElement("div", { className: "mt-5" }, formattedNotes === null || formattedNotes === void 0 ? void 0 : formattedNotes.map(function (note, index) {
                    var _a;
                    return (react_1.default.createElement("div", { key: index, className: "flex justify-between" },
                        react_1.default.createElement("div", { className: "flex flex-row" },
                            react_1.default.createElement("div", { className: "" },
                                react_1.default.createElement("div", { className: "flex flex-col items-center" },
                                    react_1.default.createElement("div", { className: "w-6 h-6 border-1 border-white rounded-full flex items-center justify-center" },
                                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faCheckCircle, className: "text-blue-500" })),
                                    index < formattedNotes.length - 1 && react_1.default.createElement("div", { className: "w-1 h-20 bg-blue-500" }))),
                            react_1.default.createElement("div", { className: "flex flex-col pl-4 space-y-1" },
                                react_1.default.createElement("div", { className: "flex justify-between items-start" },
                                    react_1.default.createElement("h3", { className: "text-sm" }, note === null || note === void 0 ? void 0 : note.date)),
                                (note === null || note === void 0 ? void 0 : note.user) && react_1.default.createElement("p", { className: "font-bold" },
                                    "Note by: ", (_a = note === null || note === void 0 ? void 0 : note.user) === null || _a === void 0 ? void 0 :
                                    _a.display_name),
                                react_1.default.createElement("p", { className: (note === null || note === void 0 ? void 0 : note.user) ? "text-md" : "font-bold" }, note === null || note === void 0 ? void 0 : note.comment))),
                        react_1.default.createElement("span", { className: "text-sm text-gray-500" }, note === null || note === void 0 ? void 0 : note.time)));
                }))))));
};
exports.WaybillTimeline = WaybillTimeline;
