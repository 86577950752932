"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaybillPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_all_waybills_thunk_1 = require("../../../../reducers/slices/waybill/thunks/get_all_waybills_thunk");
var constants_1 = require("./components/constants");
var routes_1 = require("../../../../constants/routes");
var pages_1 = require("../../../../constants/pages");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var console_1 = require("../../../../reducers/slices/console");
var WaybillPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_1.useState)(false), showFilters = _b[0], setShowFilters = _b[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.waybillState; }), waybills = _c.waybills, pagination = _c.pagination, loading = _c.loading;
    var filters = {
        client_id: user.client_id,
        page: pagination.page,
        size: pagination.size,
    };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Waybill"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (waybills.length === 0) {
            dispatch((0, get_all_waybills_thunk_1.get_all_waybills_thunk)(__assign({}, filters)));
        }
    }, []);
    var highlightRow = function (row) {
        switch (row === null || row === void 0 ? void 0 : row.waybill_mode) {
            case "PASSED_COLLECTION_TIME":
                return "bg-red-400";
            case "WITHIN_30_MIN":
                return "bg-orange-400";
            default:
                return "bg-white";
        }
    };
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getDrivers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_all_waybills_thunk_1.get_all_waybills_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getDrivers(__assign(__assign(__assign({}, filters), pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    return (react_1.default.createElement("div", { className: "w-full" }, loading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-end" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", backgroundColor: "bg-white", label: "Search", endIcon: pro_light_svg_icons_1.faSearch, value: search, size: "small", onChange: function (v) { return setSearch(v); } })),
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.WAYBILL_TABLE_COLUMNS, rowActions: [
                    {
                        label: "View",
                        onClick: function (data) {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.WAYBILL_DETAILS_ROUTE, "/").concat(data._id));
                        },
                    },
                ], highlightRow: highlightRow, data: waybills, hasShadow: true, colors: pages_1.tableColorConfig, pagination: {
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: function (page) {
                        dispatch((0, get_all_waybills_thunk_1.get_all_waybills_thunk)(__assign(__assign({}, filters), { page: page })));
                    },
                    onRowsPerPageChange: function (size) {
                        dispatch((0, get_all_waybills_thunk_1.get_all_waybills_thunk)(__assign(__assign({}, filters), { size: size })));
                    },
                } }))))));
};
exports.WaybillPage = WaybillPage;
