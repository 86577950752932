"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../reducers/slices/main");
var remove_product_variant_1 = require("../../../../../../api/calls/product/variances/remove_product_variant");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var STATUSES = [
    { label: "Available", value: "AVAILABLE" },
    { label: "Out of stock", value: "OUT-OF-STOCK" },
    { label: "Draft", value: "DRAFT" },
];
var RemoveVariant = function (props) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedItem = props.selectedItem, onTaskComplete = props.onTaskComplete;
    var _k = (0, react_1.useState)(selectedItem), selectedItemData = _k[0], setSelectedItemData = _k[1];
    (0, react_1.useEffect)(function () {
        setSelectedItemData(selectedItem);
    }, [selectedItem]);
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 items-center" },
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { size: "large", label: "Are you sure you want to remove this variant ?" }),
        react_1.default.createElement("div", { className: "grid grid-cols-2 gap-4 bg-white" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Variant Type", type: "text", size: "small", borderColor: "border-borders", value: (_a = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.type) !== null && _a !== void 0 ? _a : "", errorText: "", disabled: true, onChange: function (v) { } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Title", type: "text", size: "small", borderColor: "border-borders", value: (_b = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.title) !== null && _b !== void 0 ? _b : "", errorText: "", disabled: true, onChange: function (v) { } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "SKU", type: "text", size: "small", borderColor: "border-borders", value: (_c = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.sku) !== null && _c !== void 0 ? _c : "", errorText: "", disabled: true, onChange: function (v) { } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "PID", type: "text", size: "small", borderColor: "border-borders", value: (_d = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.pid) !== null && _d !== void 0 ? _d : "", errorText: "", disabled: true, onChange: function (v) { } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Price", type: "text", size: "small", borderColor: "border-borders", value: (_e = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.price) !== null && _e !== void 0 ? _e : "", errorText: "", disabled: true, onChange: function (v) { } }),
            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Status", borderColor: "border-borders", required: true, options: STATUSES, iconColor: "text-primary", size: "small", value: (_f = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.status) !== null && _f !== void 0 ? _f : "", disabled: true, onChange: function (v) { }, errorText: "" }),
            react_1.default.createElement("div", null,
                react_1.default.createElement("div", { className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " },
                    react_1.default.createElement("img", { src: selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.image_url, alt: "Image", className: "w-10 h-10 transition-all duration-300 rounded-lg cursor-pointer" }),
                    react_1.default.createElement("div", { className: "absolute right-2 bg-gray-600 rounded-full text-white" },
                        react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () { } }))),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Is Default", value: (_g = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.is_default) !== null && _g !== void 0 ? _g : false, onChange: function (v) { }, textColor: "text-blue-500", size: "small" }))),
            react_1.default.createElement("div", null,
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Promotion Price", type: "number", size: "small", borderColor: "border-borders", value: (_h = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.promotion_price) !== null && _h !== void 0 ? _h : "", errorText: "", disabled: true, onChange: function (v) { } }),
                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "On Promotion", value: (_j = selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.on_promotion) !== null && _j !== void 0 ? _j : false, onChange: function (v) { }, textColor: "text-blue-500", size: "small" }))),
        react_1.default.createElement("div", { className: "" }, (selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref) ? (react_1.default.createElement("div", { className: "flex flex-row justify-end " },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Remove Variant", size: "small", enabled: true, startIcon: pro_solid_svg_icons_1.faTrash, backgroundColor: "bg-red-400", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var oldRoot, updateItem, modified, request, response;
                    var _a;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                oldRoot = __assign(__assign({}, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root), { variations: (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root.variations) ? selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.root.variations : [] });
                                updateItem = function (node, ref, modifiedData) {
                                    var _a;
                                    if ((node === null || node === void 0 ? void 0 : node.ref) === ref) {
                                        return __assign({}, modifiedData);
                                    }
                                    if ((_a = node === null || node === void 0 ? void 0 : node.variations) === null || _a === void 0 ? void 0 : _a.length) {
                                        var updatedVariations = node.variations.map(function (item) { return updateItem(item, ref, modifiedData); });
                                        return __assign(__assign({}, node), { variations: updatedVariations });
                                    }
                                    return node;
                                };
                                modified = updateItem(oldRoot, selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.ref, selectedItemData);
                                (_a = modified === null || modified === void 0 ? void 0 : modified.variations) === null || _a === void 0 ? void 0 : _a.forEach(function (item) {
                                    if (item === null || item === void 0 ? void 0 : item.root) {
                                        delete item.root;
                                    }
                                });
                                request = {
                                    variance_id: modified === null || modified === void 0 ? void 0 : modified._id,
                                    node_ref: selectedItemData === null || selectedItemData === void 0 ? void 0 : selectedItemData.ref,
                                };
                                return [4 /*yield*/, (0, remove_product_variant_1.remove_product_variant)(request)];
                            case 1:
                                response = _b.sent();
                                if (response.success) {
                                    onTaskComplete();
                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                    setTimeout(function () {
                                        dispatch((0, main_1.setMessageModal)(null));
                                        dispatch((0, main_1.setContentModal)(null));
                                    }, 1000);
                                }
                                else {
                                    onTaskComplete();
                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [response.message] }));
                                }
                                return [2 /*return*/];
                        }
                    });
                }); } }))) : null)));
};
exports.default = RemoveVariant;
