"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionsTablePage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var routes_1 = require("../../../../../constants/routes");
var console_1 = require("../../../../../reducers/slices/console");
var subscriptions_1 = require("../../../../../reducers/slices/subscriptions/subscriptions");
var client_subscriptions_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/client_subscriptions_thunk");
var constants_1 = require("./constants");
var SubscriptionsTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.subscriptionsState; }), isLoading = _a.isLoading, subscriptions = _a.subscriptions, pagination = _a.pagination;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Subscriptions"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id)
            _getClientSubscriptions(pagination);
    }, [user]);
    var _getClientSubscriptions = function (query) { return dispatch((0, client_subscriptions_thunk_1.client_subscriptions_thunk)(query)); };
    var _onPageChange = function (page) {
        return _getClientSubscriptions(__assign(__assign({}, pagination), { page: page }));
    };
    var _onRowsPerPageChange = function (rows) {
        return _getClientSubscriptions(__assign(__assign({}, pagination), { size: rows }));
    };
    var _onSearch = function (search_string) { return _getClientSubscriptions(__assign(__assign({}, pagination), (search_string && { search_string: search_string }))); };
    var rowActions = [
        {
            label: "Edit",
            onClick: function (subscription) {
                dispatch((0, subscriptions_1.setSelectedSubscription)(subscription));
                return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.SUBSCRIPTIONS_ROUTE).concat(routes_1.SUBSCRIPTION_ROUTE, "/").concat(subscription._id));
            },
        },
    ];
    return (react_1.default.createElement("div", { className: "w-full flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-end items-center gap-4" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add Subscription", endIcon: pro_light_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.SUBSCRIPTIONS_ROUTE).concat(routes_1.SUBSCRIPTION_ROUTE)); } }),
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_light_svg_icons_1.faSearch, size: "small", onChange: _onSearch })),
        isLoading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })) : (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.tableColumns, rowActions: rowActions, data: subscriptions, 
                //  onSearch={_onSearch}
                hasShadow: true, pagination: {
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: _onPageChange,
                    onRowsPerPageChange: _onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig })))));
};
exports.SubscriptionsTablePage = SubscriptionsTablePage;
