"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PRODUCT_AVAILABILITY = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../../reducers/slices/main");
var get_selected_product_type_1 = require("../../../../../../api/calls/product/get_selected_product_type");
var get_selected_category_1 = require("../../../../../../api/calls/product/category/get_selected_category");
var richTextEditor_1 = require("../../../../../../components/richTextEditor");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var get_category_thunk_1 = require("../../../../../../reducers/slices/products/categories/thunks/get_category_thunk");
exports.PRODUCT_AVAILABILITY = [
    { label: "Online", value: "ONLINE" },
    { label: "Offline", value: "OFFLINE" },
];
var ProductDetailsComponent = function (props) {
    var _a, _b, _c, _d, _e, _f;
    var _g = (0, useSearchDebounce_1.useSearchDebounce)(), searchCategories = _g[0], setSearchCategories = _g[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var values = props.values, updateValues = props.updateValues;
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }), categories = _h.categories, pagination = _h.pagination;
    var productTypes = (0, react_redux_1.useSelector)(function (state) { return state.productTypeState; }).productTypes;
    var _j = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _j.client_token, user = _j.user;
    var _k = (0, react_1.useState)(((_a = values === null || values === void 0 ? void 0 : values.type) === null || _a === void 0 ? void 0 : _a._id) ? (_b = values === null || values === void 0 ? void 0 : values.type) === null || _b === void 0 ? void 0 : _b._id : " "), selectedProductType = _k[0], setSelectedProductType = _k[1];
    var _l = (0, react_1.useState)(((_c = values === null || values === void 0 ? void 0 : values.category) === null || _c === void 0 ? void 0 : _c._id) ? (_d = values === null || values === void 0 ? void 0 : values.category) === null || _d === void 0 ? void 0 : _d._id : " "), selectedCategoryType = _l[0], setSelectedCategoryType = _l[1];
    var _m = (0, react_1.useState)((values === null || values === void 0 ? void 0 : values.sub_category_id) ? values === null || values === void 0 ? void 0 : values.sub_category_id : " "), selectedSubCategoryType = _m[0], setSelectedSubCategoryType = _m[1];
    var _o = (0, react_1.useState)([]), subCategories = _o[0], setSubCategories = _o[1];
    var _p = (0, react_1.useState)(1), currentPage = _p[0], setCurrentPage = _p[1];
    var _q = (0, react_1.useState)(10), rowsPerPage = _q[0], setRowsPerPage = _q[1];
    var handleTextChange = function (value) {
        updateValues("description", value);
    };
    var onTypeChange = function (type) {
        setSelectedProductType(type);
        updateValues("type_id", type);
    };
    var onCategoryTypeChange = function (category) {
        setSelectedCategoryType(category);
        updateValues("category_id", category === null || category === void 0 ? void 0 : category._id);
    };
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (client_token && ((_a = values === null || values === void 0 ? void 0 : values.category) === null || _a === void 0 ? void 0 : _a._id)) {
            (0, get_selected_category_1.get_selected_product_category)({ _id: (_b = values === null || values === void 0 ? void 0 : values.category) === null || _b === void 0 ? void 0 : _b._id }).then(function (response) {
                if (response.success) {
                    setSelectedCategoryType(categories.find(function (x) { return x._id == response.content._id; }));
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when retrieving selected category.", messages: [response.message] }));
                }
            });
        }
    }, [(_e = values === null || values === void 0 ? void 0 : values.category) === null || _e === void 0 ? void 0 : _e._id]);
    (0, react_1.useEffect)(function () {
        var _a;
        if (selectedCategoryType) {
            var subCategories_1 = [];
            (_a = selectedCategoryType === null || selectedCategoryType === void 0 ? void 0 : selectedCategoryType.sub_categories) === null || _a === void 0 ? void 0 : _a.map(function (subCategory) {
                var name = subCategory.name, _id = subCategory._id;
                subCategories_1.push(__assign({ label: name, value: _id }, subCategory));
            });
            setSubCategories(subCategories_1);
        }
    }, [selectedCategoryType]);
    (0, react_1.useEffect)(function () {
        var _a, _b;
        if (client_token && ((_a = values === null || values === void 0 ? void 0 : values.type) === null || _a === void 0 ? void 0 : _a._id)) {
            (0, get_selected_product_type_1.get_selected_product_type)({ _id: (_b = values === null || values === void 0 ? void 0 : values.type) === null || _b === void 0 ? void 0 : _b._id }).then(function (response) {
                var _a;
                if (response.success) {
                    setSelectedProductType((_a = response.content) === null || _a === void 0 ? void 0 : _a._id);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when retrieving selected product type.", messages: [response.message] }));
                }
            });
        }
    }, [(_f = values === null || values === void 0 ? void 0 : values.type) === null || _f === void 0 ? void 0 : _f._id]);
    var getCategories = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_category_thunk_1.get_category_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getCategories(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    (0, react_1.useEffect)(function () {
        onSearch(searchCategories);
    }, [searchCategories]);
    var onPageChangeCategories = function (page) {
        setCurrentPage(page);
        if (searchCategories === "") {
            return getCategories(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            return getCategories(__assign(__assign({}, pagination), { page: page, search_string: searchCategories }));
        }
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Product Name", type: "text", size: "medium", borderColor: "border-borders", value: values.title, errorText: !(values === null || values === void 0 ? void 0 : values.title) && "product name is required.", onChange: function (v) {
                        updateValues("title", v);
                    } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Product Subtitle", type: "text", size: "medium", borderColor: "border-borders", value: values.sub_title, errorText: "", onChange: function (v) {
                        updateValues("sub_title", v);
                    } }),
                react_1.default.createElement("div", null,
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Product Description", className: "pb-2 text-black text-sm" }),
                    react_1.default.createElement(richTextEditor_1.RichTextEditor, { initialContent: values === null || values === void 0 ? void 0 : values.description, onTextChange: handleTextChange })))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white mt-4 ", variant: "filled" },
            react_1.default.createElement("div", { className: "mb-4  items-center" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Pricing", bold: true, textColor: "text-black", className: "" }),
                react_1.default.createElement("div", { className: "flex justify-evenly" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Cost price", type: "number", size: "medium", borderColor: "border-borders", value: values.cost_price, errorText: "", onChange: function (v) {
                            updateValues("cost_price", v);
                        } }),
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "List price", type: "number", size: "medium", borderColor: "border-borders", value: values.price, errorText: "", onChange: function (v) {
                            updateValues("price", v);
                        } })))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white mt-4", variant: "filled" },
            react_1.default.createElement("div", { className: "mb-4" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Product Organization", bold: true, textColor: "text-black" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement("div", { className: "grid grid-cols-2 gap-4 bg-white" },
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) {
                                return react_1.default.createElement(react_1.default.Fragment, null, v.name);
                            }, label: "Product Category", required: true, options: categories !== null && categories !== void 0 ? categories : [], colors: {
                                labelColor: "text-black",
                                textColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-black",
                            }, size: "medium", value: selectedCategoryType, errorText: "", onChange: function (x) {
                                onCategoryTypeChange(x);
                                setSearchCategories("");
                            }, onSearchChanged: function (search_string) {
                                setSearchCategories(search_string);
                            }, pagination: {
                                currentPage: currentPage,
                                recordsPerPage: rowsPerPage,
                                totalRecords: pagination === null || pagination === void 0 ? void 0 : pagination.countTotal,
                                position: "center",
                                onClick: function (page) {
                                    onPageChangeCategories(page);
                                },
                            } })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.NewDropdownControl, { label: "Sub Categories", required: true, options: subCategories, renderDisplay: function (item) {
                                return react_1.default.createElement(react_1.default.Fragment, null, item.label);
                            }, colors: {
                                textColor: "text-black",
                                labelColor: "text-black",
                                borderColor: "border-borders",
                                iconColor: "text-primary",
                            }, size: "medium", value: selectedSubCategoryType, onChange: function (v) {
                                setSelectedSubCategoryType(v);
                                updateValues("category_id", v === null || v === void 0 ? void 0 : v._id);
                            }, errorText: "" })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Product Type", borderColor: "border-borders", required: true, options: productTypes === null || productTypes === void 0 ? void 0 : productTypes.map(function (x) {
                                return __assign({ label: x.name, value: x._id }, x);
                            }), iconColor: "text-primary", size: "medium", value: selectedProductType, onChange: onTypeChange, errorText: "" }),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "This product has a SKU or barcode", value: (values && (values === null || values === void 0 ? void 0 : values.sku)) || (values === null || values === void 0 ? void 0 : values.barcode) ? true : false, onChange: function (v) { } }))),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Product PID", type: "text", size: "medium", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.pid, errorText: !values.pid && "product ID is required.", onChange: function (v) {
                                updateValues("pid", v);
                            } })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Product SKU", type: "text", size: "medium", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.sku, errorText: !(values === null || values === void 0 ? void 0 : values.sku) && "product SKU is required.", onChange: function (v) {
                                updateValues("sku", v);
                            } })),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Barcode", type: "text", size: "medium", borderColor: "border-borders", value: values === null || values === void 0 ? void 0 : values.barcode, errorText: "", onChange: function (v) {
                                updateValues("barcode", v);
                            } })),
                    (user === null || user === void 0 ? void 0 : user.role) === "ADMIN" && (react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Product Available", borderColor: "border-borders", required: true, options: exports.PRODUCT_AVAILABILITY, iconColor: "text-primary", size: "medium", value: values === null || values === void 0 ? void 0 : values.availability, onChange: function (v) {
                                updateValues("availability", v);
                            }, errorText: "" }))),
                    (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" && (react_1.default.createElement("div", null,
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Product Available", type: "text", disabled: true, size: "medium", borderColor: "border-borders", value: "Offline", errorText: "", onChange: function () { } }))))),
            react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white mt-4", variant: "filled" },
                react_1.default.createElement("div", { className: "mb-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Product Extra Data", bold: true, textColor: "text-black" })),
                react_1.default.createElement(doshx_controls_web_1.ExtraDataControl, { extraData: values === null || values === void 0 ? void 0 : values.extra_fields, displayKeys: { key: "name", value: "value" }, colors: { backgroundColor: "bg-white" }, onChange: function (value) {
                        updateValues("extra_fields", value);
                    } })))));
};
exports.default = ProductDetailsComponent;
