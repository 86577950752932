"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurementPage = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../constants/routes");
var suppliers_1 = require("./suppliers");
var procure_1 = require("./procure");
var supplier_details_1 = require("./supplier_details");
var procurement_history_1 = require("./components/procurement_history");
var procurement_products_1 = require("./components/procurement_products");
var ProcurementPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useEffect)(function () { }, []);
    return (react_1.default.createElement("div", { className: "w-full h-full" },
        react_1.default.createElement(react_router_dom_1.Routes, null,
            react_1.default.createElement(react_router_dom_1.Route, { path: "/*", element: react_1.default.createElement(procure_1.ProcurePage, null) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.SUPPLIERS_ROUTE), element: react_1.default.createElement(suppliers_1.SupplierTable, null) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.SUPPLIERS_DETAILS_ROUTE, "/:client_id"), element: react_1.default.createElement(supplier_details_1.SupplierDetailsPage, null) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.PROCUREMENT_HISTORY_ROUTE), element: react_1.default.createElement(procurement_history_1.ProcurementHistoryPage, null) }),
            react_1.default.createElement(react_router_dom_1.Route, { path: "".concat(routes_1.PROCUREMENT_PRODUCTS_ROUTE, "/:procurement_id"), element: react_1.default.createElement(procurement_products_1.ProcurementProductsPage, null) }))));
};
exports.ProcurementPage = ProcurementPage;
