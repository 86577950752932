"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.productAddOnsTableColumns = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var get_products_shopify_thunk_1 = require("../../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var react_redux_1 = require("react-redux");
var useSearchDebounce_1 = require("../../../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../../../constants/pages");
var sanitiseObject_1 = require("../../../../../../helpers/sanitiseObject");
var update_product_1 = require("../../../../../../api/calls/product/update_product");
var main_1 = require("../../../../../../reducers/slices/main");
exports.productAddOnsTableColumns = [
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "price",
        label: "Price",
    },
    {
        displayKey: "required",
        label: "Required",
    },
    {
        displayKey: "quantity",
        label: "Amount Per Variant",
    },
];
var AddOnsComponent = function (props) {
    var _a, _b;
    var values = props.values, setValues = props.setValues;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _c = (0, useSearchDebounce_1.useSearchDebounce)(), searchAddOnProducts = _c[0], setSearchAddOnProducts = _c[1];
    var _d = (0, react_1.useState)(""), addOn = _d[0], setAddOn = _d[1];
    var _e = (0, react_1.useState)(1), currentPage = _e[0], setCurrentPage = _e[1];
    var _f = (0, react_1.useState)(10), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = (0, react_1.useState)(false), showSaveChangesLabel = _g[0], setShowSaveChangesLabel = _g[1];
    var _h = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), productAddOnsPagination = _h.productAddOnsPagination, productAddOns = _h.productAddOns;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign(__assign({}, productAddOnsPagination), { is_add_ons_request: true })));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearch(searchAddOnProducts);
    }, [searchAddOnProducts]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })), { is_add_ons_request: true }));
    };
    var onPageChangeProductAddOns = function (page) {
        setCurrentPage(page);
        if (searchAddOnProducts === "") {
            return getProducts(__assign(__assign({}, productAddOnsPagination), { is_add_ons_request: true, page: page }));
        }
        else {
            return getProducts(__assign(__assign({}, productAddOnsPagination), { is_add_ons_request: true, page: page, search_string: searchAddOnProducts }));
        }
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "" },
        react_1.default.createElement("div", { className: "mb-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Add Ons", bold: true, textColor: "text-black" })),
        react_1.default.createElement("div", { className: "flex gap-4 mb-2" },
            react_1.default.createElement("div", { className: "w-80" },
                react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (v) { return v.label; }, label: "Find product to place as an add on", required: true, options: (_a = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.map(function (product, i) {
                        return { label: product.title, value: product === null || product === void 0 ? void 0 : product._id };
                    })) !== null && _a !== void 0 ? _a : [], colors: {
                        labelColor: "text-black",
                        textColor: "text-black",
                        borderColor: "border-borders",
                        iconColor: "text-black",
                    }, size: "medium", value: addOn, errorText: "", onChange: function (v) {
                        var _a;
                        setAddOn(v);
                        var product = productAddOns === null || productAddOns === void 0 ? void 0 : productAddOns.find(function (selectedProduct) { return (selectedProduct === null || selectedProduct === void 0 ? void 0 : selectedProduct._id) === (v === null || v === void 0 ? void 0 : v.value); });
                        (_a = values === null || values === void 0 ? void 0 : values.add_ons) === null || _a === void 0 ? void 0 : _a.push(product);
                        setShowSaveChangesLabel(true);
                    }, onSearchChanged: function (search_string) {
                        setTimeout(function () {
                            setSearchAddOnProducts(search_string);
                        }, 2000);
                    }, pagination: {
                        currentPage: currentPage,
                        recordsPerPage: rowsPerPage,
                        totalRecords: productAddOnsPagination === null || productAddOnsPagination === void 0 ? void 0 : productAddOnsPagination.countTotal,
                        position: "center",
                        onClick: function (page) {
                            onPageChangeProductAddOns(page);
                        },
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-10 gap-4 bg-black w-full rounded p-2 mb-2" }, exports.productAddOnsTableColumns.map(function (_a, index) {
            var label = _a.label;
            return (react_1.default.createElement("div", { className: "col-span-2 flex items-center", key: index },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: label, className: "overflow-anywhere text-white", size: "small", textColor: "text-black", bold: true })));
        })), (_b = values === null || values === void 0 ? void 0 : values.add_ons) === null || _b === void 0 ? void 0 :
        _b.map(function (product, index) {
            return (react_1.default.createElement("div", { className: "grid grid-cols-10 w-full gap-2 items-center pb-2", key: index }, exports.productAddOnsTableColumns.map(function (_a, i) {
                var displayKey = _a.displayKey;
                switch (displayKey) {
                    case "quantity":
                        return (react_1.default.createElement("div", { className: "col-span-2 flex flex-row gap-4 items-center", key: i },
                            react_1.default.createElement("div", { className: "w-24" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: product.quantity, size: "small", type: "number", onChange: function (v) {
                                        var updatedProduct = __assign(__assign({}, product), { quantity: v });
                                        var updatedAddOns = values === null || values === void 0 ? void 0 : values.add_ons.map(function (item) {
                                            if (item._id === product._id) {
                                                return updatedProduct;
                                            }
                                            return item;
                                        });
                                        setValues(__assign(__assign({}, values), { add_ons: updatedAddOns }));
                                    } })),
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { size: "2xsmall", icon: pro_light_svg_icons_1.faTrash, backgroundColor: "bg-red-400", iconColor: "text-white", borderColor: "border-transparent", onClick: function (v) {
                                    var _a;
                                    var filteredProducts = (_a = values === null || values === void 0 ? void 0 : values.add_ons) === null || _a === void 0 ? void 0 : _a.filter(function (item) { return (item === null || item === void 0 ? void 0 : item._id) !== (product === null || product === void 0 ? void 0 : product._id); });
                                    setValues(__assign(__assign({}, values), { add_ons: __spreadArray([], filteredProducts, true) }));
                                    setShowSaveChangesLabel(true);
                                } })));
                    case "required":
                        return (react_1.default.createElement("div", { className: "col-span-2 break-words items-center", key: i },
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { size: "xsmall", value: product === null || product === void 0 ? void 0 : product.is_required, onChange: function (v) {
                                    var updatedProduct = __assign(__assign({}, product), { is_required: v });
                                    var updatedAddOns = values === null || values === void 0 ? void 0 : values.add_ons.map(function (item) {
                                        if (item._id === product._id) {
                                            return updatedProduct;
                                        }
                                        return item;
                                    });
                                    setValues(__assign(__assign({}, values), { add_ons: updatedAddOns }));
                                    setShowSaveChangesLabel(true);
                                } })));
                    default:
                        return (react_1.default.createElement("div", { className: "col-span-2 break-words items-center", key: i },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: product[displayKey], size: "xsmall", textColor: "text-black" })));
                }
            })));
        }),
        react_1.default.createElement("div", { className: "flex flex-col items-end" },
            react_1.default.createElement("div", { className: "w-[100px] mt-2 pb-1" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", label: "Save", size: "medium", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var params, response;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    params = (0, sanitiseObject_1.sanitiseObject)(__assign({}, values));
                                    return [4 /*yield*/, (0, update_product_1.update_product)(params)];
                                case 1:
                                    response = _a.sent();
                                    if (response.success) {
                                        setShowSaveChangesLabel(false);
                                        dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            showSaveChangesLabel && (react_1.default.createElement("div", null,
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Save Changes...", size: "xsmall", textColor: "text-red-600" }))))));
};
exports.default = AddOnsComponent;
