"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketPayfastGateway = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var doshx_controls_web_1 = require("doshx_controls_web");
var get_client_1 = require("../../../../api/calls/client/get_client");
var main_1 = require("../../../../reducers/slices/main");
var pay_with_payfast_1 = require("../../../../api/calls/payments/payment_engine/pay_with_payfast");
var routes_1 = require("../../../../constants/routes");
var BasketPayfastGateway = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var payFastFormRef = (0, react_1.useRef)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var _a = (0, react_1.useState)(), formData = _a[0], setFormData = _a[1];
    var clientID = params.clientID, basketID = params.basketID, userID = params.userID, name = params.name, surname = params.surname, cellNumber = params.cellNumber, amount = params.amount, itemName = params.itemName;
    var searchParams = (0, react_router_dom_1.useSearchParams)()[0];
    console.log(searchParams.get("type"));
    (0, react_1.useEffect)(function () {
        console.log("clientID  ==>", clientID);
        (0, get_client_1.get_client)(clientID).then(function (clientRes) {
            if (clientRes.success) {
                var _a = clientRes.content, token = _a.token, integration_settings = _a.integration_settings;
                localStorage.setItem("paymentEngineToken", integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_token);
                localStorage.setItem("clientToken", token);
                (0, pay_with_payfast_1.pay_with_payfast)({
                    integration_id: userID,
                    return_url: "".concat(process.env.LETS_TRADE_WEB_URL, "#").concat(routes_1.SUCCESS_BASKET_ROUTE, "/").concat(basketID, "/").concat(userID),
                    cancel_url: "".concat(process.env.LETS_TRADE_WEB_URL, "#").concat(routes_1.FAILED_BASKET_ROUTE, "/").concat(basketID, "/").concat(userID),
                    name_first: name,
                    name_last: surname,
                    cell_number: cellNumber,
                    amount: amount,
                    item_name: itemName,
                }, integration_settings === null || integration_settings === void 0 ? void 0 : integration_settings.payment_engine_token).then(function (payfastRes) {
                    if (payfastRes.success) {
                        var result = payfastRes.content.result;
                        setFormData(result);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [payfastRes.message] }));
                    }
                });
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [clientRes.message] }));
            }
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (formData) {
            payFastFormRef.current.submit();
        }
    }, [formData]);
    return (react_1.default.createElement("div", { className: "container mx-auto p-4 flex flex-col gap-4 " },
        react_1.default.createElement("div", { className: "" },
            react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "xlarge" })),
        react_1.default.createElement("div", { className: "" }, formData && (react_1.default.createElement("form", { ref: payFastFormRef, action: process.env.PAYFAST_FORM_URL, method: "post" //
            , className: "hidden" }, Object.keys(formData).map(function (key, index) {
            return react_1.default.createElement("input", { key: "form-hidden-field-".concat(index), type: "hidden", name: key, value: formData[key] });
        }))))));
};
exports.BasketPayfastGateway = BasketPayfastGateway;
