"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfilePage = void 0;
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var sectioner_1 = require("../../../../controls/sectioner");
var pages_1 = require("../../../../constants/pages");
var ProfilePage = function (props) {
    var dispatch = (0, react_redux_1.useDispatch)();
    // const { isSideHeaderControlOpen } = props;
    var _a = (0, react_1.useState)(null), selectedPaymentMethod = _a[0], setSelectedPaymentMethod = _a[1];
    var _b = (0, react_1.useState)([
        { title: "Credit Card", type: "visa", maskedNumber: "**** **** **** 2345", selected: false },
        { title: "Cheque Card", type: "mastercard", maskedNumber: "**** **** **** 0987", selected: true },
    ]), paymentMethods = _b[0], setPaymentMethods = _b[1];
    var _c = (0, react_1.useState)(1), currentPage = _c[0], setCurrentPage = _c[1];
    var _d = (0, react_1.useState)(10), rowsPerPage = _d[0], setRowsPerPage = _d[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Profile"));
    }, []);
    // may come from api call
    var userData = (0, react_1.useState)([
        {
            name: "Letstrade0008",
            label: "Username",
        },
        {
            name: "Bruno Silva",
            label: "Name",
        },
        {
            name: "BrunoSilva@Letstrade.com",
            label: "Email",
        },
    ])[0];
    // may come from api call
    var billingInfo = (0, react_1.useState)([
        {
            type: "Username",
            value: "Bruno Silva",
        },
        {
            type: "Company",
            value: "Let’sTrade",
        },
        {
            type: "Street",
            value: "100 Airdlin Rd, Sunninghill",
        },
        {
            type: "Surburb",
            value: "Johannesburg 2021",
        },
        {
            type: "Country",
            value: "South Africa",
        },
    ])[0];
    // may come from api call
    var taxInvoiceData = (0, react_1.useState)({
        columns: [
            {
                displayKey: "date",
                label: "Date",
            },
            {
                displayKey: "tax",
                label: "Tax Invoices",
            },
            {
                displayKey: "amount",
                label: "Total Amount",
            },
        ],
        content: [
            {
                _id: "1",
                date: "Jan 1, 2023",
                tax: 100765473,
                amount: "$150.00",
            },
            {
                _id: "2",
                date: "Jan 1, 2023",
                tax: 100765473,
                amount: "$150.00",
            },
        ],
    })[0];
    return (react_1.default.createElement("div", { className: "" },
        react_1.default.createElement("div", { className: "flex flex-row justify-center lg:justify-start" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { textColor: "text-black", bold: true, size: "2xlarge", label: "Account Details", center: true })),
        react_1.default.createElement("div", { className: "h-8" }),
        react_1.default.createElement("div", { className: "grid grid-cols-1 \n                   lg:grid-cols-2 gap-4 items-start justify-start" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center" },
                react_1.default.createElement(sectioner_1.SectionerComponent, { buttonText: "Update Account Details", onButtonClick: function () {
                        //
                    }, sections: [
                        react_1.default.createElement("div", null, userData.map(function (user, index) {
                            return (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { key: index, disabled: true, label: user.label, labelColor: "text-labelText", borderColor: "border-transparent", value: user.name }));
                        })),
                    ] }),
                react_1.default.createElement(sectioner_1.SectionerComponent, { title: "Current Plan", buttonText: "Update Account Details", onButtonClick: function () {
                        //
                    }, sections: [
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Plan Title", size: "small", textColor: "text-inputText", bold: true })),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Plan details,", size: "small", textColor: "text-disabled" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "10 January 2023 (Monthly)", size: "small", textColor: "text-disabled" })),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "What You'll be charged", size: "small", textColor: "text-disabled" })),
                    ] })),
            react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center" },
                react_1.default.createElement(sectioner_1.SectionerComponent, { title: "Upgrade your Let\u2019sTrade Subscription", buttonText: "Switch Plan", onButtonClick: function () {
                        //
                    }, sections: [
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "We will adjust the amount we charge you according to the remaining days on your current subscriptions", size: "small", textColor: "text-inputText" })),
                    ] }),
                react_1.default.createElement(sectioner_1.SectionerComponent, { title: "Payment Method", buttonText: "Add payment Method", onButtonClick: function () {
                        //
                    }, sections: [
                        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.PaymentMethodControl
                            // title="Payment Method"
                            // titleColor="text-inputText"
                            , { 
                                // title="Payment Method"
                                // titleColor="text-inputText"
                                options: paymentMethods, selectedValue: selectedPaymentMethod, onSelectedValueChanged: function (v) {
                                    setSelectedPaymentMethod(v);
                                } }),
                            react_1.default.createElement("div", { className: "flex" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update Payment method", size: "small", borderColor: "border-black", backgroundColor: "bg-transparent", textColor: "text-disabled" }))),
                        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Billing Information", size: "small", textColor: "text-inputText", bold: true }),
                            react_1.default.createElement("div", { className: "flex flex-col" }, billingInfo.map(function (info, index) {
                                return react_1.default.createElement(doshx_controls_web_1.LabelControl, { key: index, label: info.value, textColor: "text-inputText", size: "small" });
                            })),
                            react_1.default.createElement("div", { className: "flex" },
                                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Update Billing information", size: "small", borderColor: "border-black", backgroundColor: "bg-transparent", textColor: "text-disabled" }))),
                        react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Allow direct payments for clients or branches" })),
                    ] }))),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "underline pt-4", textColor: "text-underlineColor", bold: true, label: "Cancel Subscription" }),
        react_1.default.createElement("div", { className: "h-8" }),
        react_1.default.createElement(doshx_controls_web_1.TableControl, { title: "Tax Invoices", 
            // currentPage={currentPage}
            // rowsPerPage={rowsPerPage}
            // totalRecords={1000}
            columns: taxInvoiceData.columns, data: taxInvoiceData.content, rowActions: [
                {
                    label: "Edit",
                    onClick: function (o) {
                        console.log("Edit", o);
                    },
                },
                {
                    label: "Delete",
                    onClick: function (data) {
                        console.log("Delete", data);
                    },
                },
            ], onSearch: function (searchText) {
                console.log("Search", searchText);
            }, 
            //
            hasShadow: true, pagination: {
                currentPage: currentPage,
                rowsPerPage: rowsPerPage,
                totalRecords: 1000,
                onPageChange: function (page) {
                    setCurrentPage(page);
                },
                onRowsPerPageChange: function (rows) {
                    setRowsPerPage(rows);
                },
            }, colors: pages_1.tableColorConfig }),
        react_1.default.createElement("div", { className: "h-8" })));
};
exports.ProfilePage = ProfilePage;
