"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentSettingsPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var update_payment_config_1 = require("../../../../../api/calls/payments/update_payment_config");
var constants_1 = require("../../../../../constants/constants");
var get_client_thunk_1 = require("../../../../../reducers/slices/client/thunks/get_client_thunk");
var console_1 = require("../../../../../reducers/slices/console");
var main_1 = require("../../../../../reducers/slices/main");
var payment_settings_1 = require("../../../../../reducers/slices/payment_settings/payment_settings");
var add_client_payment_thunk_1 = require("../../../../../reducers/slices/payment_settings/thunks/add_client_payment_thunk");
var delete_client_payment_thunk_1 = require("../../../../../reducers/slices/payment_settings/thunks/delete_client_payment_thunk");
var payment_methods_1 = require("./components/payment_methods");
var PaymentSettingsPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(false), isPaymentConfigEnabled = _a[0], setIsPaymentConfigEnabled = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.paymentSettingsState; }), selectedPaymentSettings = _b.selectedPaymentSettings, isLoading = _b.isLoading;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _c = (0, react_1.useState)(selectedPaymentSettings === null || selectedPaymentSettings === void 0 ? void 0 : selectedPaymentSettings.payment_config), paymentConfig = _c[0], setPaymentConfig = _c[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Payments"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            dispatch((0, get_client_thunk_1.get_client_thunk)());
        }
    }, [user]);
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var _setPaymentConfig = function (value) {
        return setPaymentConfig(__assign(__assign({}, paymentConfig), value));
    };
    var _onUpdatePaymentConfig = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, update_payment_config_1.update_payment_config)(paymentConfig)];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, get_client_thunk_1.get_client_thunk)());
                        dispatch((0, main_1.setContentModal)(null));
                    }
                    else
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    return [2 /*return*/];
            }
        });
    }); };
    var _onToggleEnablePaymentConfig = function (e) { return !isPaymentConfigEnabled && e.preventDefault(); };
    var _onEnablePaymentConfig = function () { return setIsPaymentConfigEnabled(!isPaymentConfigEnabled); };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled" },
            react_1.default.createElement("div", { className: "flex flex-col gap-3", onClick: _onToggleEnablePaymentConfig, onDoubleClick: _onEnablePaymentConfig },
                react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 items-center gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Allow stores to manage payment provider", textColor: "text-black", size: "small" }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { onChange: function (allowBranchPayments) { return _setPaymentConfig({ account_owner: allowBranchPayments ? constants_1.BRANCH : constants_1.CLIENT }); }, value: paymentConfig.account_owner === constants_1.BRANCH, errorTextColor: "text-red-800" })),
                react_1.default.createElement("div", { className: "grid grid-cols-2 items-center gap-4" },
                    react_1.default.createElement("div", { className: "w-[200px]" },
                        react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "small", onClick: _onUpdatePaymentConfig })),
                    react_1.default.createElement("div", { className: "flex items-center gap-4" },
                        react_1.default.createElement(react_fontawesome_1.FontAwesomeIcon, { icon: !isPaymentConfigEnabled ? pro_light_svg_icons_1.faLock : pro_light_svg_icons_1.faUnlock, size: "1x", color: "text-disabled" }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Double click to unlock this function", textColor: "text-tableTextColor", size: "small" }))))),
        react_1.default.createElement(payment_methods_1.PaymentMethods, { selectedPaymentMethods: selectedPaymentSettings === null || selectedPaymentSettings === void 0 ? void 0 : selectedPaymentSettings.payment_method, addPaymentCallBack: function (paymentProvider) { return dispatch((0, add_client_payment_thunk_1.add_client_payment_thunk)(paymentProvider)); }, deletePaymentCallBack: function (paymentProvider) { return dispatch((0, delete_client_payment_thunk_1.delete_client_payment_thunk)(paymentProvider)); }, setPaymentCallBack: function (values) { return dispatch((0, payment_settings_1.setPaymentMethod)(values)); }, requestCallBack: function () { return dispatch((0, main_1.setContentModal)(null)); }, isLoading: isLoading })));
};
exports.PaymentSettingsPage = PaymentSettingsPage;
