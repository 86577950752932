"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var pages_1 = require("../../../../constants/pages");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var product_1 = require("../../../../reducers/slices/products/product");
var get_client_1 = require("../../../../api/calls/client/get_client");
var channelsCellComponent_1 = require("../../../../components/channelsCellComponent");
var constants_1 = require("../../constants");
var console_1 = require("../../../../reducers/slices/console");
var get_product_from_sku_list_thunk_1 = require("../../../../reducers/slices/products/thunks/get_product_from_sku_list_thunk");
var link_supplier_1 = require("../../../../api/calls/client/link_supplier");
var main_1 = require("../../../../reducers/slices/main");
var SupplierDetailsPage = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useParams)();
    var client_id = params.client_id;
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), search = _b[0], setSearch = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _c.products, pagination = _c.pagination, loading = _c.loading, selectedProducts = _c.selectedProducts, selectedCategory = _c.selectedCategory;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _d = (0, react_1.useState)(), clientData = _d[0], setClientData = _d[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Supplier Details"));
        getClient();
    }, []);
    var getClient = function () {
        (0, get_client_1.get_client)(client_id).then(function (res) {
            if (res.success) {
                setClientData(res.content);
            }
        });
    };
    var _e = (0, react_1.useEffect)(function () {
        if (clientData === null || clientData === void 0 ? void 0 : clientData.token) {
            if (selectedProducts.length > 0) {
                getProducts({ SKUs: selectedProducts.map(function (x) { return x.sku; }) });
            }
            else if (selectedCategory.length > 0) {
                getProducts({ category_ids: selectedCategory.map(function (x) { return x._id; }) });
            }
            else {
                navigate(-1);
            }
        }
    }, [clientData, search]);
    var getProducts = function (filter) {
        var _pagination = __assign({}, pagination);
        _pagination === null || _pagination === void 0 ? true : delete _pagination.countTotal;
        dispatch((0, get_product_from_sku_list_thunk_1.get_product_from_sku_list_thunk)({ payload: __assign({}, filter) }));
    };
    var linkSupplier = function () {
        var payload = {};
        if (selectedProducts.length > 0) {
            var SKUs = selectedProducts.map(function (x) { return x.sku; });
            payload.id_list = SKUs;
            payload.link_type = "product";
            getProducts({ SKUs: SKUs });
        }
        else if (selectedCategory.length > 0) {
            var categoryIds = selectedCategory.map(function (x) { return x._id; });
            payload.id_list = categoryIds;
            payload.link_type = "category";
            getProducts({ category_ids: categoryIds });
        }
        payload.supplier_id = client_id;
        (0, link_supplier_1.link_supplier)(payload).then(function (res) {
            if (res.success) {
                dispatch((0, main_1.setMessageModal)({ title: "Success", messages: [res.message] }));
            }
            else {
                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong!", messages: [res.message] }));
            }
        });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-between" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "xsmall", startIcon: pro_solid_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                    navigate(-1);
                } }),
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save Supplier", size: "xsmall", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                    linkSupplier();
                } })),
        react_1.default.createElement("div", { className: "flex gap-8 bg-white rounded-md p-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Supplier Name", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: clientData === null || clientData === void 0 ? void 0 : clientData.contact_person, size: "small" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Industry", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: clientData === null || clientData === void 0 ? void 0 : clientData.name, size: "small" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Email", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: clientData === null || clientData === void 0 ? void 0 : clientData.email, size: "small" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Phone Number", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: clientData === null || clientData === void 0 ? void 0 : clientData.contact_number, size: "small" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Address", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_a = clientData === null || clientData === void 0 ? void 0 : clientData.address) === null || _a === void 0 ? void 0 : _a.full_address, size: "small" })),
            react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Vat registered", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: clientData === null || clientData === void 0 ? void 0 : clientData.vat_percentage, size: "small" }))),
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex justify-between" },
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Products", bold: true })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, dispatch((0, product_1.setTablePagination)(pages_1.initialPaginationValues))];
                                    case 1:
                                        _a.sent();
                                        setSearch(e);
                                        return [2 /*return*/];
                                }
                            });
                        }); } }))),
            react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "image",
                        label: "Image",
                        formatter: function (product) {
                            if (product) {
                                return react_1.default.createElement("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "sku",
                        label: "SKU",
                    },
                    {
                        displayKey: "pid",
                        label: "PID",
                    },
                    {
                        displayKey: "title",
                        label: "Title",
                    },
                    ,
                    {
                        displayKey: "category.name",
                        label: "Category",
                        formatter: function (product) {
                            var _a;
                            if (product === null || product === void 0 ? void 0 : product.category) {
                                return (_a = product === null || product === void 0 ? void 0 : product.category) === null || _a === void 0 ? void 0 : _a.name;
                            }
                            else {
                                return "";
                            }
                        },
                    },
                    {
                        displayKey: "price",
                        label: "Price",
                        formatter: function (product) {
                            var _a, _b;
                            if (product) {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = (_a = product === null || product === void 0 ? void 0 : product.price) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "on_sale",
                        label: "On Sale",
                        formatter: function (product) {
                            if (product === null || product === void 0 ? void 0 : product.on_promotion) {
                                return "Yes";
                            }
                            else {
                                return "No";
                            }
                        },
                    },
                    {
                        displayKey: "on_sale_price",
                        label: "On Sale Price",
                        formatter: function (product) {
                            if (product === null || product === void 0 ? void 0 : product.on_promotion) {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: product === null || product === void 0 ? void 0 : product.promotion_price,
                                    symbolSeparation: true,
                                });
                            }
                            else {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "channels",
                        label: "Channel",
                        formatter: function (product) {
                            return (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: product === null || product === void 0 ? void 0 : product.channels });
                        },
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                        formatter: function (x) {
                            if (x) {
                                return (0, constants_1.TABLE_STATUSES)(x.status);
                            }
                        },
                    },
                ], data: (clientData === null || clientData === void 0 ? void 0 : clientData.token) ? products : [], pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: function (page) {
                        getProducts({ page: page });
                    },
                    onRowsPerPageChange: function (size) {
                        getProducts({ size: size });
                    },
                }, hasShadow: true, colors: pages_1.tableColorConfig }))));
};
exports.SupplierDetailsPage = SupplierDetailsPage;
