"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionPage = void 0;
var react_1 = __importStar(require("react"));
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var console_1 = require("../../../../../reducers/slices/console");
var get_category_thunk_1 = require("../../../../../reducers/slices/products/categories/thunks/get_category_thunk");
var get_products_shopify_thunk_1 = require("../../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var subscriptions_1 = require("../../../../../reducers/slices/subscriptions/subscriptions");
var add_subscription_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/add_subscription_thunk");
var update_subscription_thunk_1 = require("../../../../../reducers/slices/subscriptions/thunks/update_subscription_thunk");
var upload_image_thunk_1 = require("../../../../../reducers/slices/upload/thunk/upload_image_thunk");
var constants_1 = require("./constants");
var SubscriptionPage = function (props) {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var subscriptionId = (0, react_router_dom_1.useParams)().subscriptionId;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.subscriptionsState; }), isLoading = _d.isLoading, selectedSubscription = _d.selectedSubscription, subscriptionsAnalytics = _d.subscriptionsAnalytics, pagination = _d.pagination;
    var products = (0, react_redux_1.useSelector)(function (state) { return state.productState; }).products;
    var categories = (0, react_redux_1.useSelector)(function (state) { return state.categoryState; }).categories;
    var _e = (0, react_1.useState)(false), isInteracted = _e[0], setIsInteracted = _e[1];
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var _f = (0, validateForm_1.validateForm)(selectedSubscription, {
        validationConfig: constants_1.validationConfig,
        initialValues: constants_1.initialSubscriptionValues,
        enabled: isInteracted,
    }), errors = _f.errors, isValid = _f.isValid;
    var _setSubscription = function (value) {
        dispatch((0, subscriptions_1.setSelectedSubscription)(__assign(__assign({}, selectedSubscription), value)));
        if (!isInteracted)
            setIsInteracted(true);
    };
    var _onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription._id)
                        response = dispatch((0, update_subscription_thunk_1.update_subscription_thunk)());
                    return [4 /*yield*/, dispatch((0, add_subscription_thunk_1.add_subscription_thunk)())];
                case 1:
                    response = _b.sent();
                    if ((_a = response === null || response === void 0 ? void 0 : response.payload) === null || _a === void 0 ? void 0 : _a.success)
                        navigate(-1);
                    return [2 /*return*/];
            }
        });
    }); };
    var _getCategory = function (query) { return dispatch((0, get_category_thunk_1.get_category_thunk)(query)); };
    var _getProducts = function (query) { return dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query)); };
    var _onUploadSubscriptionImage = function (file) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, url, etag;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, dispatch((0, upload_image_thunk_1.upload_image_thunk)(file))];
                case 1:
                    _a = (_b.sent()).payload, url = _a.location, etag = _a.etag;
                    _setSubscription({ image: { url: url, etag: etag } });
                    return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Subscription Details"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id) {
            _getProducts(pagination);
            _getCategory(pagination);
        }
    }, [user]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", addPadding: false, className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-row" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back to subscription", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () { return navigate(-1); } })),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Subscription Analytics", textColor: "text-inputText", bold: true }),
            react_1.default.createElement("div", { className: "grid  grid-cols-3 lg:grid-cols-6 divide-x divide-borders gap-4" },
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: subscriptionsAnalytics.subscribers, title: subscriptionsAnalytics.subscribers.toString(), subTitle: "Subscribers", colors: { barFilledColor: "text-pink-500" } }),
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: subscriptionsAnalytics.redemptions, title: subscriptionsAnalytics.redemptions.toString(), subTitle: "Redemptions", colors: { barFilledColor: "text-purple-500" } }),
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: subscriptionsAnalytics.subscriptionsValue, title: subscriptionsAnalytics.subscriptionsValue.toString(), subTitle: "Value", colors: { barFilledColor: "text-yellow-500" } }),
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { value: Number(subscriptionsAnalytics.mostPurchasedProduct), title: subscriptionsAnalytics.mostPurchasedProduct.toString(), subTitle: "Most Purchased Product", colors: { barFilledColor: "text-blue-500" } }),
                react_1.default.createElement(doshx_controls_web_1.CircularStatisticControl, { icon: pro_light_svg_icons_1.faWallet, title: subscriptionsAnalytics.incrementalRevenueDate.toString(), subTitle: "Incremental revenue", colors: { iconColor: "text-green-500" } }))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-1 mb-5" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "General Subscription Details", size: "large", textColor: "text-inputText", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Add a new subscription by filling in the form below", textColor: "text-inputText" })),
            react_1.default.createElement("div", { className: "grid grid-cols-4 gap-4" },
                react_1.default.createElement("div", { className: "col-span-3 " },
                    react_1.default.createElement("div", { className: "gap-4 grid grid-cols-3 mb-3" },
                        react_1.default.createElement("div", { className: "flex flex-col justify-between" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Subscription name", labelColor: "text-labelText", value: selectedSubscription.name, errorText: errors.name, onChange: function (name) { return _setSubscription({ name: name }); } }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Billing Period", required: true, options: constants_1.periodOptions, iconColor: "text-primary", value: selectedSubscription.period, onChange: function (period) { return _setSubscription({ period: period }); }, errorText: errors.period, errorTextColor: "text-error" })),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Descriptions", lines: 5, labelColor: "text-labelText", value: selectedSubscription.description, errorText: errors.description, onChange: function (description) { return _setSubscription({ description: description }); } }),
                        react_1.default.createElement("div", { className: "flex flex-col justify-between" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "SKU", labelColor: "text-labelText", errorText: errors.sku, value: selectedSubscription.sku, onChange: function (sku) { return _setSubscription({ sku: sku }); } }),
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Subscription Type", required: true, options: constants_1.typeOptions, iconColor: "text-primary", value: selectedSubscription.type, onChange: function (type) { return _setSubscription({ type: type }); }, errorText: errors.type, errorTextColor: "text-error" })),
                        selectedSubscription.free_trial && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Trial Period", type: "number", errorText: errors.trial_period, value: selectedSubscription.trial_period, onChange: function (trial_period) { return _setSubscription({ trial_period: trial_period }); } })),
                        selectedSubscription.type !== "ONGOING" && (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Number of Periods", required: true, type: "number", value: selectedSubscription.number_of_periods, onChange: function (number_of_periods) { return _setSubscription({ number_of_periods: number_of_periods }); }, errorText: errors.number_of_periods, errorTextColor: "text-error" })),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Price", required: true, type: "number", value: selectedSubscription.price, onChange: function (price) { return _setSubscription({ price: price }); }, errorText: errors.price, errorTextColor: "text-error" })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "gap-4 grid grid-cols-4 my-5" },
                            react_1.default.createElement("div", { className: "col-span-1 items-center" },
                                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Allow Free-trial", value: selectedSubscription.free_trial, errorText: errors.free_trial, onChange: function (free_trial) { return _setSubscription({ free_trial: free_trial }); }, errorTextColor: "text-red-800" }),
                                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Auto Renewal", value: selectedSubscription.auto_renewal, errorText: errors.auto_renewal, onChange: function (auto_renewal) { return _setSubscription({ auto_renewal: auto_renewal }); }, errorTextColor: "text-red-800" }),
                                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Trial Conversion", value: selectedSubscription.trial_conversion, errorText: errors.trial_conversion, onChange: function (trial_conversion) { return _setSubscription({ trial_conversion: trial_conversion }); }, errorTextColor: "text-red-800" }),
                                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Pro-Rata Billing", value: selectedSubscription.billable, errorText: errors.billable, onChange: function (billable) { return _setSubscription({ billable: billable }); }, errorTextColor: "text-red-800" })),
                            react_1.default.createElement("div", { className: "col-span-2" },
                                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "", cols: "grid-cols-2", options: constants_1.subscriptionTypeOptions, selectedValue: String(selectedSubscription.subscription_usage_type), onChange: function (subscription_usage_type) { return _setSubscription({ subscription_usage_type: subscription_usage_type }); }, colors: pages_1.radioButtonColorConfig, errorText: errors.subscription_usage_type }),
                                react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { title: "", cols: "grid-cols-2", options: constants_1.paymentTypeOptions, selectedValue: String(selectedSubscription.payment_type), onChange: function (payment_type) { return _setSubscription({ payment_type: payment_type }); }, errorText: errors.payment_type, colors: pages_1.radioButtonColorConfig })))),
                    react_1.default.createElement("div", { className: "gap-4 grid grid-cols-3 mb-3" },
                        react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Product type selection", required: true, options: constants_1.productTypeOptions, iconColor: "text-primary", value: selectedSubscription.apply_to, onChange: function (apply_to) { return _setSubscription({ apply_to: apply_to }); }, errorText: errors.apply_to, errorTextColor: "text-error" }),
                        selectedSubscription.apply_to === "CATEGORY" && (react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { label: "Categories", onSearchChanged: function (search_string) { return _getCategory(__assign(__assign({}, pagination), (search_string && { search_string: search_string }))); }, options: categories, renderDisplay: function (_) { return "".concat(_.name); }, canSearch: true, selectedValues: selectedSubscription.categories, onSelectionChanged: function (categories) { return _setSubscription({ categories: categories }); } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errors.categories, size: "xsmall", textColor: "text-red-500" }))),
                        selectedSubscription.apply_to !== "CATEGORY" && (react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { label: "Product", renderDisplay: function (_) { return "".concat(_.title); }, options: products, onSearchChanged: function (search_string) { return _getProducts(__assign(__assign({}, pagination), (search_string && { search_string: search_string }))); }, selectedValues: selectedSubscription.primary_products, onSelectionChanged: function (primary_products) { return _setSubscription({ primary_products: primary_products }); } })),
                        selectedSubscription.apply_to === "COMPOUND" && (react_1.default.createElement("div", null,
                            react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { label: "Combination Products", options: products, onSearchChanged: function (search_string) { return _getProducts(__assign(__assign({}, pagination), (search_string && { search_string: search_string }))); }, renderDisplay: function (_) { return "".concat(_.title); }, selectedValues: selectedSubscription.products, onSelectionChanged: function (products) { return _setSubscription({ products: products }); } }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errors.products, size: "xsmall", textColor: "text-red-500" }))),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Quantity", type: "number", labelColor: "text-labelText", value: selectedSubscription.max_per_user, errorText: errors.max_per_user, onChange: function (max_per_user) { return _setSubscription({ max_per_user: max_per_user }); } })),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "my-5", label: "Schedule", textColor: "text-inputText", bold: true }),
                    react_1.default.createElement("div", { className: "gap-4 grid grid-cols-3 mb-5" },
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Redemptions in period", labelColor: "text-labelText", errorText: errors.max_redemptions_per_period, value: selectedSubscription.max_redemptions_per_period, onChange: function (max_redemptions_per_period) { return _setSubscription({ max_redemptions_per_period: max_redemptions_per_period }); } }),
                        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { type: "number", label: "Redemptions per day", labelColor: "text-labelText", errorText: errors.max_redemptions_per_day, onChange: function (max_redemptions_per_day) { return _setSubscription({ max_redemptions_per_day: max_redemptions_per_day }); } }),
                        react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { label: "Start date", value: new Date(selectedSubscription.start_date), errorText: errors.start_date, onChange: function (start_date) { return _setSubscription({ start_date: start_date }); } }),
                        react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { label: "Last day of purchase", value: new Date(selectedSubscription.last_purchase_date), errorText: errors.last_purchase_date, onChange: function (last_purchase_date) { return _setSubscription({ last_purchase_date: last_purchase_date }); } }),
                        react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { label: "Last day of redemption", errorText: errors.last_redemption_date, value: new Date(selectedSubscription.last_redemption_date), onChange: function (last_redemption_date) { return _setSubscription({ last_redemption_date: last_redemption_date }); } }),
                        react_1.default.createElement(doshx_controls_web_1.DateTimeControl, { label: "Valid until", errorText: errors.expires_in, value: new Date(selectedSubscription.expires_in), onChange: function (expires_in) { return _setSubscription({ expires_in: expires_in }); } })),
                    react_1.default.createElement("div", { className: "gap-4 grid grid-cols-3" },
                        react_1.default.createElement("div", { className: "col-span-1" },
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Failed Payment Setting", required: true, options: constants_1.onPaymentFailureOptions, iconColor: "text-primary", value: selectedSubscription.on_payment_failure, onChange: function (on_payment_failure) { return _setSubscription({ on_payment_failure: on_payment_failure }); }, errorText: errors.on_payment_failure, errorTextColor: "text-error" }),
                            selectedSubscription.on_payment_failure === "RETRY" && (react_1.default.createElement("div", { className: "flex flex-col gap-4 mt-4 justify-between" },
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Days between retry", type: "number", errorText: errors.retry_days, value: selectedSubscription.retry_days, onChange: function (retry_days) { return _setSubscription({ retry_days: retry_days }); } }),
                                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Number of retries", type: "number", errorText: errors.retry_count, value: selectedSubscription.retry_count, onChange: function (retry_count) { return _setSubscription({ retry_count: retry_count }); } }),
                                react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Pause while retrying", value: selectedSubscription.pause_while_retrying, errorText: errors.pause_while_retrying, onChange: function (pause_while_retrying) { return _setSubscription({ pause_while_retrying: pause_while_retrying }); } })))))),
                react_1.default.createElement("div", { className: "col-span-1" },
                    !((_a = selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.image) === null || _a === void 0 ? void 0 : _a.url) && (react_1.default.createElement(doshx_controls_web_1.FileInputControl, { startIcon: selectedSubscription.image ? pro_light_svg_icons_1.faImage : pro_light_svg_icons_1.faPen, required: true, previewFile: true, size: "medium", label: !(selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.image) ? "Upload Subscription image" : "Edit Subscription image", placeholder: "", onChange: _onUploadSubscriptionImage })),
                    (selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription._id) && ((_b = selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.image) === null || _b === void 0 ? void 0 : _b.url) && (react_1.default.createElement("div", { className: "flex items-center relative border border-solid border-borders p-0 rounded-lg " },
                        react_1.default.createElement("img", { src: (_c = selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.image) === null || _c === void 0 ? void 0 : _c.url, alt: "Subscription Image", className: "w-14 h-14 transition-all duration-300 rounded-lg cursor-pointer" }),
                        react_1.default.createElement("div", { className: "absolute right-3 bg-gray-600 rounded-full text-white" },
                            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPencil, size: "xsmall", backgroundColor: "bg-blue-700", borderColor: "border-transparent", iconColor: "text-white", shape: "circle", onClick: function () {
                                    _setSubscription({ image: null });
                                } })))))),
            react_1.default.createElement("div", { className: "flex items-center justify-around my-5" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return navigate(-1); } }),
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: subscriptionId ? "Edit" : "Save", enabled: isValid, loading: isLoading, onClick: _onSubmit })))));
};
exports.SubscriptionPage = SubscriptionPage;
