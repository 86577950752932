"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichMarkdownEditor = void 0;
var editor_1 = require("@mdxeditor/editor");
require("@mdxeditor/editor/style.css");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var RichMarkdownEditor = function (_a) {
    var markdown = _a.markdown, onChange = _a.onChange, className = _a.className, colours = _a.colours, errorText = _a.errorText;
    var ref = react_1.default.useRef(null);
    var _b = (0, react_1.useState)(Boolean(markdown)), isPopulated = _b[0], setIsPopulated = _b[1];
    var _c = (0, react_1.useState)(markdown !== null && markdown !== void 0 ? markdown : ""), value = _c[0], setValue = _c[1];
    (0, react_1.useEffect)(function () {
        var _a;
        if (!isPopulated && markdown) {
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setMarkdown(markdown);
        }
    }, [markdown]);
    var FallbackToolbar = function () { return react_1.default.createElement("span", { className: "w-full h-full" }, "Loading toolbar..."); };
    var borderColour = errorText ? "border-red-600" : (colours === null || colours === void 0 ? void 0 : colours.border) || "border-black-500";
    var errorColour = (colours === null || colours === void 0 ? void 0 : colours.error) || "text-red-500";
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: "flex border rounded-md ".concat(borderColour, " ").concat(className) },
            react_1.default.createElement(editor_1.MDXEditor, { ref: ref, markdown: value, plugins: [
                    (0, editor_1.listsPlugin)(),
                    (0, editor_1.quotePlugin)(),
                    (0, editor_1.headingsPlugin)(),
                    (0, editor_1.toolbarPlugin)({
                        toolbarContents: function () { return (react_1.default.createElement(react_1.Suspense, { fallback: react_1.default.createElement(FallbackToolbar, null) },
                            react_1.default.createElement(editor_1.DiffSourceToggleWrapper, null,
                                react_1.default.createElement(editor_1.UndoRedo, null),
                                react_1.default.createElement(editor_1.Separator, null),
                                react_1.default.createElement(editor_1.BlockTypeSelect, null),
                                react_1.default.createElement(editor_1.Separator, null),
                                react_1.default.createElement(editor_1.BoldItalicUnderlineToggles, null),
                                react_1.default.createElement(editor_1.Separator, null)))); },
                    }),
                    (0, editor_1.markdownShortcutPlugin)(),
                    (0, editor_1.diffSourcePlugin)({
                        diffMarkdown: "Previous",
                        viewMode: "rich-text",
                    }),
                ], contentEditableClassName: "prose", onChange: function (value) {
                    if (isPopulated || !markdown) {
                        onChange(value);
                    }
                    setIsPopulated(true);
                    setValue(value);
                }, className: "overflow-auto grow" })),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: errorText, size: "xsmall", textColor: errorColour, className: "text-ellipsis overflow-hidden hover:overflow-scroll px-2" })));
};
exports.RichMarkdownEditor = RichMarkdownEditor;
