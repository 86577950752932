"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var AddD365Organization = function () {
    var _a = (0, react_1.useState)(""), url = _a[0], setUrl = _a[1];
    var _b = (0, react_1.useState)(""), errorText = _b[0], setErrorText = _b[1];
    (0, react_1.useEffect)(function () { }, [open]);
    var validate = function () {
        var messageCount = 0;
        setErrorText("");
        if (!url) {
            messageCount++;
            setErrorText("Please enter url");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "gap-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. https://orgc889c88f.crm4.dynamics.com", size: "xsmall" }),
            react_1.default.createElement("div", { className: "flex flex-col gap-4" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "URL", type: "text", borderColor: "border-borders", value: url, 
                    // errorText={validate().url}
                    errorText: errorText, onChange: function (v) {
                        setUrl(v);
                    } }))),
        react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement("div", { className: "w-40" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Connect", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () {
                        if (validate()) {
                            var stateString = doshx_controls_web_1.Utilities.randomAlphanumeric();
                            var _scope = "".concat(url, "/user_impersonation");
                            var redirectTo = "".concat(process.env.LETS_TRADE_WEB_URL);
                            location.href = "https://login.microsoftonline.com/".concat(process.env.D365_DIRECTORY_ID, "/oauth2/v2.0/authorize?client_id=").concat(process.env.D365_CLIENT_ID, "&response_type=code&redirect_uri=").concat(redirectTo, "&response_mode=query&scope=").concat(_scope, "&state=").concat(stateString);
                        }
                    } }))),
        react_1.default.createElement("div", { className: "px-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "By dynamic 365 your Shopify account, you agree that your products will be visible and manageable on the Letstrade platform.", size: "xsmall", center: true }))));
};
exports.default = AddD365Organization;
