"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessDetails = void 0;
var react_1 = __importStar(require("react"));
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("./constants");
var routes_1 = require("../../../../constants/routes");
var get_client_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_thunk");
var get_client_address_1 = require("../../../../api/calls/client/get_client_address");
var main_1 = require("../../../../reducers/slices/main");
var get_selected_client_offering_1 = require("../../../../api/calls/client/get_selected_client_offering");
var update_client_1 = require("../../../../api/calls/client/update_client");
var update_client_offering_1 = require("../../../../api/calls/client/update_client_offering");
var update_client_address_1 = require("../../../../api/calls/client/update_client_address");
var create_client_address_1 = require("../../../../api/calls/client/create_client_address");
var offering_list_1 = require("../../../../api/calls/client/offering_list");
var registration_1 = require("../../../../reducers/slices/registration");
var pages_1 = require("../../../../constants/pages");
var BusinessDetails = function () {
    var _a, _b, _c, _d, _e, _f;
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var offerings = (0, react_redux_1.useSelector)(function (state) { return state.registrationState; }).offerings;
    var _g = (0, react_1.useState)([
        { label: "No", value: "No" },
        { label: "Yes", value: "Yes" },
    ]), vatOptions = _g[0], setVatOptions = _g[1];
    var _h = (0, react_1.useState)((client === null || client === void 0 ? void 0 : client.vat_registered) ? "Yes" : "No"), selectedVatOption = _h[0], setSelectedVatOption = _h[1];
    var currentCurrencyValue = constants_1.currenciesOptions[0].label;
    if (client === null || client === void 0 ? void 0 : client.currency_symbol) {
        currentCurrencyValue = (_a = constants_1.currenciesOptions.find(function (e) { return e.value === (client === null || client === void 0 ? void 0 : client.currency_symbol); })) === null || _a === void 0 ? void 0 : _a.label;
    }
    var _j = (0, react_1.useState)(currentCurrencyValue !== null && currentCurrencyValue !== void 0 ? currentCurrencyValue : ""), selectedCurrency = _j[0], setSelectedCurrency = _j[1];
    var _k = (0, react_1.useState)(false), isEditing = _k[0], setIsEditing = _k[1];
    var _l = (0, react_1.useState)(false), isSaving = _l[0], setIsSaving = _l[1];
    var _m = (0, react_1.useState)((_b = client === null || client === void 0 ? void 0 : client.name) !== null && _b !== void 0 ? _b : ""), registeredBusinessName = _m[0], setRegisteredBusinessName = _m[1];
    var _o = (0, react_1.useState)((_c = client === null || client === void 0 ? void 0 : client.id_number) !== null && _c !== void 0 ? _c : ""), registrationNumber = _o[0], setRegistrationNumber = _o[1];
    var _p = (0, react_1.useState)((_d = client === null || client === void 0 ? void 0 : client.vat_number) !== null && _d !== void 0 ? _d : ""), vatNumber = _p[0], setVatNumber = _p[1];
    var _q = (0, react_1.useState)((_e = client === null || client === void 0 ? void 0 : client.vat_percentage) !== null && _e !== void 0 ? _e : ""), vat = _q[0], setVat = _q[1];
    var _r = (0, react_1.useState)(""), clientAddress = _r[0], setClientAddress = _r[1];
    var _s = (0, react_1.useState)(), clientAddressObject = _s[0], setClientAddressObject = _s[1];
    var _t = (0, react_1.useState)(""), registeredBusinessNameMessage = _t[0], setRegisteredBusinessNameMessage = _t[1];
    var _u = (0, react_1.useState)(""), registrationNumberMessage = _u[0], setRegistrationNumberMessage = _u[1];
    var _v = (0, react_1.useState)(), clientAddressMessage = _v[0], setClientAddressMessage = _v[1];
    var _w = (0, react_1.useState)(" "), selectedIndustry = _w[0], setSelectedIndustry = _w[1];
    var onCurrencyStatusChange = function (status) {
        console.log("EWE status", status);
        setSelectedCurrency(status);
    };
    var onIndustryChange = function (status) {
        setSelectedIndustry(status);
    };
    var getClient = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_client_thunk_1.get_client_thunk)())];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var getSelectedClientOfferings = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (client_token) {
                (0, get_selected_client_offering_1.get_selected_client_offering_list)().then(function (response) {
                    var _a;
                    if (response.success) {
                        setSelectedIndustry((_a = response === null || response === void 0 ? void 0 : response.content) === null || _a === void 0 ? void 0 : _a.industry[0]);
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when retrieving selected offering.", messages: [response.message] }));
                    }
                });
            }
            return [2 /*return*/];
        });
    }); };
    var getClientAddresses = function () {
        if (user) {
            (0, get_client_address_1.get_client_address)({ page: null }).then(function (response) {
                var _a;
                if (response.success) {
                    setClientAddressObject(response === null || response === void 0 ? void 0 : response.content[0]);
                    setClientAddress((_a = response === null || response === void 0 ? void 0 : response.content[0]) === null || _a === void 0 ? void 0 : _a.full_address);
                }
                else {
                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when getting client address.", messages: [response.message] }));
                }
            });
        }
    };
    (0, react_1.useEffect)(function () {
        (0, offering_list_1.offering_list)().then(function (response) {
            dispatch((0, registration_1.setOfferings)(response.content[0]));
        });
    }, []);
    (0, react_1.useEffect)(function () {
        if (user && client_token) {
            getClientAddresses();
            getSelectedClientOfferings();
        }
    }, [client_token, user, isSaving]);
    (0, react_1.useEffect)(function () {
        if (user && (user === null || user === void 0 ? void 0 : user.client_id)) {
            getClient();
        }
    }, [user, user === null || user === void 0 ? void 0 : user.client_id, isSaving]);
    (0, react_1.useEffect)(function () {
        if (client) {
            setRegisteredBusinessName(client === null || client === void 0 ? void 0 : client.name);
            setRegistrationNumber(client === null || client === void 0 ? void 0 : client.id_number);
            setVatNumber(client === null || client === void 0 ? void 0 : client.vat_number);
            setVat(client === null || client === void 0 ? void 0 : client.vat_percentage);
        }
    }, [client]);
    var validateDetails = function () {
        var messageCount = 0;
        setRegisteredBusinessNameMessage("");
        setRegistrationNumberMessage("");
        setClientAddressMessage("");
        if (!(client === null || client === void 0 ? void 0 : client.name)) {
            messageCount++;
            setRegisteredBusinessNameMessage("Registered Business Name name is required.");
        }
        if (!(client === null || client === void 0 ? void 0 : client.id_number)) {
            messageCount++;
            setRegistrationNumberMessage("Registration Number is required.");
        }
        if (!clientAddress) {
            messageCount++;
            setClientAddressMessage("Client address required.");
        }
        return messageCount ? false : true;
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { className: " bg-white", borderColor: "border-transparent" },
        react_1.default.createElement("div", { className: "fle flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex pb-4" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "medium", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.DASHBOARD_ROUTE));
                    } })),
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement("div", { className: "flex-1 flex flex-col gap-4" },
                    react_1.default.createElement("div", { className: "grid grid-cols-2 gap-4 bg-white" },
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Business Name", disabled: !isEditing, value: registeredBusinessName, errorText: registeredBusinessNameMessage, onChange: function (v) { return setRegisteredBusinessName(v); }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-gray-500" : "border-disabled", lines: 1, size: "small" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Registration Number", disabled: !isEditing, value: registrationNumber, errorText: registrationNumberMessage, onChange: function (v) { return setRegistrationNumber(v); }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-gray-500" : "border-disabled", lines: 1, size: "small" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.RadioButtonsControl, { required: true, title: "Are you VAT registered?", colors: __assign(__assign({}, pages_1.radioButtonColorConfig), { titleColor: isEditing ? "text-black" : "text-disabled", radio: __assign(__assign({}, pages_1.radioButtonColorConfig.radio), { textColor: isEditing ? "text-black" : "text-disabled", iconColor: isEditing ? "text-black" : "text-disabled" }) }), options: vatOptions, selectedValue: selectedVatOption, onChange: function (value) {
                                    setSelectedVatOption(value);
                                }, errorText: !vatOptions ? "This selection is required" : "" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Vat Number", disabled: !isEditing, value: vatNumber, errorText: "", onChange: function (v) { return setVatNumber(v); }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-gray-500" : "border-disabled", lines: 1, size: "small" })),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "VAT %", disabled: !isEditing, value: vat, errorText: vatNumber && !vat ? "This field is required" : "", onChange: function (v) { return setVat(v); }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-gray-500" : "border-disabled", lines: 1, size: "small" })),
                        isEditing ? (react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Currency", required: true, options: constants_1.currenciesOptions, labelColor: "text-black", textColor: "text-black", borderColor: "border-gray-500", iconColor: "text-black", size: "small", value: selectedCurrency, errorText: "", onChange: onCurrencyStatusChange }))) : (react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Currency", disabled: !isEditing, value: selectedCurrency, errorText: "", onChange: function (v) { }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", lines: 1, size: "small" }))),
                        react_1.default.createElement("div", { className: "" }, isEditing ? (react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.AddressSearchControl, { value: clientAddress && clientAddress, required: true, label: "Search Address", size: "small", errorText: clientAddressMessage, onSelectionChange: function (addressObject) {
                                    var _a, _b;
                                    setClientAddress(addressObject);
                                    if (addressObject && clientAddressObject) {
                                        (0, update_client_address_1.update_client_address)({
                                            _id: clientAddressObject === null || clientAddressObject === void 0 ? void 0 : clientAddressObject._id,
                                            full_address: addressObject.fullAddress,
                                            longitude: addressObject.coordinates.lng,
                                            latitude: addressObject.coordinates.lat,
                                        }).then(function (response) {
                                            if (response.success) {
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when updating client address.", messages: [response.message] }));
                                            }
                                        });
                                    }
                                    else {
                                        (0, create_client_address_1.create_client_address)({
                                            full_address: addressObject === null || addressObject === void 0 ? void 0 : addressObject.fullAddress,
                                            longitude: (_a = addressObject === null || addressObject === void 0 ? void 0 : addressObject.coordinates) === null || _a === void 0 ? void 0 : _a.lng.toString(),
                                            latitude: (_b = addressObject === null || addressObject === void 0 ? void 0 : addressObject.coordinates) === null || _b === void 0 ? void 0 : _b.lat.toString(),
                                        }).then(function (response) {
                                            if (response.success) {
                                            }
                                            else {
                                                dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when creating client address.", messages: [response.message] }));
                                            }
                                        });
                                    }
                                } }))) : (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Address", value: clientAddress, onChange: function (v) { }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", lines: 1, size: "small" }))),
                        isEditing ? (react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Industry", required: true, options: (_f = offerings === null || offerings === void 0 ? void 0 : offerings.industry) === null || _f === void 0 ? void 0 : _f.map(function (e, i) {
                                    return { label: e, value: e };
                                }), labelColor: "text-black", textColor: "text-black", borderColor: "border-gray-500", iconColor: "text-black", size: "small", value: selectedIndustry, errorText: "", onChange: onIndustryChange }))) : (react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Industry", disabled: !isEditing, value: selectedIndustry, onChange: function (v) { }, backgroundColor: "bg-gray", textColor: isEditing ? "text-black" : "text-disabled", labelColor: isEditing ? "text-black" : "text-disabled", borderColor: isEditing ? "border-borders" : "border-disabled", lines: 1, size: "small" }))),
                    react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                        react_1.default.createElement("div", { className: "w-[100px]" },
                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: isEditing ? "Done" : "Edit", size: "medium", loading: isSaving, onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var _id, email, contact_number, contact_person, payload;
                                    return __generator(this, function (_a) {
                                        _id = client._id, email = client.email, contact_number = client.contact_number, contact_person = client.contact_person;
                                        if (isEditing) {
                                            if (validateDetails()) {
                                                setIsSaving(true);
                                                if (client) {
                                                    payload = {
                                                        _id: _id,
                                                        client: {
                                                            id_number: registrationNumber,
                                                            name: registeredBusinessName,
                                                            vat_number: vatNumber,
                                                            email: email,
                                                            contact_number: contact_number,
                                                            contact_person: contact_person,
                                                            vat_percentage: vat,
                                                            currency_symbol: selectedCurrency,
                                                            vat_registered: selectedVatOption === "Yes" ? true : false,
                                                        },
                                                    };
                                                    (0, update_client_1.update_client)(payload).then(function (response) {
                                                        if (response.success) {
                                                            setIsSaving(false);
                                                            setIsEditing(false);
                                                            dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: [response.message] }));
                                                        }
                                                        else {
                                                            dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when updating client details!", messages: [response.message] }));
                                                            setIsSaving(false);
                                                            setIsEditing(false);
                                                        }
                                                    });
                                                }
                                                (0, update_client_offering_1.update_client_offering)({
                                                    industry: selectedIndustry,
                                                }).then(function (response) {
                                                    if (response.success) {
                                                        setIsSaving(false);
                                                        setIsEditing(false);
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when updating client offering details!", messages: [response.message] }));
                                                        setIsSaving(false);
                                                        setIsEditing(false);
                                                    }
                                                });
                                            }
                                        }
                                        else {
                                            setIsEditing(true);
                                        }
                                        return [2 /*return*/];
                                    });
                                }); } })))),
                react_1.default.createElement("div", { className: "flex-1" })))));
};
exports.BusinessDetails = BusinessDetails;
