"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuotesTablePage = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../../reducers/slices/console");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var routes_1 = require("../../../../../constants/routes");
var react_router_dom_1 = require("react-router-dom");
var pages_1 = require("../../../../../constants/pages");
var get_client_quotes_thunk_1 = require("../../../../../reducers/slices/quotes/thunks/get_client_quotes_thunk");
var remove_quote_1 = require("../../../../../api/calls/customer-quotes/remove/remove_quote");
var main_1 = require("../../../../../reducers/slices/main");
var QuotesTablePage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _a.client_token, user = _a.user;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.quoteState; }), quotes = _b.quotes, pagination = _b.pagination, loading = _b.loading;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Quotes"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_client_quotes_thunk_1.get_client_quotes_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    var getQuotes = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_quotes_thunk_1.get_client_quotes_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchQuotesList = function (search_string) {
        getQuotes(__assign(__assign({}, pages_1.initialPaginationValues), (search_string && { search_string: search_string })));
    };
    var onPageChange = function (page) {
        return getQuotes(__assign(__assign({}, pagination), { page: page }));
    };
    var onRowsPerPageChange = function (size) {
        return getQuotes(__assign(__assign({}, pagination), { size: size }));
    };
    return (react_1.default.createElement("div", null,
        react_1.default.createElement("div", { className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex justify-end items-center gap-2" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Quote", endIcon: pro_solid_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.QUOTE_DETAILS_ROUTE));
                    } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) { } })),
            false ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : (react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: [
                    {
                        displayKey: "createdAt",
                        label: "Date Created",
                        formatter: function (quote) {
                            return doshx_controls_web_1.Utilities.formatDateTime(quote === null || quote === void 0 ? void 0 : quote.createdAt, "YYYY-MM-DD");
                        },
                    },
                    {
                        displayKey: "customer_name",
                        label: "Customer",
                        formatter: function (quote) {
                            var _a, _b;
                            if (quote) {
                                return "".concat((_a = quote === null || quote === void 0 ? void 0 : quote.customer) === null || _a === void 0 ? void 0 : _a.name, " ").concat((_b = quote === null || quote === void 0 ? void 0 : quote.customer) === null || _b === void 0 ? void 0 : _b.surname);
                            }
                            return "Anonymous";
                        },
                    },
                    {
                        displayKey: "amount",
                        label: "Amount",
                        formatter: function (quote) {
                            var _a, _b;
                            if (quote) {
                                return doshx_controls_web_1.Utilities.formatMoney({
                                    symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                    value: (_b = (_a = quote === null || quote === void 0 ? void 0 : quote.total) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "0",
                                    symbolSeparation: true,
                                });
                            }
                        },
                    },
                    {
                        displayKey: "status",
                        label: "Status",
                    },
                ], data: quotes, onRowClicked: function (quote) {
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.QUOTE_DETAILS_ROUTE, "/").concat(quote === null || quote === void 0 ? void 0 : quote._id));
                }, rowActions: [
                    {
                        label: "View",
                        onClick: function (quote) {
                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.QUOTE_DETAILS_ROUTE, "/").concat(quote === null || quote === void 0 ? void 0 : quote._id));
                        },
                    },
                    {
                        label: "Remove Quote",
                        onClick: function (quote) { return __awaiter(void 0, void 0, void 0, function () {
                            var _a, _b;
                            return __generator(this, function (_c) {
                                dispatch((0, main_1.setContentModal)({
                                    title: "Confirm",
                                    content: (react_1.default.createElement("div", { className: "flex gap-4 flex-col" },
                                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to remove ".concat((_a = quote === null || quote === void 0 ? void 0 : quote.customer) === null || _a === void 0 ? void 0 : _a.name, "  ").concat((_b = quote === null || quote === void 0 ? void 0 : quote.customer) === null || _b === void 0 ? void 0 : _b.surname, "'s quote ?"), textColor: "text-inputText" }),
                                        react_1.default.createElement("div", { className: "flex gap-4 justify-center" },
                                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                                            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                                    var response;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, (0, remove_quote_1.remove_customer_quote)({
                                                                    _id: quote === null || quote === void 0 ? void 0 : quote._id,
                                                                    deleted_by: "".concat(user === null || user === void 0 ? void 0 : user.name, " ").concat(user === null || user === void 0 ? void 0 : user.surname),
                                                                })];
                                                            case 1:
                                                                response = _a.sent();
                                                                if (response.success) {
                                                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                                    dispatch((0, main_1.setContentModal)(null));
                                                                    dispatch((0, get_client_quotes_thunk_1.get_client_quotes_thunk)(__assign({}, pagination)));
                                                                }
                                                                else {
                                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when removing the quote?", messages: [response.message] }));
                                                                }
                                                                dispatch((0, main_1.setContentModal)(null));
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); } })))),
                                }));
                                return [2 /*return*/];
                            });
                        }); },
                    },
                ], hasShadow: true, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: pagination.page,
                    rowsPerPage: pagination.size,
                    totalRecords: pagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, colors: pages_1.tableColorConfig })))));
};
exports.QuotesTablePage = QuotesTablePage;
