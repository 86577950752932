"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuConceptDetailsPage = exports.excludeProductsTableColumns = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../reducers/slices/console");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pages_1 = require("../../../../constants/pages");
var product_list_1 = require("../../../../api/calls/product/product_list");
var main_1 = require("../../../../reducers/slices/main");
var routes_1 = require("../../../../constants/routes");
var react_router_dom_1 = require("react-router-dom");
var get_products_shopify_thunk_1 = require("../../../../reducers/slices/products/thunks/get_products_shopify_thunk");
var product_1 = require("../../../../reducers/slices/products/product");
var menu_concepts_1 = require("../../../../reducers/slices/menuConcepts/menu_concepts");
var sanitiseObject_1 = require("../../../../helpers/sanitiseObject");
var useSearchDebounce_1 = require("../../../../hooks/useSearchDebounce");
var create_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/create_menu_concept_thunk");
var update_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/update_menu_concept_thunk");
var get_menu_concept_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/get_menu_concept_thunk");
var set_menu_concept_products_thunk_1 = require("../../../../reducers/slices/menuConcepts/thunks/set_menu_concept_products_thunk");
exports.excludeProductsTableColumns = [
    {
        displayKey: "image_url",
        label: "Image",
        formatter: function (product) {
            if (product) {
                return react_1.default.createElement("img", { className: "w-10 aspect-square rounded-lg", src: product === null || product === void 0 ? void 0 : product.image_url });
            }
            else {
                return "";
            }
        },
    },
    {
        displayKey: "title",
        label: "Title",
    },
    {
        displayKey: "sku",
        label: "SKU",
    },
    {
        displayKey: "pid",
        label: "PID",
    },
];
var MenuConceptDetailsPage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var menu_concept_id = (0, react_router_dom_1.useParams)().menu_concept_id;
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _a.client_token, user = _a.user;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _b.products, productsIsLoading = _b.loading, pagination = _b.pagination;
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.menuConceptState; }), selectedMenuConcept = _c.selectedMenuConcept, errors = _c.errors, isEdited = _c.isEdited, productSelectionEdited = _c.productSelectionEdited;
    var _d = (0, useSearchDebounce_1.useSearchDebounce)(), search = _d[0], setSearch = _d[1];
    var _e = (0, react_1.useState)([]), categoryOptions = _e[0], setCategoryOptions = _e[1];
    var _f = (0, react_1.useState)([]), selectedProducts = _f[0], setSelectedProducts = _f[1];
    var _g = (0, react_1.useState)([]), selectedCategories = _g[0], setSelectedCategories = _g[1];
    var _h = (0, react_1.useState)(1), currentPage = _h[0], setCurrentPage = _h[1];
    var _j = (0, react_1.useState)(10), rowsPerPage = _j[0], setRowsPerPage = _j[1];
    var _k = (0, react_1.useState)([]), selectedRows = _k[0], setSelectedRows = _k[1];
    var _l = (0, react_1.useState)([]), productOptions = _l[0], setProductOptions = _l[1];
    var onChange = function (values) {
        dispatch((0, menu_concepts_1.setSelectedMenuConcept)(__assign(__assign({}, selectedMenuConcept), values)));
        dispatch((0, menu_concepts_1.setIsEdited)(true));
    };
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Menu Concept Details"));
        return function () {
            dispatch((0, menu_concepts_1.setSelectedMenuConcept)(menu_concepts_1.initialMenuConceptState.selectedMenuConcept));
        };
    }, []);
    (0, react_1.useEffect)(function () {
        if (!client_token) {
            return;
        }
        if (!productsIsLoading) {
            dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(__assign({}, pagination)));
        }
        if (menu_concept_id) {
            getSelectedProducts();
            dispatch((0, get_menu_concept_thunk_1.get_menu_concept_thunk)({ _id: menu_concept_id, client_id: user === null || user === void 0 ? void 0 : user.client_id }));
        }
    }, [client_token]);
    // useEffect(() => {
    //   if (menu_concept_id) {
    //     get_product_list({ menu_concept_id, size: 0 }).then((response) => {
    //       if (response.success) {
    //         debugger;
    //         setSelectedProducts(response.content.product_list);
    //       }
    //     });
    //   }
    // }, [menu_concept_id]);
    // useEffect(() => {
    //   setCategoryOptions(categories.map((category) => ({ label: category.name, value: category._id })));
    // }, [categories]);
    // useEffect(() => {
    //   // for (const product of selectedProducts) {
    //   console.log("selectedCategories", selectedCategories);
    //   // for category
    //   dispatch(get_products_thunk({ ...pagination, category}));
    // }, [selectedCategories]);
    (0, react_1.useEffect)(function () {
        setProductOptions(products.map(function (product) { return ({
            _id: product._id,
            title: product.title,
            sku: product.sku,
            pid: product.pid,
            image_url: product.image_url,
        }); }));
    }, [products]);
    (0, react_1.useEffect)(function () {
        onSearch(search);
    }, [search]);
    var getProducts = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_products_shopify_thunk_1.get_products_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var getSelectedProducts = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, (0, product_list_1.get_product_list)({ menu_concept_id: menu_concept_id, size: 0 })];
                case 1:
                    response = _b.sent();
                    if (response.success) {
                        setSelectedProducts((_a = response.content.product_list) === null || _a === void 0 ? void 0 : _a.map(function (product) { return ({
                            _id: product._id,
                            title: product.title,
                            sku: product.sku,
                            pid: product.pid,
                            image_url: product.image_url,
                        }); }));
                    }
                    return [2 /*return*/, response];
            }
        });
    }); };
    var onSearch = function (search_string) {
        getProducts(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getProducts(__assign(__assign({}, pagination), { page: page, search_string: search }));
        }
    };
    var onRowsPerPageChange = function (size) {
        setRowsPerPage(size);
        return getProducts(__assign(__assign({}, pagination), { size: size }));
    };
    var onSelectionChange = function (selectedRows) {
        setSelectedRows(selectedRows);
        if (!productSelectionEdited) {
            dispatch((0, menu_concepts_1.setProductSelectionEdited)(true));
        }
    };
    var saveMenuConcept = function () { return __awaiter(void 0, void 0, void 0, function () {
        var params, payload, payload;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    params = (0, sanitiseObject_1.sanitiseObject)({
                        _id: selectedMenuConcept._id,
                        name: selectedMenuConcept.name,
                        branch_group_id_list: selectedMenuConcept.branch_group_id_list,
                        branch_id_list: selectedMenuConcept.branch_id_list,
                    });
                    if (!!selectedMenuConcept._id) return [3 /*break*/, 2];
                    return [4 /*yield*/, dispatch((0, create_menu_concept_thunk_1.create_menu_concept_thunk)(params))];
                case 1:
                    payload = (_b.sent()).payload;
                    if (!(payload === null || payload === void 0 ? void 0 : payload.success) && (payload === null || payload === void 0 ? void 0 : payload.content)) {
                        return [2 /*return*/];
                    }
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_DETAILS_ROUTE, "/").concat((_a = payload === null || payload === void 0 ? void 0 : payload.content) === null || _a === void 0 ? void 0 : _a._id));
                    return [2 /*return*/, payload];
                case 2: return [4 /*yield*/, dispatch((0, update_menu_concept_thunk_1.update_menu_concept_thunk)(params))];
                case 3:
                    payload = (_b.sent()).payload;
                    if (!(payload === null || payload === void 0 ? void 0 : payload.success) && (payload === null || payload === void 0 ? void 0 : payload.content)) {
                        return [2 /*return*/];
                    }
                    return [2 /*return*/, payload];
            }
        });
    }); };
    var onSave = function () { return __awaiter(void 0, void 0, void 0, function () {
        var result, id, payload;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!isEdited) return [3 /*break*/, 2];
                    return [4 /*yield*/, saveMenuConcept()];
                case 1:
                    result = _c.sent();
                    if ((result === null || result === void 0 ? void 0 : result.success) === false) {
                        return [2 /*return*/];
                    }
                    _c.label = 2;
                case 2:
                    id = (_a = selectedMenuConcept._id) !== null && _a !== void 0 ? _a : (_b = result === null || result === void 0 ? void 0 : result.content) === null || _b === void 0 ? void 0 : _b._id;
                    if (!(productSelectionEdited && id)) return [3 /*break*/, 4];
                    if (isEdited && (result === null || result === void 0 ? void 0 : result.success) === false) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, dispatch((0, set_menu_concept_products_thunk_1.set_menu_concept_products_thunk)({
                            product_id_list: selectedProducts.map(function (product) { return product._id; }),
                            menu_concept_id_list: [id],
                        }))];
                case 3:
                    payload = (_c.sent()).payload;
                    getSelectedProducts();
                    result = payload;
                    _c.label = 4;
                case 4:
                    if (result === null || result === void 0 ? void 0 : result.success) {
                        dispatch((0, main_1.setMessageModal)({ title: "Success!", messages: ["Your menu concept has been saved!"] }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
        react_1.default.createElement("div", { className: "flex justify-between items-center gap-4" },
            react_1.default.createElement("div", { className: "flex flex-row" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back To Menu Concepts", size: "xsmall", startIcon: pro_light_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.MENU_CONCEPT_LIST_ROUTE));
                    } })),
            react_1.default.createElement("div", { className: "w-[100px]" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "medium", backgroundColor: "bg-primary", textColor: "text-white", onClick: onSave, enabled: isEdited || productSelectionEdited }))),
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "flex gap-6" },
            react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Menu Concept Name", type: "text", size: "medium", borderColor: "border-borders", value: selectedMenuConcept.name, errorText: errors.name, onChange: function (name) { return onChange({ name: name }); } })),
        react_1.default.createElement("div", { className: "flex flex-col gap-4 relative" },
            react_1.default.createElement("div", { className: "flex justify-between gap-4 items-end" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Select products to include in this menu concept", textColor: "text-inputText", bold: true }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", borderColor: "border-borders", backgroundColor: "bg-white", endIcon: pro_light_svg_icons_1.faSearch, size: "small", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, dispatch((0, product_1.setTablePagination)(pages_1.initialPaginationValues))];
                                case 1:
                                    _a.sent();
                                    setSearch(e);
                                    return [2 /*return*/];
                            }
                        });
                    }); } })),
            react_1.default.createElement(doshx_controls_web_1.TableControl, { rowSelections: selectedProducts, onSelectionChange: onSelectionChange, columns: exports.excludeProductsTableColumns, data: productOptions, pagination: {
                    rowsPerPageOptions: [10, 15, 20],
                    currentPage: currentPage,
                    rowsPerPage: rowsPerPage,
                    totalRecords: pagination.countTotal,
                    onPageChange: onPageChange,
                    onRowsPerPageChange: onRowsPerPageChange,
                }, hasShadow: true, colors: pages_1.tableColorConfig })),
        selectedProducts && selectedProducts.length > 0 && (react_1.default.createElement("div", { className: "flex gap-4 fixed bottom-8 right-8" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Clear ".concat(selectedProducts.length, " Selected Products"), endIcon: pro_light_svg_icons_1.faTimes, size: "small", backgroundColor: "bg-borders", textColor: "text-black", iconColor: "text-black", onClick: function () {
                    setSelectedProducts([]);
                } })))));
};
exports.MenuConceptDetailsPage = MenuConceptDetailsPage;
