"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasketTable = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var doshx_controls_web_2 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var routes_1 = require("../../../../../constants/routes");
var basket_1 = require("../../../../../reducers/slices/basket/basket");
var pages_1 = require("../../../../../constants/pages");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var client_basket_list_thunk_1 = require("../../../../../reducers/slices/basket/thunks/client_basket_list_thunk");
var branch_list_thunk_1 = require("../../../../../reducers/slices/branch/thunks/get/branch_list_thunk");
var cancel_basket_thunk_1 = require("../../../../../reducers/slices/basket/thunks/cancel_basket_thunk");
var useSkipFirstRender_1 = require("../../../../../hooks/useSkipFirstRender");
var constants_1 = require("../../../constants");
var BasketTable = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), search = _a[0], setSearch = _a[1];
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.basketState; }), baskets = _b.baskets, basketPagination = _b.basketPagination, basketStatus = _b.basketStatus, basketType = _b.basketType, isLoadingBasket = _b.isLoadingBasket;
    var branches = (0, react_redux_1.useSelector)(function (state) { return state.branchState; }).branches;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _c = (0, react_1.useState)(false), showFilters = _c[0], setShowFilters = _c[1];
    var _d = (0, react_1.useState)(""), minValue = _d[0], setMinValue = _d[1];
    var _e = (0, react_1.useState)(""), maxValue = _e[0], setMaxValue = _e[1];
    var _f = (0, react_1.useState)(""), fromDate = _f[0], setFromDate = _f[1];
    var _g = (0, react_1.useState)(""), toDate = _g[0], setToDate = _g[1];
    var _h = (0, react_1.useState)(pages_1.initialSortValues), sorter = _h[0], setSorter = _h[1];
    var _j = (0, react_1.useState)(""), selectedBranch = _j[0], setSelectedBranch = _j[1];
    var _k = (0, react_1.useState)(false), hasBranches = _k[0], setHasBranches = _k[1];
    var _l = (0, react_1.useState)(1), currentPage = _l[0], setCurrentPage = _l[1];
    var _m = (0, react_1.useState)(10), rowsPerPage = _m[0], setRowsPerPage = _m[1];
    var _o = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _o.client_token, user = _o.user;
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, client_basket_list_thunk_1.client_basket_list_thunk)(__assign(__assign({}, basketPagination), { branch_id_list: (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" ? user === null || user === void 0 ? void 0 : user.manager_of : null })));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if ((client === null || client === void 0 ? void 0 : client._id) && !(branches === null || branches === void 0 ? void 0 : branches.length) && !hasBranches) {
            dispatch((0, branch_list_thunk_1.branch_list_thunk)(__assign({}, basketPagination)));
            setHasBranches(true);
        }
    }, [branches, client === null || client === void 0 ? void 0 : client._id, hasBranches]);
    (0, useSkipFirstRender_1.useSkipFirstRender)(function () {
        if (search) {
            onSearchClientBaskets(search);
        }
        else {
            getClientBaskets(__assign(__assign({}, basketPagination), { branch_id_list: (user === null || user === void 0 ? void 0 : user.role) !== "ADMIN" ? user === null || user === void 0 ? void 0 : user.manager_of : null }));
        }
    }, [search]);
    var getClientBaskets = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, client_basket_list_thunk_1.client_basket_list_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchClientBaskets = function (search_string) { return getClientBaskets(__assign(__assign({}, basketPagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" })); };
    var onPageChange = function (page) {
        setCurrentPage(page);
        if (search === "") {
            setRowsPerPage(rowsPerPage);
            return getClientBaskets(__assign(__assign({}, basketPagination), { page: page }));
        }
        else {
            setRowsPerPage(rowsPerPage);
            return getClientBaskets(__assign(__assign({}, basketPagination), { page: page, search_string: search }));
        }
    };
    var onRowsPerPageChange = function (size) {
        setRowsPerPage(size);
        return getClientBaskets(__assign(__assign({}, basketPagination), { size: size }));
    };
    var statusValues = [
        {
            name: "All",
            value: "ALL",
        },
        {
            name: "Active",
            value: "ACTIVE",
        },
        {
            name: "Cancelled",
            value: "CANCELLED",
        },
        {
            name: "Checked Out",
            value: "CHECKED-OUT",
        },
        {
            name: "Abandoned",
            value: "ABANDONED",
        },
    ];
    var deliveryMethods = [
        {
            name: "Both",
            value: "BOTH",
        },
        {
            name: "Collection",
            value: "COLLECTION",
        },
        {
            name: "Delivery",
            value: "DELIVERY",
        },
    ];
    var onSortTable = function (sort) {
        setSorter(sort);
        getClientBaskets({ sort_by: sort.key, order_by: sort.direction });
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
        react_1.default.createElement("div", { className: "flex justify-between" },
            react_1.default.createElement("div", { className: "flex justify-start gap-2 items-center" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } }),
                showFilters && (react_1.default.createElement("div", { className: "w-32 bg-white rounded-md" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Status", size: "small", borderColor: "border-white", options: statusValues.map(function (status) {
                            return {
                                label: status.name,
                                value: status.value,
                            };
                        }), value: basketStatus, onChange: function (v) {
                            console.log("The drowpdown value is", v);
                            dispatch((0, basket_1.setBasketBranch)(""));
                            dispatch((0, basket_1.setBasketStatus)(v));
                        } }))),
                showFilters && (react_1.default.createElement("div", { className: "w-32 bg-white rounded-md" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Delivery Method", size: "small", borderColor: "border-white", options: deliveryMethods.map(function (method) {
                            return {
                                label: method.name,
                                value: method.value,
                            };
                        }), value: basketType, onChange: function (v) {
                            dispatch((0, basket_1.setBasketBranch)(""));
                            dispatch((0, basket_1.setBasketType)(v));
                        } }))),
                showFilters && (react_1.default.createElement("div", { className: "w-32 bg-white rounded-md" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Branch", size: "small", borderColor: "border-white", options: branches === null || branches === void 0 ? void 0 : branches.map(function (branch) {
                            return {
                                label: branch.display_name,
                                value: branch === null || branch === void 0 ? void 0 : branch._id,
                            };
                        }), value: selectedBranch, onChange: function (v) {
                            setSelectedBranch(v);
                            dispatch((0, basket_1.setBasketStatus)(""));
                            dispatch((0, basket_1.setBasketType)(""));
                            dispatch((0, basket_1.setBasketBranch)(v));
                        } }))),
                showFilters && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", { className: "w-32 bg-white rounded-md py-2 text-center cursor-pointer" },
                        react_1.default.createElement(doshx_controls_web_1.PopoverControl, { content: react_1.default.createElement("div", { className: "flex flex-col gap-4 bg-white border-borders rounded-md" },
                                react_1.default.createElement("div", { className: "" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Min Amount", size: "small", type: "number", borderColor: "border-transaparent", value: minValue, onChange: function (e) {
                                            setMinValue(e);
                                        } })),
                                react_1.default.createElement("div", { className: "" },
                                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Max Amount", size: "small", type: "number", borderColor: "border-transaparent", value: maxValue, onChange: function (e) {
                                            setMaxValue(e);
                                        } })),
                                react_1.default.createElement("div", { className: "flex justify-end" },
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: function () {
                                            dispatch((0, basket_1.setBasketFilterdByTotal)({
                                                minValue: minValue,
                                                maxValue: maxValue,
                                                page: basketPagination.page,
                                                size: basketPagination.size,
                                            }));
                                            setMinValue("");
                                            setMaxValue("");
                                            dispatch((0, basket_1.setBasketBranch)(""));
                                        } }))), behavior: "click" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Amount Filter" }))),
                    react_1.default.createElement("div", { className: "w-32 bg-white rounded-md py-2 text-center cursor-pointer" },
                        react_1.default.createElement(doshx_controls_web_1.PopoverControl, { content: react_1.default.createElement("div", { className: "flex flex-col gap-4 bg-white border-borders rounded-md" },
                                react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "From Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: fromDate, onChange: function (v) {
                                        setFromDate(doshx_controls_web_2.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                    }, size: "small" }),
                                react_1.default.createElement(doshx_controls_web_1.DatePickerControl, { label: "To Date", errorText: "", errorTextColor: "text-red-400", backgroundColor: "bg-gray", borderColor: "border-transaparent", value: toDate, onChange: function (v) {
                                        setToDate(doshx_controls_web_2.Utilities.formatDateTime(v, "YYYY-MM-DD"));
                                    }, size: "small" }),
                                react_1.default.createElement("div", { className: "flex justify-end" },
                                    react_1.default.createElement(doshx_controls_web_1.LinkButtonControl, { label: "apply", size: "xsmall", onClick: function () {
                                            dispatch((0, basket_1.setBasketFilterRange)({
                                                fromDate: fromDate,
                                                toDate: toDate,
                                                page: basketPagination.page,
                                                size: basketPagination.size,
                                            }));
                                            setFromDate("");
                                            setToDate("");
                                            dispatch((0, basket_1.setBasketBranch)(""));
                                        } }))), behavior: "click" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Date Filter" })))))),
            react_1.default.createElement("div", { className: "flex gap-2" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Create Basket" //
                    , endIcon: pro_solid_svg_icons_1.faPlus, backgroundColor: "bg-success", size: "small", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BASKETS_ROUTE).concat(routes_1.CREATE_BASKET));
                    } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Search", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", borderColor: "border-borders", backgroundColor: "bg-white", onChange: function (e) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, dispatch((0, basket_1.setBasketTablePagination)(pages_1.initialPaginationValues))];
                                case 1:
                                    _a.sent();
                                    setSearch(e);
                                    return [2 /*return*/];
                            }
                        });
                    }); } }))),
        isLoadingBasket ? (react_1.default.createElement("div", { className: "grid justify-center p-4" },
            react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl //
            , { size: "large" }))) : (react_1.default.createElement(doshx_controls_web_1.TableControl, { sort: {
                value: sorter,
                onChange: onSortTable,
            }, columns: [
                {
                    displayKey: "user.name",
                    label: "Customer",
                    formatter: function (basket) {
                        var _a, _b;
                        var customerName = "";
                        if (basket) {
                            customerName =
                                (basket === null || basket === void 0 ? void 0 : basket.user) && Object.keys(basket === null || basket === void 0 ? void 0 : basket.user).length > 0
                                    ? "".concat((_a = basket === null || basket === void 0 ? void 0 : basket.user) === null || _a === void 0 ? void 0 : _a.name, " ").concat((_b = basket === null || basket === void 0 ? void 0 : basket.user) === null || _b === void 0 ? void 0 : _b.surname)
                                    : (basket === null || basket === void 0 ? void 0 : basket.user) && Object.keys(basket === null || basket === void 0 ? void 0 : basket.user).length === 0
                                        ? "".concat(basket.name, " ").concat(basket.surname)
                                        : "Guest User";
                            return customerName;
                        }
                        return customerName;
                    },
                },
                {
                    displayKey: "user.email",
                    label: "Email",
                    formatter: function (basket) {
                        var _a;
                        if (basket) {
                            return (_a = basket === null || basket === void 0 ? void 0 : basket.user) === null || _a === void 0 ? void 0 : _a.email;
                        }
                        return "";
                    },
                },
                {
                    displayKey: "user.cell_number",
                    label: "Phone Number",
                    formatter: function (basket) {
                        var _a;
                        if (basket) {
                            return (_a = basket === null || basket === void 0 ? void 0 : basket.user) === null || _a === void 0 ? void 0 : _a.cell_number;
                        }
                        return "";
                    },
                },
                {
                    displayKey: "createdAt",
                    label: "Date",
                    formatter: function (basket) {
                        if (basket) {
                            return doshx_controls_web_2.Utilities.formatDateTime(basket === null || basket === void 0 ? void 0 : basket.createdAt, "YYYY-MM-DD");
                        }
                        else {
                            return "";
                        }
                    },
                },
                {
                    displayKey: "total",
                    label: "Amount",
                    formatter: function (o) {
                        if (o) {
                            return doshx_controls_web_2.Utilities.formatMoney({
                                symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                                value: o.total.toString(),
                                symbolSeparation: true,
                            });
                        }
                        return "0.00";
                    },
                },
                // {
                //   displayKey: "items",
                //   label: "Items",
                //   formatter: (o: IBasket) => {
                //     let totalNumberOfProducts: number = 0;
                //     if (o) {
                //       totalNumberOfProducts = o.product_list.length;
                //       return totalNumberOfProducts.toString();
                //     }
                //     return totalNumberOfProducts.toString();
                //   },
                // },
                {
                    displayKey: "branch",
                    label: "Branch",
                    formatter: function (o) {
                        var _a;
                        var branchName = "";
                        if (o) {
                            branchName = (_a = o.branch) === null || _a === void 0 ? void 0 : _a.display_name;
                            return branchName;
                        }
                        return branchName;
                    },
                },
                {
                    displayKey: "type",
                    label: "Method",
                    formatter: function (o) {
                        var deliveryMethod = "";
                        if (o) {
                            deliveryMethod = o.type.toUpperCase();
                            return deliveryMethod;
                        }
                        return deliveryMethod;
                    },
                },
                {
                    displayKey: "status",
                    label: "Status",
                    formatter: function (o) {
                        if (o) {
                            return (0, constants_1.TABLE_STATUSES)(o.status);
                        }
                    },
                },
            ], data: baskets !== null && baskets !== void 0 ? baskets : [], 
            // onRowClicked={(data) => {
            //   navigate(`${CONSOLE_ROUTE}${BASKETS_ROUTE}${VIEW_BASKET_ROUTE}/${data._id}/${data.user_id}`);
            // }}
            rowActions: [
                {
                    label: "View",
                    onClick: function (data) {
                        console.log("View", data);
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.BASKETS_ROUTE).concat(routes_1.VIEW_BASKET_ROUTE, "/").concat(data._id, "/").concat(data.user_id));
                    },
                },
                {
                    label: "Cancel",
                    onClick: function (data) {
                        dispatch((0, cancel_basket_thunk_1.cancel_basket_thunk)({ payload: { _id: data === null || data === void 0 ? void 0 : data._id } }));
                    },
                },
            ], pagination: {
                rowsPerPageOptions: [10, 15, 20],
                currentPage: currentPage,
                rowsPerPage: rowsPerPage,
                totalRecords: basketPagination.countTotal,
                onPageChange: onPageChange,
                onRowsPerPageChange: onRowsPerPageChange,
            }, colors: pages_1.tableColorConfig }))));
};
exports.BasketTable = BasketTable;
