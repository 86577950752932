"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateClientPage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var company_details_1 = require("./tabs/company_details");
var company_information_1 = require("./tabs/company_information");
var client_details_1 = require("./tabs/client_details");
var offering_list_1 = require("../../../../api/calls/client/offering_list");
var registration_1 = require("../../../../reducers/slices/registration");
var CreateClientPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var entryTheme = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).entryTheme;
    var currentStep = (0, react_redux_1.useSelector)(function (state) { return state.entryState; }).currentStep;
    (0, react_1.useEffect)(function () {
        (0, offering_list_1.offering_list)().then(function (response) {
            dispatch((0, registration_1.setOfferings)(response.content[0]));
        });
    }, []);
    return (react_1.default.createElement("div", { className: "w-full flex flex-col h-full justify-center overflow-y-auto " },
        react_1.default.createElement("div", { className: "overflow-y-auto overflow-x-hidden  px-8" },
            react_1.default.createElement(doshx_controls_web_1.StepperControl
            // showDefaultButtons={true}
            , { 
                // showDefaultButtons={true}
                orientation: "horizontal", currentStep: currentStep, stepPillBackgroundColor: entryTheme.config.colors.buttons.bgPrimary, steps: [
                    // { label: "Test Stripe", component: <PaymentPlanPage /> },
                    { label: "Create Account", component: react_1.default.createElement(client_details_1.ClientDetailsPage, null) },
                    { label: "Company Details", component: react_1.default.createElement(company_details_1.CompanyDetailsPage, null) },
                    { label: "Company Information", component: react_1.default.createElement(company_information_1.CompanyInformationPage, null) },
                    // { label: "Payment Plan", component: <PaymentPlanPage /> },
                ] }))));
};
exports.CreateClientPage = CreateClientPage;
