"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WaybillDetailsPage = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../reducers/slices/console");
var parcels_table_1 = require("./components/parcels_table");
var waybill_timeline_1 = require("./components/waybill_timeline");
var WaybillDetailsPage = function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var waybill_id = (0, react_router_dom_1.useParams)().waybill_id;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _o = (0, react_1.useState)(), waybill = _o[0], setWaybill = _o[1];
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _p = (0, react_redux_1.useSelector)(function (state) { return state.waybillState; }), waybills = _p.waybills, pagination = _p.pagination, loading = _p.loading;
    (0, react_1.useEffect)(function () {
        var _a;
        dispatch((0, console_1.setPageTitle)("Waybill: ".concat((_a = waybill === null || waybill === void 0 ? void 0 : waybill.waybill) !== null && _a !== void 0 ? _a : '')));
    }, [waybill]);
    (0, react_1.useEffect)(function () {
        var _waybill = waybills.find(function (x) { return x._id === waybill_id; });
        if (_waybill) {
            setWaybill(_waybill);
        }
    }, [waybills]);
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { borderColor: "border-transparent", addPadding: false, className: "w-full" },
        react_1.default.createElement("div", { className: " flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-2 w-full" },
                react_1.default.createElement("div", { className: "flex flex-row" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back to waybills", size: "xsmall", startIcon: pro_solid_svg_icons_1.faChevronLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                            navigate(-1);
                        } }))),
            react_1.default.createElement("div", { className: "grid grid-cols-1 lg:grid-cols-4 gap-4 items-start justify-start" },
                react_1.default.createElement("div", { className: "flex flex-col gap-4 justify-center lg:col-span-3" },
                    react_1.default.createElement(parcels_table_1.ParcelsTablesComponent, { parcels: waybill === null || waybill === void 0 ? void 0 : waybill.parcel_list }),
                    react_1.default.createElement(waybill_timeline_1.WaybillTimeline, { waybillID: waybill === null || waybill === void 0 ? void 0 : waybill._id, comments: waybill === null || waybill === void 0 ? void 0 : waybill.comments })),
                react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-4" },
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("div", { className: "flex-1" },
                                react_1.default.createElement("div", { className: "flex items-center" },
                                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faMapMarker, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Collection address", size: "small", textColor: "text-black", bold: true })))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_a = waybill === null || waybill === void 0 ? void 0 : waybill.collection_address) === null || _a === void 0 ? void 0 : _a.full_address, size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Latitude ".concat((_b = waybill === null || waybill === void 0 ? void 0 : waybill.collection_address) === null || _b === void 0 ? void 0 : _b.lat), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Longitude ".concat((_c = waybill === null || waybill === void 0 ? void 0 : waybill.collection_address) === null || _c === void 0 ? void 0 : _c.lon), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Pick up ".concat((_e = (_d = waybill === null || waybill === void 0 ? void 0 : waybill.collection_date) === null || _d === void 0 ? void 0 : _d.actual) !== null && _e !== void 0 ? _e : (_f = waybill === null || waybill === void 0 ? void 0 : waybill.collection_date) === null || _f === void 0 ? void 0 : _f.specified, " ").concat((_h = (_g = waybill === null || waybill === void 0 ? void 0 : waybill.collection_date) === null || _g === void 0 ? void 0 : _g.actual) !== null && _h !== void 0 ? _h : (_j = waybill === null || waybill === void 0 ? void 0 : waybill.collection_date) === null || _j === void 0 ? void 0 : _j.specified), size: "xsmall", textColor: "text-black" })))),
                    react_1.default.createElement("div", null,
                        react_1.default.createElement("div", { className: "flex items-center" },
                            react_1.default.createElement("div", { className: "flex-1" },
                                react_1.default.createElement("div", { className: "flex items-center" },
                                    react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faMapMarker, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Delivery address", size: "small", textColor: "text-black", bold: true })))),
                        react_1.default.createElement("div", { className: "" },
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_k = waybill === null || waybill === void 0 ? void 0 : waybill.delivery_address) === null || _k === void 0 ? void 0 : _k.full_address, size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Latitude ".concat((_l = waybill === null || waybill === void 0 ? void 0 : waybill.delivery_address) === null || _l === void 0 ? void 0 : _l.lat), size: "xsmall", textColor: "text-black" })),
                            react_1.default.createElement("div", { className: "flex items-center" },
                                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { borderColor: "border-transparent", icon: pro_solid_svg_icons_1.faArrowRight, onClick: function () { }, backgroundColor: "bg-transparent", iconColor: "text-black", size: "xsmall" }),
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Longitude ".concat((_m = waybill === null || waybill === void 0 ? void 0 : waybill.delivery_address) === null || _m === void 0 ? void 0 : _m.lon), size: "xsmall", textColor: "text-black" })))))))));
};
exports.WaybillDetailsPage = WaybillDetailsPage;
