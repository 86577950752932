"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UberStoreListComponent = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var main_1 = require("../../../../../../reducers/slices/main");
var react_router_dom_1 = require("react-router-dom");
var uber_integrated_1 = require("./uber_integrated");
var get_store_list_1 = require("../../../../../../api/calls/integration/uber/get_store_list");
var UberStoreListComponent = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.channelState; }), loading = _a.loading, channels = _a.channels;
    var _b = (0, react_router_dom_1.useSearchParams)(), searchParams = _b[0], setSearchParams = _b[1];
    var _c = (0, react_1.useState)([]), stores = _c[0], setStores = _c[1];
    (0, react_1.useEffect)(function () {
        getStoreList();
        integrationRedirect();
    }, []);
    var integrationRedirect = function () {
        if (!searchParams.get("code"))
            return;
        dispatch((0, main_1.setContentModal)({
            title: "Creating a Uber integration...",
            content: react_1.default.createElement(uber_integrated_1.UberIntegrated, null),
        }));
    };
    var getStoreList = function () {
        (0, get_store_list_1.get_store_list)().then(function (res) { });
    };
    return loading ? (react_1.default.createElement("div", { className: "grid justify-center p-4" },
        react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl //
        , { size: "xlarge" }))) : (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "xs:w-auto text-center" },
            react_1.default.createElement("div", { className: "flex" },
                react_1.default.createElement("div", { className: "mr-4" }),
                react_1.default.createElement("div", { className: "flex-1 flex-col gap-1 text-start" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Connect Uber", bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "The list of stores you have with uber will appear here." })))),
        react_1.default.createElement("div", { className: "flex justify-between" },
            react_1.default.createElement("div", { className: "flex justify-start" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", label: "Back", size: "small", startIcon: pro_light_svg_icons_1.faArrowLeft, backgroundColor: "bg-transparent", textColor: "text-black", onClick: function () {
                        navigate(-1);
                    } }))),
        react_1.default.createElement("div", { className: "grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4" }, stores.map(function (x) { return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", variant: "filled", className: "flex flex-col gap-4" },
            react_1.default.createElement("div", { className: "flex justify-between gap-4" },
                react_1.default.createElement("div", { className: "flex-1" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(x === null || x === void 0 ? void 0 : x.display_name) })),
                react_1.default.createElement("div", { className: "" },
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { disabled: false, value: true, onChange: function (v) {
                            //   onChange("is_default", v);
                        }, textColor: "text-primary", size: "large" }))),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "By connecting your uber account, you agree that your products, customers and/or orders will be visible and manageable on the Letstrade platform.", size: "xsmall", center: true }))); })),
        react_1.default.createElement("div", { className: "flex justify-end" },
            react_1.default.createElement("div", { className: "flex justify-end items-center" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Push to uber", endIcon: pro_light_svg_icons_1.faPaperPlane, size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { } })))));
};
exports.UberStoreListComponent = UberStoreListComponent;
