"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var sanitiseObject_1 = require("../../../../../../helpers/sanitiseObject");
var create_channel_thunk_1 = require("../../../../../../reducers/slices/channels/thunks/create_channel_thunk");
var local_storage_names_1 = require("../../../../../../constants/local_storage_names");
var AddShopifyStore = function () {
    var _a = (0, react_1.useState)({
        url: "",
        api_key: "",
        api_secret: "",
        access_token: "",
    }), connDetails = _a[0], setConnDetails = _a[1];
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_1.useState)({ read: false, write: false }), productAccess = _b[0], setProductAccess = _b[1];
    var _c = (0, react_1.useState)({ read: false, write: false }), customerAccess = _c[0], setCustomerAccess = _c[1];
    var _d = (0, react_1.useState)({ read: false, write: false }), orderAccess = _d[0], setOrderAccess = _d[1];
    var _e = (0, react_1.useState)(false), customApp = _e[0], setCustomApp = _e[1];
    var _f = (0, react_1.useState)({
        url: "",
        api_key: "",
        api_secret: "",
        access_token: "",
    }), errorText = _f[0], setErrorText = _f[1];
    var validate = function () {
        var sanitisedConnDetails = (0, sanitiseObject_1.sanitiseObject)(connDetails);
        var validation = {
            url: !sanitisedConnDetails.url ? "Please enter a valid url" : "",
            api_key: !sanitisedConnDetails.api_key && customApp ? "Please enter api key" : "",
            api_secret: !sanitisedConnDetails.api_secret && customApp ? "Please enter api secret" : "",
            access_token: !sanitisedConnDetails.access_token && customApp ? "Please enter access token" : "",
        };
        setErrorText(validation);
        if (validation.url || validation.access_token)
            return false;
        return true;
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4 max-w-md" },
        react_1.default.createElement("div", { className: "gap-0" },
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "URL", type: "text", borderColor: "border-borders", required: true, errorText: errorText.url, onChange: function (url) { return setConnDetails(__assign(__assign({}, connDetails), { url: url })); } }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. https://letstradestore.myshopify.com", size: "xsmall" }))),
        react_1.default.createElement("div", { className: "flex flex-col px-4 gap-4 border-borders border-radius-2" },
            react_1.default.createElement("div", { className: "flex flex-col lg:flex-row items-center justify-between" },
                react_1.default.createElement("div", { className: "flex justify-start" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Connection details", bold: true, textColor: "text-black" })),
                react_1.default.createElement("div", { className: "flex justify-end" },
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Custom App", value: customApp, onChange: function (v) { return setCustomApp(v); } }))),
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Access Token", type: "password", borderColor: "border-borders", textColor: customApp ? "text-black" : "text-disabled", labelColor: customApp ? "text-black" : "text-disabled", disabled: !customApp, required: customApp, value: connDetails.access_token, errorText: errorText.access_token, onChange: function (access_token) { return setConnDetails(__assign(__assign({}, connDetails), { access_token: access_token })); } }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. shpua_9875778ba987987af9879898a7", size: "xsmall" })),
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "API Key", type: "text", borderColor: "border-borders", textColor: customApp ? "text-black" : "text-disabled", labelColor: customApp ? "text-black" : "text-disabled", disabled: !customApp, required: customApp, value: connDetails.api_key, errorText: errorText.api_key, onChange: function (api_key) { return setConnDetails(__assign(__assign({}, connDetails), { api_key: api_key })); } }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. 0987d09875778ba987987af9879898a7", size: "xsmall" })),
            react_1.default.createElement("div", { className: "flex flex-col" },
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "API Secret", type: "password", borderColor: "border-borders", textColor: customApp ? "text-black" : "text-disabled", labelColor: customApp ? "text-black" : "text-disabled", disabled: !customApp, required: customApp, value: connDetails.api_secret, errorText: errorText.api_secret, onChange: function (api_secret) { return setConnDetails(__assign(__assign({}, connDetails), { api_secret: api_secret })); } }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "eg. 09d67b7655e6f66565ef714ae98757b9", size: "xsmall" })),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Enabled Permissions", bold: true, textColor: "text-black", className: "pt-2" }),
            customApp && (react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Please only select permissions within the scopes selected during creation of the Custom App on Shopify.", textColor: "text-black", size: "small" })),
            react_1.default.createElement("div", { className: "flex gap-8 px-4" },
                react_1.default.createElement("div", { className: "flex-1 flex-col lg:flex-row gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Products", bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Read", value: productAccess.read, onChange: function (read) { return setProductAccess(function (prevState) { return (__assign(__assign({}, prevState), { read: read })); }); } }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Write", value: productAccess.write, onChange: function (write) { return setProductAccess(function (prevState) { return (__assign(__assign({}, prevState), { write: write, read: write })); }); } })),
                react_1.default.createElement("div", { className: "flex-1 flex-col lg:flex-row gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Customers", bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Read", value: customerAccess.read, onChange: function (read) { return setCustomerAccess(function (prevState) { return (__assign(__assign({}, prevState), { read: read })); }); } }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Write", value: customerAccess.write, onChange: function (write) { return setCustomerAccess(function (prevState) { return (__assign(__assign({}, prevState), { write: write, read: write })); }); } })),
                react_1.default.createElement("div", { className: "flex-1 flex-col lg:flex-row gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Orders", bold: true, textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Read", value: orderAccess.read, onChange: function (read) { return setOrderAccess(function (prevState) { return (__assign(__assign({}, prevState), { read: read })); }); } }),
                    react_1.default.createElement(doshx_controls_web_1.CheckboxControl, { label: "Write", value: orderAccess.write, onChange: function (write) { return setOrderAccess(function (prevState) { return (__assign(__assign({}, prevState), { write: write, read: write })); }); } })))),
        react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement("div", { className: "w-40" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Connect", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var stateString, redirectTo, permissions;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!validate())
                                        return [2 /*return*/];
                                    if (!!customApp) return [3 /*break*/, 1];
                                    stateString = doshx_controls_web_1.Utilities.randomAlphanumeric();
                                    redirectTo = "".concat(process.env.LETS_TRADE_WEB_URL, "/shopify-redirect");
                                    permissions = [];
                                    if (productAccess.write) {
                                        permissions.push("write_products", "read_products");
                                    }
                                    else if (productAccess.read) {
                                        permissions.push("read_products");
                                    }
                                    if (customerAccess.write) {
                                        permissions.push("write_customers", "read_customers");
                                    }
                                    else if (customerAccess.read) {
                                        permissions.push("read_customers");
                                    }
                                    if (orderAccess.write) {
                                        permissions.push("write_orders", "read_orders");
                                    }
                                    else if (orderAccess.read) {
                                        permissions.push("read_orders");
                                    }
                                    // const productAccessPermissions = ["write_products", "read_products"];
                                    // permissions.push(...(productAccess.write ? productAccessPermissions : productAccess.read ? ["read_products"] : []));
                                    // const customerAccessPermissions = ["write_customers", "read_customers"];
                                    // permissions.push(...(customerAccess.write ? customerAccessPermissions : customerAccess.read ? ["read_customers"] : []));
                                    // const orderAccessPermissions = ["write_orders", "read_orders"];
                                    // permissions.push(...(orderAccess.write ? orderAccessPermissions : orderAccess.read ? ["read_orders"] : []));
                                    // TODO: call api
                                    localStorage.setItem(local_storage_names_1.NEW_CHANNEL_LOCAL_STORAGE, JSON.stringify({
                                        type: "shopify",
                                        url: connDetails.url,
                                        permissions: {
                                            products: productAccess,
                                            customers: customerAccess,
                                            orders: orderAccess,
                                        },
                                    }));
                                    location.href = "".concat(connDetails.url, "/admin/oauth/authorize?client_id=").concat(process.env.SHOPIFY_CLIENT_ID, "&redirect_uri=").concat(redirectTo, "&state=").concat(stateString, "&scope=").concat(permissions);
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, dispatch((0, create_channel_thunk_1.create_channel_thunk)(__assign(__assign({ type: "shopify" }, connDetails), { permissions: {
                                            products: productAccess,
                                            customers: customerAccess,
                                            orders: orderAccess,
                                        } })))];
                                case 2:
                                    _a.sent();
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); } }))),
        react_1.default.createElement("div", { className: "px-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "By connecting your Shopify account, you agree that your products, customers and/or orders will be visible and manageable on the Letstrade platform.", size: "xsmall", center: true }))));
};
exports.default = AddShopifyStore;
