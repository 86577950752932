"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConnectedChannelsSelectModal = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var get_channels_thunk_1 = require("../../../../../../reducers/slices/channels/thunks/get_channels_thunk");
var main_1 = require("../../../../../../reducers/slices/main");
var ConnectedChannelsSelectModal = function (_a) {
    var channel_type = _a.channel_type, connectedChannels = _a.connectedChannels, onConfirm = _a.onConfirm;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.channelState; }), channels = _b.channels, loading = _b.loading;
    var _c = (0, react_1.useState)([]), selectedChannels = _c[0], setSelectedChannels = _c[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, get_channels_thunk_1.get_channels_thunk)({ type: channel_type }));
    }, []);
    var filteredChannels = channels.filter(function (channel) { return !connectedChannels.find(function (connectedChannel) { return connectedChannel.channel_id === channel._id; }); });
    var allSelected = connectedChannels.length === (channels === null || channels === void 0 ? void 0 : channels.length) && filteredChannels.length === 0;
    var channelOptions = filteredChannels
        .sort(function (a, b) { return a.type.localeCompare(b.type); })
        .map(function (channel) { return ({ channel_id: channel._id, name: channel.name, channel: channel.type.toUpperCase() }); });
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "flex flex-col gap-6 justify-between items-center max-w-md" },
        allSelected && react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "No more channels available to add to this product.", textColor: "text-inputText" }),
        loading ? (react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "large" })) : (react_1.default.createElement("div", { className: "w-[250px]" },
            react_1.default.createElement(doshx_controls_web_1.DropdownMultiControl, { label: "Channel", options: channelOptions !== null && channelOptions !== void 0 ? channelOptions : [], renderDisplay: function (channel) { return channel === null || channel === void 0 ? void 0 : channel.name; }, 
                // selectedValues={channelOptions?.filter((channel) => selectedChannels?.some((selectedChannel) => selectedChannel.channel_id === channel.channel_id)) ?? []}
                selectedValues: selectedChannels, colors: {
                    borderColor: "border-borders",
                    textColor: "text-inputText",
                    iconColor: "text-inputText",
                }, onSelectionChanged: setSelectedChannels }))),
        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Adding channels to this product will sync this product across the selected channels upon saving this product.", textColor: "text-inputText" }),
        channel_type === "shopify" && react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Due to constraints in Shopify, upon.", textColor: "text-inputText" }),
        react_1.default.createElement("div", { className: "flex flex-row gap-6 justify-center items-center" },
            react_1.default.createElement("div", { className: "w-[150px]" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", onClick: function () { return (0, main_1.setContentModal)(null); }, borderColor: "border-primary", backgroundColor: "bg-transparent", textColor: "text-primary" })),
            react_1.default.createElement("div", { className: "w-[150px]" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Confirm", onClick: function () {
                        onConfirm(selectedChannels);
                        (0, main_1.setContentModal)(null);
                    }, backgroundColor: "bg-primary", textColor: "text-white" })))));
};
exports.ConnectedChannelsSelectModal = ConnectedChannelsSelectModal;
