"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var constants_1 = require("./constants");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
exports.default = (function () {
    var _a = (0, react_1.useState)(false), sidebarCollapsed = _a[0], setSidebarCollapsed = _a[1];
    var toggleCollapse = function () { return setSidebarCollapsed(!sidebarCollapsed); };
    var NodeComponents = function () { return (react_1.default.createElement(react_1.default.Fragment, null, Object.entries(constants_1.nodeConfigurations)
        .map(function (_a, index) {
        var category = _a[0], typeConfigs = _a[1];
        if (!typeConfigs) {
            return null;
        }
        var nodes = typeConfigs
            .sort(function (a, b) { return (a.data.name > b.data.name ? 1 : -1); })
            .map(function (value, subIndex) {
            var type = value.type, data = value.data;
            var NodeTypeComponent = constants_1.nodeTypes[type];
            if (!NodeTypeComponent) {
                return null;
            }
            return react_1.default.createElement(NodeTypeComponent, { draggable: true, key: "".concat(category, "-").concat(type, "-").concat(index * 100 + subIndex), type: type, category: category, data: data });
        })
            .filter(Boolean);
        if (nodes.length === 0) {
            return null;
        }
        return index === 0 ? nodes : __spreadArray([react_1.default.createElement(doshx_controls_web_1.DividerControl, { key: "divider-".concat(index), lineColor: "bg-borders" })], nodes, true);
    })
        .flat())); };
    return (react_1.default.createElement("div", { className: "flex flex-col items-start gap-2" },
        react_1.default.createElement("div", { className: "flex ".concat(sidebarCollapsed ? "flex-col gap-4" : "row justify-between min-w-[250px]", " max-w-[400px]") },
            !sidebarCollapsed && react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "You can drag these nodes into the editor on the left.", size: "medium", textColor: "text-gray-500" }),
            react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: sidebarCollapsed ? pro_light_svg_icons_1.faPlus : pro_light_svg_icons_1.faMinus, iconColor: sidebarCollapsed ? "text-white" : "text-black", backgroundColor: sidebarCollapsed ? "bg-primary hover:bg-blue-500" : "bg-gray-100 hover:bg-blue-300", borderColor: "border-transparent", onClick: toggleCollapse, size: "2xsmall" })),
        !sidebarCollapsed && (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-blue-50", borderColor: "border-transparent", className: "flex flex-col h-full w-full overflow-scroll shadow-inner rounded-lg p-2 gap-2", addPadding: false },
            react_1.default.createElement(NodeComponents, null)))));
});
