"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var doshx_controls_web_1 = require("doshx_controls_web");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var stock_replacement_1 = require("../../../../reducers/slices/stockReplacement/stock_replacement");
var main_1 = require("../../../../reducers/slices/main");
var DataMapper = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var webhook = (0, react_redux_1.useSelector)(function (state) { return state.stockReplacementState; }).webhook;
    var dataMapCopy = ((_a = webhook.data_map) === null || _a === void 0 ? void 0 : _a.length) > 0 ? webhook.data_map.map(function (i) { return (__assign({}, i)); }) : null;
    var _b = (0, react_1.useState)(dataMapCopy !== null && dataMapCopy !== void 0 ? dataMapCopy : [
        {
            localField: "",
            targetField: "",
            targetType: "String",
            defaultValue: "",
            nestedFields: [],
        },
    ]), fields = _b[0], setFields = _b[1];
    var addField = function () {
        var previousField = fields[fields.length - 1];
        if (fields.length > 0)
            validateField(previousField);
        setFields(__spreadArray(__spreadArray([], fields, true), [
            {
                localField: "",
                targetField: "",
                targetType: "String",
                defaultValue: "",
                nestedFields: [],
            },
        ], false));
    };
    var removeField = function (index) {
        var updatedFields = __spreadArray([], fields, true);
        updatedFields.splice(index, 1);
        setFields(updatedFields);
    };
    var updateField = function (index, fieldName, fieldValue) {
        var updatedFields = __spreadArray([], fields, true);
        updatedFields[index][fieldName] = fieldValue;
        setFields(updatedFields);
    };
    var addNestedField = function (parentIndex) {
        var _a;
        var _b;
        var updatedFields = __spreadArray([], fields, true);
        var updatedField = updatedFields[parentIndex];
        updatedField.targetType = "Array";
        ((_a = (_b = updatedFields[parentIndex]).nestedFields) !== null && _a !== void 0 ? _a : (_b.nestedFields = [])).push({
            localField: "",
            targetField: "",
            targetType: "String",
            defaultValue: "",
        });
        setFields(updatedFields);
    };
    var removeNestedField = function (parentIndex, nestedIndex) {
        var updatedFields = __spreadArray([], fields, true);
        var updatedField = updatedFields[parentIndex];
        updatedField.nestedFields.splice(nestedIndex, 1);
        updatedField.targetType = "String";
        setFields(updatedFields);
    };
    var updateNestedField = function (parentIndex, nestedIndex, fieldName, fieldValue) {
        var updatedFields = __spreadArray([], fields, true);
        updatedFields[parentIndex].nestedFields[nestedIndex][fieldName] = fieldValue;
        setFields(updatedFields);
    };
    var fieldDataTypeOptions = [
        { label: "String", value: "String" },
        { label: "Number", value: "Number" },
        { label: "Boolean", value: "Boolean" },
        { label: "Date", value: "Date" },
        { label: "Integer", value: "Integer" },
        { label: "Array", value: "Array" },
    ];
    var validateField = function (field) {
        var validation = {
            localField: !field.localField ? "Local field is required" : "",
            targetField: !field.targetField ? "Target field is required" : "",
            targetType: !field.targetType ? "Target type is required" : "",
        };
        updateField(fields.indexOf(field), "errorText", validation);
        if (validation.localField || validation.targetField || validation.targetType)
            return false;
        return true;
    };
    var validateFields = function () {
        var isValid = true;
        fields.forEach(function (field) {
            if (!field.errorText)
                isValid = validateField(field);
            else if (field.errorText.localField || field.errorText.targetField || field.errorText.targetType)
                isValid = false;
        });
        if (isValid)
            fields.forEach(function (field) {
                delete field.errorText;
            });
        return isValid;
    };
    var FieldMap = function (_a) {
        var _b, _c, _d, _e;
        var field = _a.field, index = _a.index, _f = _a.parentIndex, parentIndex = _f === void 0 ? null : _f;
        return (react_1.default.createElement("div", { key: index, className: "flex flex-col gap-4 ".concat(parentIndex !== null ? "ml-8" : "pt-2") },
            react_1.default.createElement("div", { className: "flex flex-row items-center gap-4" },
                react_1.default.createElement("div", { className: "min-w-lg" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Local Field", type: "text", borderColor: "border-borders", value: field.localField, errorText: (_b = field.errorText) === null || _b === void 0 ? void 0 : _b.localField, onChange: function (value) {
                            if (parentIndex === null)
                                updateField(index, "localField", value);
                            else
                                updateNestedField(parentIndex, index, "localField", value);
                        } })),
                react_1.default.createElement("div", { className: "min-w-lg" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { label: "Target Field", type: "text", borderColor: "border-borders", value: field.targetField, errorText: (_c = field.errorText) === null || _c === void 0 ? void 0 : _c.targetField, onChange: function (value) {
                            if (parentIndex === null)
                                updateField(index, "targetField", value);
                            else
                                updateNestedField(parentIndex, index, "targetField", value);
                        } })),
                react_1.default.createElement("div", { className: "w-[150px]" },
                    react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Type", disabled: field.nestedFields && field.nestedFields.length > 0 ? true : false, options: fieldDataTypeOptions, textColor: "text-black", labelColor: field.nestedFields && field.nestedFields.length > 0 ? "text-disabled" : "text-black", borderColor: field.nestedFields && field.nestedFields.length > 0 ? "border-disabled" : "border-black", size: "small", value: field.targetType, errorText: (_d = field.errorText) === null || _d === void 0 ? void 0 : _d.targetType, onChange: function (value) {
                            if (parentIndex === null) {
                                if (value === "Array")
                                    addNestedField(index);
                                else
                                    updateField(index, "targetType", value);
                            }
                            else
                                updateNestedField(parentIndex, index, "targetType", value);
                        } })),
                react_1.default.createElement("div", { className: "min-w-lg" },
                    react_1.default.createElement(doshx_controls_web_1.TextInputControl, { disabled: field.nestedFields && field.nestedFields.length > 0 ? true : false, label: "Default Value", type: "text", borderColor: "border-borders", textColor: field.nestedFields && field.nestedFields.length > 0 ? "text-disabled" : "text-black", labelColor: field.nestedFields && field.nestedFields.length > 0 ? "text-disabled" : "text-black", value: field.defaultValue, onChange: function (value) {
                            if (parentIndex === null)
                                updateField(index, "defaultValue", value);
                            else
                                updateNestedField(parentIndex, index, "defaultValue", value);
                        } })),
                parentIndex === null && (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPlusCircle, size: "large", borderColor: "border-transparent", backgroundColor: "bg-transparent hover:bg-gray-100", iconColor: "text-primary", onClick: function () {
                        addNestedField(index);
                    } })),
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faXmarkCircle, size: "large", borderColor: "border-transparent", backgroundColor: "bg-transparent hover:bg-gray-100", iconColor: "text-black hover:text-primary", onClick: function () {
                        if (parentIndex === null)
                            removeField(index);
                        else
                            removeNestedField(parentIndex, index);
                    } })),
            ((_e = field.nestedFields) === null || _e === void 0 ? void 0 : _e.length) > 0 &&
                field.nestedFields.map(function (nestedField, nestedIndex) { return FieldMap({ field: nestedField, index: nestedIndex, parentIndex: index }); })));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex flex-col gap-4 max-h-36 md:max-h-56 lg:max-h-96 xl:max-h-[500px] overflow-auto" }, fields.map(function (field, parentIndex) { return FieldMap({ field: field, index: parentIndex }); })),
        react_1.default.createElement("div", null,
            react_1.default.createElement("div", { className: "flex justify-center" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faPlusCircle, size: "large", onClick: addField, borderColor: "border-transparent", backgroundColor: "bg-transparent hover:bg-gray-100", iconColor: "text-primary" })),
            react_1.default.createElement("div", { className: "w-[100px] justify-start" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Save", size: "medium", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (!validateFields())
                                return [2 /*return*/];
                            dispatch((0, stock_replacement_1.setWebhook)(__assign(__assign({}, webhook), { data_map: fields })));
                            dispatch((0, main_1.setContentModal)(null));
                            return [2 /*return*/];
                        });
                    }); } })))));
};
exports.default = DataMapper;
