"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var add_client_integration_1 = require("../../../../../../api/calls/client/add_client_integration");
var constants_1 = require("../../../../../../constants/constants");
var validateForm_1 = require("../../../../../../helpers/validateForm/validateForm");
var client_1 = require("../../../../../../reducers/slices/client/client");
var main_1 = require("../../../../../../reducers/slices/main");
var get_client_thunk_1 = require("../../../../../../reducers/slices/client/thunks/get_client_thunk");
var AddFirebaseMessaging = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var selectedClientIntegration = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).selectedClientIntegration;
    var data = selectedClientIntegration.data, _id = selectedClientIntegration._id;
    var _b = (0, validateForm_1.validateForm)(data), errors = _b.errors, isValid = _b.isValid;
    var _c = (0, react_1.useState)(!Boolean(_id)), editing = _c[0], setEditing = _c[1];
    var showLoader = function () {
        dispatch((0, main_1.setContentModal)({
            title: "Please Wait...",
            content: react_1.default.createElement(doshx_controls_web_1.CircularLoaderControl, { size: "small" }),
        }));
    };
    var handleResponse = function (response) {
        if (response.success) {
            dispatch((0, main_1.setContentModal)({ title: "Success", content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
            dispatch((0, get_client_thunk_1.get_client_thunk)());
        }
        else
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
    };
    var _setFormData = function (value) {
        dispatch((0, client_1.setSelectedClientIntegration)(__assign(__assign({}, selectedClientIntegration), { data: __assign(__assign({}, data), value) })));
    };
    var _onSubmit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var type, data, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    type = selectedClientIntegration.type, data = selectedClientIntegration.data;
                    return [4 /*yield*/, (0, add_client_integration_1.add_client_integration)({ type: type, data: data })];
                case 1:
                    response = _a.sent();
                    handleResponse(response);
                    return [2 /*return*/];
            }
        });
    }); };
    var _onEdit = function () {
        setEditing(true);
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-8" },
        react_1.default.createElement("div", { className: "gap-4" },
            react_1.default.createElement("div", { className: "flex flex-col gap-4" }, ((_a = data.service_account) === null || _a === void 0 ? void 0 : _a.projectId) && !editing ? (react_1.default.createElement("div", { className: "flex flex-col gap-2" },
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Project ID" }),
                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: data.service_account.projectId, size: "small", className: "overflow-truncate" }))) : (react_1.default.createElement(doshx_controls_web_1.FileInputControl, { label: "Service Account Credentials", supportedFileTypes: [".json"], onChange: function (file) {
                    var reader = new FileReader();
                    reader.onload = function (e) {
                        var text = e.target.result;
                        var json = JSON.parse(text);
                        if (!json.project_id || !json.private_key || !json.client_email) {
                            dispatch((0, main_1.setMessageModal)({ title: "Success", messages: ["Invalid Service Account Credentials file. Please upload a valid File."] }));
                            return;
                        }
                        _setFormData({
                            service_account: {
                                projectId: json.project_id,
                                privateKey: json.private_key,
                                clientEmail: json.client_email,
                            },
                        });
                    };
                    reader.readAsText(file);
                } })))),
        react_1.default.createElement("div", { className: "flex justify-center" },
            react_1.default.createElement("div", { className: "w-40" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: editing ? "Connect" : "Edit", size: "small", enabled: isValid, backgroundColor: editing ? "bg-primary" : "bg-green-300", textColor: "text-white", onClick: editing ? _onSubmit : _onEdit })))));
};
exports.default = AddFirebaseMessaging;
