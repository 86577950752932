"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VouchersPage = void 0;
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var console_1 = require("../../../../../reducers/slices/console");
var get_client_vouchers_thunk_1 = require("../../../../../reducers/slices/vouchers/thunks/get_client_vouchers_thunk");
var doshx_controls_web_1 = require("doshx_controls_web");
var constants_1 = require("./constants");
var pages_1 = require("../../../../../constants/pages");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../constants/routes");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var main_1 = require("../../../../../reducers/slices/main");
var remove_voucher_1 = require("../../../../../api/calls/vouchers/remove/remove_voucher");
var VouchersPage = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, useSearchDebounce_1.useSearchDebounce)(), searchVoucherList = _a[0], setSearchVouchers = _a[1];
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.voucherState; }), vouchers = _b.vouchers, pagination = _b.pagination, loading = _b.loading;
    var _c = (0, react_1.useState)(false), showFilters = _c[0], setShowFilters = _c[1];
    var _d = (0, react_1.useState)(""), selectedVoucherType = _d[0], setSelectedVoucherType = _d[1];
    var _e = (0, react_1.useState)(""), voucherStatus = _e[0], setVoucherStatus = _e[1];
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Vouchers"));
    }, []);
    (0, react_1.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        onSearchDealList(searchVoucherList);
    }, [searchVoucherList]);
    (0, react_1.useEffect)(function () {
        dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(__assign(__assign({}, pagination), { type: selectedVoucherType })));
    }, [selectedVoucherType]);
    (0, react_1.useEffect)(function () {
        dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(__assign(__assign({}, pagination), { status: voucherStatus })));
    }, [voucherStatus]);
    var getVouchers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchDealList = function (search_string) {
        getVouchers(__assign(__assign({}, pages_1.initialPaginationValues), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    var onPageChange = function (page) {
        return getVouchers(__assign(__assign({}, pagination), { page: page }));
    };
    var onRowsPerPageChange = function (size) {
        return getVouchers(__assign(__assign({}, pagination), { size: size }));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "flex justify-between items-center gap-4 " },
            react_1.default.createElement("div", { className: "flex justify-start gap-4 items-center" },
                react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_light_svg_icons_1.faSlidersSimple, size: "small", backgroundColor: "bg-primary", iconColor: "text-white", onClick: function () { return setShowFilters(!showFilters); } }),
                showFilters && (react_1.default.createElement("div", { className: "flex flex-grow justify-start gap-4 px-4" },
                    react_1.default.createElement("div", { className: "flex gap-4 justify-center" },
                        react_1.default.createElement("div", { className: "w-44 bg-white rounded-md" },
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Voucher Type", size: "small", borderColor: "border-white", options: constants_1.VOUCHER_TYPES === null || constants_1.VOUCHER_TYPES === void 0 ? void 0 : constants_1.VOUCHER_TYPES.map(function (type) {
                                    return {
                                        label: type.label,
                                        value: type === null || type === void 0 ? void 0 : type.value,
                                    };
                                }), value: selectedVoucherType, onClear: function () { }, onChange: function (v) {
                                    setSelectedVoucherType(v);
                                } })),
                        react_1.default.createElement("div", { className: "w-44 bg-white rounded-md" },
                            react_1.default.createElement(doshx_controls_web_1.DropdownControl, { label: "Voucher Status", borderColor: "border-white", size: "small", options: constants_1.STATUSES === null || constants_1.STATUSES === void 0 ? void 0 : constants_1.STATUSES.map(function (type) {
                                    return {
                                        label: type.label,
                                        value: type === null || type === void 0 ? void 0 : type.value,
                                    };
                                }), value: voucherStatus, onClear: function () { }, onChange: function (v) {
                                    setVoucherStatus(v);
                                } })))))),
            react_1.default.createElement("div", { className: "flex items-center gap-2" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Add Voucher", endIcon: pro_solid_svg_icons_1.faPlus, size: "small", backgroundColor: "bg-success", textColor: "text-white", onClick: function () {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.VOUCHER_DETAILS_ROUTE));
                    } }),
                react_1.default.createElement(doshx_controls_web_1.TextInputControl, { borderColor: "border-borders", backgroundColor: "bg-white", label: "Search", endIcon: pro_solid_svg_icons_1.faSearch, size: "small", onChange: function (e) {
                        setSearchVouchers(e);
                    } }))),
        react_1.default.createElement(doshx_controls_web_1.TableControl, { columns: constants_1.VOUCHER_TABLE_COLUMNS, data: vouchers, onRowClicked: function (voucher) {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.VOUCHER_DETAILS_ROUTE, "/").concat(voucher === null || voucher === void 0 ? void 0 : voucher._id), { state: { voucher_type: voucher === null || voucher === void 0 ? void 0 : voucher.type } });
            }, rowActions: [
                {
                    label: "View",
                    onClick: function (voucher) {
                        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.VOUCHER_DETAILS_ROUTE, "/").concat(voucher === null || voucher === void 0 ? void 0 : voucher._id), { state: { voucher_type: voucher === null || voucher === void 0 ? void 0 : voucher.type } });
                    },
                },
                {
                    label: "Remove Voucher",
                    onClick: function (voucher) {
                        dispatch((0, main_1.setContentModal)({
                            title: "Confirm deletion of ".concat(voucher === null || voucher === void 0 ? void 0 : voucher.name, " voucher"),
                            content: (react_1.default.createElement("div", { className: "flex gap-4 flex-col items-center" },
                                react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to delete this voucher?", textColor: "text-inputText" }),
                                react_1.default.createElement("div", { className: "flex gap-4 justify-center" },
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
                                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () {
                                            (0, remove_voucher_1.remove_voucher)({
                                                _id: voucher === null || voucher === void 0 ? void 0 : voucher._id,
                                            }).then(function (response) {
                                                if (response.success) {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                    dispatch((0, main_1.setContentModal)(null));
                                                    dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(__assign({}, pagination)));
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting the voucher?", messages: [response.message] }));
                                                }
                                            });
                                        } })))),
                        }));
                    },
                },
            ], hasShadow: true, pagination: {
                rowsPerPageOptions: [10, 15, 20],
                currentPage: pagination.page,
                rowsPerPage: pagination.size,
                totalRecords: pagination.countTotal,
                onPageChange: onPageChange,
                onRowsPerPageChange: onRowsPerPageChange,
            }, colors: pages_1.tableColorConfig })));
};
exports.VouchersPage = VouchersPage;
