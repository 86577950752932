"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StoreFrontDomain = void 0;
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var connect_store_domain_1 = require("../../../../../api/calls/storeFront/domain/connect_store_domain");
var delete_store_domain_1 = require("../../../../../api/calls/storeFront/domain/delete_store_domain");
var get_domain_token_1 = require("../../../../../api/calls/storeFront/domain/get_domain_token");
var get_store_domain_1 = require("../../../../../api/calls/storeFront/domain/get_store_domain");
var verify_store_domain_1 = require("../../../../../api/calls/storeFront/domain/verify_store_domain");
var constants_1 = require("../../../../../constants/constants");
var validateForm_1 = require("../../../../../helpers/validateForm/validateForm");
var main_1 = require("../../../../../reducers/slices/main");
var store_front_1 = require("../../../../../reducers/slices/storeFront/store_front");
var update_store_front_thunk_1 = require("../../../../../reducers/slices/storeFront/thunks/update_store_front_thunk");
var StoreFrontDomain = function () {
    var _a, _b, _c;
    var dispatch = (0, react_redux_1.useDispatch)();
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.storeFrontState; }), selectedEnvironment = _d.selectedEnvironment, store_front = __rest(_d, ["selectedEnvironment"]);
    var store_domain = store_front.store_domain;
    var validationConfig = { url: { name: "Domain name", required: true, type: "url" } };
    var _e = (0, validateForm_1.validateForm)(store_domain, { validationConfig: validationConfig, enabled: Boolean(store_domain === null || store_domain === void 0 ? void 0 : store_domain.url) }), errors = _e.errors, isValid = _e.isValid;
    var _f = (0, react_1.useState)(null), isLoading = _f[0], setIsLoading = _f[1];
    var _g = (0, react_1.useState)(null), verificationConfig = _g[0], setVerificationConfig = _g[1];
    var _h = (0, react_1.useState)(null), domainConfig = _h[0], setDomainConfig = _h[1];
    var _j = (0, react_1.useState)(null), tokenConfig = _j[0], setTokenConfig = _j[1];
    var siteVerified = (store_domain === null || store_domain === void 0 ? void 0 : store_domain.url) === ((_a = verificationConfig === null || verificationConfig === void 0 ? void 0 : verificationConfig.site) === null || _a === void 0 ? void 0 : _a.identifier);
    var siteIsConfigured = (store_domain === null || store_domain === void 0 ? void 0 : store_domain.url) === (domainConfig === null || domainConfig === void 0 ? void 0 : domainConfig.url);
    var domainPayload = {
        app_name: client.name,
        app_url: store_domain.url,
    };
    (0, react_1.useEffect)(function () {
        if (!!store_domain.url)
            onGetStoreDomain();
    }, []);
    var showLoader = function () {
        setIsLoading(true);
    };
    var showDNSRecords = function () {
        var _a;
        dispatch((0, main_1.setContentModal)({
            title: "DNS Records",
            content: (react_1.default.createElement("div", { className: "flex flex-col justify-between mt-4" }, ((_a = domainConfig === null || domainConfig === void 0 ? void 0 : domainConfig.records) !== null && _a !== void 0 ? _a : []).map(function (item, index) {
                return (react_1.default.createElement("div", { className: "flex flex-row justify-between", key: index }, Object.keys(item).map(function (key, i) { return (react_1.default.createElement("div", { key: key },
                    index == 0 && react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "pb-2", label: key.charAt(0).toUpperCase() + key.slice(1), size: "large", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { className: "pb-2", label: "".concat(item[key]), size: "small", textColor: "text-black" }))); })));
            }))),
        }));
    };
    var handleResponse = function (response) {
        setIsLoading(false);
        if (response.success) {
            return response.content;
        }
        else {
            dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
        }
    };
    var onConnectDomain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, connect_store_domain_1.connect_store_domain)(domainPayload)];
                case 1:
                    response = _a.sent();
                    if (!response.success) return [3 /*break*/, 3];
                    return [4 /*yield*/, onGetStoreDomain()];
                case 2: return [2 /*return*/, _a.sent()];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleOnConnectDomain = function () {
        dispatch((0, update_store_front_thunk_1.update_store_front_thunk)(store_front));
        onConnectDomain();
    };
    var onGetStoreDomain = function (refreshOnly) {
        if (refreshOnly === void 0) { refreshOnly = false; }
        return __awaiter(void 0, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        showLoader();
                        return [4 /*yield*/, (0, get_store_domain_1.get_store_domain)(domainPayload)];
                    case 1:
                        response = _a.sent();
                        if (refreshOnly) {
                            setIsLoading(false);
                            if (response === null || response === void 0 ? void 0 : response.success) {
                                return [2 /*return*/, setDomainConfig(response.content)];
                            }
                        }
                        else
                            setDomainConfig(handleResponse(response));
                        return [2 /*return*/];
                }
            });
        });
    };
    var onDeleteStoreDomain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, delete_store_domain_1.delete_store_domain)(domainPayload)];
                case 1:
                    response = _a.sent();
                    handleResponse(response);
                    setDomainConfig(null);
                    setVerificationConfig(null);
                    return [2 /*return*/];
            }
        });
    }); };
    var onVerifyStoreDomain = function () { return __awaiter(void 0, void 0, void 0, function () {
        var config, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, verify_store_domain_1.verify_store_domain)(domainPayload)];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        config = response.content;
                    }
                    else if (tokenConfig) {
                        dispatch((0, main_1.setContentModal)({ title: constants_1.REQUEST_ERROR_TITLE, content: react_1.default.createElement(react_1.default.Fragment, null, response.message) }));
                    }
                    setVerificationConfig(config);
                    setIsLoading(false);
                    if (!!config) return [3 /*break*/, 3];
                    return [4 /*yield*/, onGetDomainToken()];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var onGetDomainToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showLoader();
                    return [4 /*yield*/, (0, get_domain_token_1.get_domain_token)(domainPayload)];
                case 1:
                    response = _a.sent();
                    setTokenConfig(handleResponse(response));
                    return [2 /*return*/];
            }
        });
    }); };
    var onChangeurl = function (url) {
        dispatch((0, store_front_1.setStoreDomainUrl)(__assign(__assign({}, store_domain), { url: url })));
    };
    return (react_1.default.createElement(doshx_controls_web_1.BoxControl, { backgroundColor: "bg-white", borderColor: "border-transparent", className: "w-md" },
        react_1.default.createElement("div", { className: "flex flex-row justify-between items-center h-[30px]" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Connect Domain Url", size: "large", textColor: "text-black" }),
            ((_b = domainConfig === null || domainConfig === void 0 ? void 0 : domainConfig.records) === null || _b === void 0 ? void 0 : _b.length) > 0 ? (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "DNS Records", size: "small", onClick: showDNSRecords })) : (react_1.default.createElement(doshx_controls_web_1.IconButtonControl, { icon: pro_solid_svg_icons_1.faArrowsRotate, borderColor: "border-transparent", onClick: function () {
                    onGetStoreDomain(true);
                } }))),
        react_1.default.createElement(doshx_controls_web_1.DividerControl, null),
        react_1.default.createElement(doshx_controls_web_1.TextInputControl, { value: (_c = store_domain === null || store_domain === void 0 ? void 0 : store_domain.url) !== null && _c !== void 0 ? _c : "", errorText: errors.url, required: true, borderColor: "border-black-300", label: "Domain name", size: "small", onChange: onChangeurl }),
        react_1.default.createElement("div", { className: "flex justify-start w-full" },
            react_1.default.createElement("div", { className: "flex flex-row justify-end gap-4 pt-4" }, !siteIsConfigured ? (react_1.default.createElement(react_1.default.Fragment, null, verificationConfig && siteVerified ? (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Connect Domain", size: "small", enabled: isValid, loading: isLoading, startIcon: pro_solid_svg_icons_1.faLink, onClick: handleOnConnectDomain })) : (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Verify Domain", size: "small", loading: isLoading, enabled: isValid, startIcon: pro_solid_svg_icons_1.faLink, onClick: onVerifyStoreDomain })))) : (react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Remove Domain", backgroundColor: "bg-red-400", size: "small", loading: isLoading, startIcon: pro_solid_svg_icons_1.faTrash, onClick: onDeleteStoreDomain })))),
        siteVerified && !domainConfig && (react_1.default.createElement("div", { className: "flex flex-col justify-between mt-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Your website has been verified, You may connect your domain to your store front", size: "medium", textColor: "text-green" }))),
        !siteVerified && tokenConfig && (react_1.default.createElement("div", { className: "flex flex-col justify-between mt-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "To verify ownership of your website, you'll need to add this ".concat(tokenConfig === null || tokenConfig === void 0 ? void 0 : tokenConfig.method, " record to your domain's DNS settings."), size: "medium", textColor: "text-black" }),
            react_1.default.createElement(doshx_controls_web_1.DividerControl, { orientation: "horizontal", lineColor: "bg-disabled" }),
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: tokenConfig === null || tokenConfig === void 0 ? void 0 : tokenConfig.token, size: "small", bold: true, textColor: "text-black" }))),
        domainConfig && (react_1.default.createElement(react_1.default.Fragment, null, domainConfig.status == "Ready" ? (react_1.default.createElement("div", { className: "flex flex-col justify-between mt-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "https://".concat(domainConfig === null || domainConfig === void 0 ? void 0 : domainConfig.url, " is ready"), size: "medium", textColor: "text-green" }))) : (react_1.default.createElement("div", { className: "flex flex-col justify-between mt-4" },
            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "https://".concat(domainConfig === null || domainConfig === void 0 ? void 0 : domainConfig.url, " is busy propagating, this may take up to 48hours"), size: "medium", textColor: "text-green" })))))));
};
exports.StoreFrontDomain = StoreFrontDomain;
