"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var issue_voucher_1 = require("../../../../../api/calls/vouchers/update/issue_voucher");
var useSearchDebounce_1 = require("../../../../../hooks/useSearchDebounce");
var main_1 = require("../../../../../reducers/slices/main");
var get_client_vouchers_thunk_1 = require("../../../../../reducers/slices/vouchers/thunks/get_client_vouchers_thunk");
var get_user_voucher_1 = require("../../../../../api/calls/vouchers/get/get_user_voucher");
var vouchers_1 = require("../../../../../reducers/slices/vouchers/vouchers");
var IssueVoucherComponent = function () {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var _b = (0, useSearchDebounce_1.useSearchDebounce)(), searchVoucherList = _b[0], setSearchVoucherList = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _c.client_token, user = _c.user;
    var _d = (0, react_redux_1.useSelector)(function (state) { return state.voucherState; }), vouchers = _d.vouchers, pagination = _d.pagination;
    var customer = (0, react_redux_1.useSelector)(function (state) { return state.customerState; }).customer;
    var _e = (0, react_1.useState)(""), voucher = _e[0], setVoucher = _e[1];
    var _f = (0, react_1.useState)(undefined), selectedVoucherId = _f[0], setSelectedVoucherId = _f[1];
    (0, react_1.useEffect)(function () {
        if (user === null || user === void 0 ? void 0 : user._id)
            getClientVouchers(pagination);
    }, [user]);
    (0, react_1.useEffect)(function () {
        onSearchVoucherList(searchVoucherList);
    }, [searchVoucherList]);
    var getClientVouchers = function (query) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, dispatch((0, get_client_vouchers_thunk_1.get_vouchers_thunk)(query))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); };
    var onSearchVoucherList = function (search_string) {
        getClientVouchers(__assign(__assign({}, pagination), { search_string: search_string !== null && search_string !== void 0 ? search_string : "" }));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-1 lg:gap-2 lg:h-full md:h-auto sm:h-auto" },
        react_1.default.createElement(doshx_controls_web_1.DropdownSearchControl, { renderDisplay: function (voucher) { return voucher === null || voucher === void 0 ? void 0 : voucher.label; }, label: "Select a Voucher...", options: (_a = vouchers === null || vouchers === void 0 ? void 0 : vouchers.map(function (voucher) { return ({ label: voucher === null || voucher === void 0 ? void 0 : voucher.name, value: voucher === null || voucher === void 0 ? void 0 : voucher._id }); })) !== null && _a !== void 0 ? _a : [], size: "small", value: voucher, colors: {
                borderColor: "border-borders",
                iconColor: "text-primary",
            }, errorText: "", onChange: function (VoucherObject) {
                setVoucher(VoucherObject);
                setSelectedVoucherId(VoucherObject === null || VoucherObject === void 0 ? void 0 : VoucherObject.value);
            }, onSearchChanged: function (search_string) {
                setSearchVoucherList(search_string);
            } }),
        react_1.default.createElement("hr", null),
        react_1.default.createElement("div", { className: "flex justify-between items-center gap-2" },
            react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-black", startIcon: pro_solid_svg_icons_1.faCancel, label: "Cancel", size: "small", backgroundColor: "bg-borders", textColor: "text-black", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }),
            react_1.default.createElement("div", { className: "w-[85px] hidden md:block" },
                react_1.default.createElement(doshx_controls_web_1.ButtonControl, { iconColor: "text-white", startIcon: pro_solid_svg_icons_1.faSave, label: "Issue", size: "small", backgroundColor: "bg-primary", textColor: "text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var userObject, response, user_vouchers;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    userObject = {
                                        name: user === null || user === void 0 ? void 0 : user.name,
                                        surname: user === null || user === void 0 ? void 0 : user.surname,
                                        cell_number: user === null || user === void 0 ? void 0 : user.cell_number,
                                        email: user === null || user === void 0 ? void 0 : user.email,
                                        birthday: user === null || user === void 0 ? void 0 : user.birthday,
                                    };
                                    return [4 /*yield*/, (0, issue_voucher_1.issue_voucher)({ user_id: customer === null || customer === void 0 ? void 0 : customer._id, voucher_id: selectedVoucherId, user: userObject })];
                                case 1:
                                    response = _b.sent();
                                    if (!(response === null || response === void 0 ? void 0 : response.success)) return [3 /*break*/, 3];
                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                    dispatch((0, main_1.setContentModal)(null));
                                    return [4 /*yield*/, (0, get_user_voucher_1.get_user_vouchers)({ user_id: customer === null || customer === void 0 ? void 0 : customer._id })];
                                case 2:
                                    user_vouchers = _b.sent();
                                    dispatch((0, vouchers_1.setUserVouchers)((_a = user_vouchers === null || user_vouchers === void 0 ? void 0 : user_vouchers.content) === null || _a === void 0 ? void 0 : _a.voucher_list));
                                    return [3 /*break*/, 4];
                                case 3:
                                    dispatch((0, main_1.setMessageModal)({
                                        title: "Something went wrong when issuing a Voucher.",
                                        messages: [response.message],
                                    }));
                                    _b.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    }); } })))));
};
exports.default = IssueVoucherComponent;
