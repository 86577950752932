"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProcurePage = void 0;
var react_1 = __importStar(require("react"));
var doshx_controls_web_1 = require("doshx_controls_web");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../reducers/slices/console");
var branch_configure_1 = require("./components/branch_configure");
var stock_configure_1 = require("./components/stock_configure");
var get_client_suppliers_thunk_1 = require("../../../../reducers/slices/client/thunks/get_client_suppliers_thunk");
var ProcurePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_1.useState)(0), selectedTabIndex = _a[0], setSelectedTabIndex = _a[1];
    var navigate = (0, react_router_dom_1.useNavigate)();
    var pagination = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).pagination;
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Procure"));
    }, []);
    var getSuppliers = function () {
        var _pagination = __assign({}, pagination);
        _pagination === null || _pagination === void 0 ? true : delete _pagination.countTotal;
        dispatch((0, get_client_suppliers_thunk_1.get_client_suppliers_thunk)({
            payload: __assign({}, _pagination),
            callBack: function () {
                setSelectedTabIndex(1);
            },
        }));
    };
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement(doshx_controls_web_1.TabControl, { selectedTabIndex: selectedTabIndex, tabs: [
                {
                    title: "Branch",
                    content: react_1.default.createElement(branch_configure_1.BranchConfigure, { getSuppliers: getSuppliers }),
                },
                {
                    title: "Stock",
                    content: react_1.default.createElement(stock_configure_1.StockConfigure, null),
                },
            ], colors: {
                borderColor: "border-borders",
                tabs: {
                    textColor: "text-gray-800",
                    indicatorColor: "bg-primary",
                    backgroundColor: "bg-borders",
                    unselectedBackgroundColor: "bg-white",
                    selectedBackgroundColor: "bg-white",
                },
            } })));
};
exports.ProcurePage = ProcurePage;
