"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var doshx_controls_web_1 = require("doshx_controls_web");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var main_1 = require("../../../../../reducers/slices/main");
var remove_parcel_thunk_1 = require("../../../../../reducers/slices/trips/thunks/remove_parcel_thunk");
var TripViewComponent = function (props) {
    var _a;
    var dispatch = (0, react_redux_1.useDispatch)();
    var trip = props.trip;
    var _b = (0, react_1.useState)(), selectedTrip = _b[0], setSelectedTrip = _b[1];
    var _c = (0, react_redux_1.useSelector)(function (state) { return state.tripState; }), loading = _c.loading, trips = _c.trips;
    (0, react_1.useEffect)(function () {
        console.log("trip ===>", trip);
        var _trip = trips.find(function (x) { return x._id === trip._id; });
        if (_trip) {
            setSelectedTrip(_trip);
        }
    }, [trips]);
    var distance = (function (parcel) {
        var _a, _b, _c, _d, _e, _f;
        if (((_a = parcel === null || parcel === void 0 ? void 0 : parcel.delivery_address) === null || _a === void 0 ? void 0 : _a.lat) && ((_b = parcel === null || parcel === void 0 ? void 0 : parcel.collection_address) === null || _b === void 0 ? void 0 : _b.lat)) {
            var ds = doshx_controls_web_1.Utilities.calculateDistanceCrow({
                coordinates1: {
                    lat: parseFloat((_c = parcel === null || parcel === void 0 ? void 0 : parcel.collection_address) === null || _c === void 0 ? void 0 : _c.lat),
                    lng: parseFloat((_d = parcel === null || parcel === void 0 ? void 0 : parcel.collection_address) === null || _d === void 0 ? void 0 : _d.lon)
                },
                coordinates2: {
                    lat: parseFloat((_e = parcel === null || parcel === void 0 ? void 0 : parcel.delivery_address) === null || _e === void 0 ? void 0 : _e.lat),
                    lng: parseFloat((_f = parcel === null || parcel === void 0 ? void 0 : parcel.delivery_address) === null || _f === void 0 ? void 0 : _f.lon)
                }
            });
            if (ds)
                return ds.toFixed(2);
        }
        return 0;
    });
    //console.log("distance ===>", distance)
    return (react_1.default.createElement("div", { className: "flex flex-col gap-4" },
        react_1.default.createElement("div", { className: "grid grid-cols-2" },
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement("div", { className: "flex gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Trip Created:", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: doshx_controls_web_1.Utilities.formatDateTime(trip.createdAt) })),
                react_1.default.createElement("div", { className: "flex gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Driver:", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(trip.driver_name, " ").concat(trip.driver_name) }))),
            react_1.default.createElement("div", { className: "" },
                react_1.default.createElement("div", { className: "flex gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Trip Status:", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: trip.status })),
                react_1.default.createElement("div", { className: "flex gap-4" },
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Est\u2019 Deliveries finish time:", textColor: "text-black" }),
                    react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "" })))),
        react_1.default.createElement("hr", null),
        selectedTrip && ((_a = selectedTrip === null || selectedTrip === void 0 ? void 0 : selectedTrip.parcel_list) === null || _a === void 0 ? void 0 : _a.map(function (x) {
            var _a, _b, _c, _d, _e;
            return (react_1.default.createElement("div", { className: "flex flex-col gap-4", key: x._id },
                react_1.default.createElement("div", { className: "grid grid-cols-2" },
                    react_1.default.createElement("div", { className: "flex flex-col gap-8" },
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: x.code }),
                        react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Parcel delivery status: ".concat(x.status) })),
                    react_1.default.createElement("div", { className: "" },
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Pick up Location:", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_a = x === null || x === void 0 ? void 0 : x.collection_address) === null || _a === void 0 ? void 0 : _a.full_address })),
                        (x === null || x === void 0 ? void 0 : x.collection_time) && react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Pick up Time:", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_b = x === null || x === void 0 ? void 0 : x.collection_time) === null || _b === void 0 ? void 0 : _b.actual })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Drop Off:", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: (_c = x === null || x === void 0 ? void 0 : x.delivery_address) === null || _c === void 0 ? void 0 : _c.full_address })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "Distance:", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "".concat(distance(x), " km") })),
                        react_1.default.createElement("div", { className: "flex gap-4" },
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: "ETA:", textColor: "text-black" }),
                            react_1.default.createElement(doshx_controls_web_1.LabelControl, { label: ((_d = x === null || x === void 0 ? void 0 : x.delivery_address) === null || _d === void 0 ? void 0 : _d.scheduled_time) ? doshx_controls_web_1.Utilities.formatDateTime((_e = x === null || x === void 0 ? void 0 : x.delivery_address) === null || _e === void 0 ? void 0 : _e.scheduled_time) : '' })))),
                react_1.default.createElement("div", { className: "flex justify-center p-4" },
                    react_1.default.createElement(doshx_controls_web_1.ButtonControl, { label: "Abandon", 
                        //  endIcon={faPlus}
                        size: "small", borderColor: "border-black", backgroundColor: "bg-white", textColor: "text-black", onClick: function () {
                            dispatch((0, main_1.setMessageModal)({
                                title: "Confirm!",
                                messages: ["Are you sure you want to abandon this parcel? its gonna be removed from this trip"],
                                buttons: [
                                    {
                                        label: "Cancel",
                                        backgroundColor: "bg-white",
                                        textColor: "text-primary",
                                        borderColor: "border-primary",
                                        onClick: function () {
                                            dispatch((0, main_1.setMessageModal)(null));
                                        },
                                    },
                                    {
                                        label: "Confirm",
                                        loading: loading,
                                        onClick: function () {
                                            dispatch((0, remove_parcel_thunk_1.remove_parcel_thunk)({
                                                _id: trip._id,
                                                parcel_id_list: [x._id]
                                            }));
                                        },
                                    },
                                ],
                            }));
                        } })),
                react_1.default.createElement("hr", null)));
        }))));
};
exports.default = TripViewComponent;
