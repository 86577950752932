"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var api_1 = require("@react-google-maps/api");
var MapControl = function (props) {
    var latitude = props.latitude, longitude = props.longitude, getCordinates = props.getCordinates, setCordinates = props.setCordinates, onChange = props.onChange;
    var _a = (0, react_1.useState)(11), zoomLevel = _a[0], setZoomLevel = _a[1];
    var polygonRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(null), polygon = _b[0], setPolygon = _b[1];
    var isLoaded = (0, api_1.useJsApiLoader)({
        id: "google-map-script",
        googleMapsApiKey: process.env.GOOGLE_API_KEY,
    }).isLoaded;
    var containerStyle = {
        width: "100%",
        height: "400px",
    };
    var center = {
        lat: latitude,
        lng: longitude,
    };
    var _c = (0, react_1.useState)(null), map = _c[0], setMap = _c[1];
    var onLoad = (0, react_1.useCallback)(function (map) {
        console.log("What is the callBack map-onLoad", map);
        var bounds = new window.google.maps.LatLngBounds(center);
        console.log("What is the callBack fitbounds-onLoad", bounds);
        map.fitBounds(bounds);
        setMap(map);
        setTimeout(function () {
            setZoomLevel(zoomLevel + 1);
        }, 1000);
    }, []);
    var onUnmount = (0, react_1.useCallback)(function (map) {
        setMap(null);
    }, []);
    // Call setPath with new edited path
    var onPolygonEdit = (0, react_1.useCallback)(function () {
        if (polygon) {
            console.log("What is the polygoon data-onpolyedit", polygon);
            var nextPath = polygon
                .getPath()
                .getArray()
                .map(function (latLng) {
                return {
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                };
            });
            //  getCordinates(nextPath);
            getCordinates({
                nextPath: nextPath,
            });
        }
    }, [polygon]);
    var onPolygonLoad = (0, react_1.useCallback)(function (polygon) {
        console.log("What is the poly data-onpolyLoad", polygon);
        setPolygon(polygon);
    }, [onPolygonEdit]);
    return isLoaded ? (react_1.default.createElement(api_1.GoogleMap, { mapContainerStyle: containerStyle, center: center, 
        // position={center}
        zoom: zoomLevel, onLoad: onLoad, onUnmount: onUnmount, options: {
            gestureHandling: "greedy",
        } },
        react_1.default.createElement(api_1.Marker, { icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png", position: center }),
        react_1.default.createElement(api_1.Polygon, { editable: true, draggable: true, path: setCordinates, onMouseUp: onPolygonEdit, onDragEnd: onPolygonEdit, onLoad: onPolygonLoad }),
        react_1.default.createElement(react_1.default.Fragment, null, " "))) : (react_1.default.createElement(react_1.default.Fragment, null));
};
exports.default = (0, react_1.memo)(MapControl);
